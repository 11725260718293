exports.stats = {
  bundleURLTemplate: 'cv-corpus-4-2019-12-10/{locale}.tar.gz',
  date: '2019-12-10',
  name: 'Common Voice Corpus 4',
  multilingual: true,
  locales: {
    en: {
      clips: 1137300,
      splits: {
        accent: {
          '': 0.51,
          canada: 0.03,
          england: 0.09,
          us: 0.23,
          indian: 0.04,
          australia: 0.03,
          malaysia: 0,
          newzealand: 0.01,
          african: 0.01,
          ireland: 0.01,
          philippines: 0,
          singapore: 0,
          scotland: 0.02,
          hongkong: 0,
          bermuda: 0,
          southatlandtic: 0,
          wales: 0,
          other: 0.02,
        },
        age: {
          '': 0.39,
          twenties: 0.22,
          sixties: 0.04,
          thirties: 0.15,
          teens: 0.05,
          seventies: 0.01,
          fourties: 0.09,
          fifties: 0.05,
          eighties: 0,
          nineties: 0,
        },
        gender: { '': 0.39, male: 0.46, female: 0.13, other: 0.02 },
      },
      users: 51072,
      duration: 5358858312,
      buckets: {
        dev: 15531,
        invalidated: 124820,
        other: 155485,
        test: 15531,
        train: 232975,
        validated: 854444,
      },
      size: 41448227462,
      avgDurationSecs: 4.712,
      validDurationSecs: 4026065.534,
      totalHrs: 1488.57,
      validHrs: 1118.35,
    },
    de: {
      clips: 413561,
      splits: {
        accent: {
          germany: 0.64,
          russia: 0,
          '': 0.31,
          austria: 0.02,
          liechtenstein: 0,
          switzerland: 0.01,
          france: 0,
          other: 0,
          poland: 0,
          united_kingdom: 0,
          hungary: 0,
          netherlands: 0,
          namibia: 0,
          slovakia: 0,
          united_states: 0,
          italy: 0,
          czechia: 0,
          brazil: 0,
          canada: 0,
          turkey: 0,
          slovenia: 0,
          luxembourg: 0,
          belgium: 0,
          denmark: 0,
          greece: 0,
        },
        age: {
          twenties: 0.23,
          fourties: 0.19,
          '': 0.23,
          thirties: 0.17,
          teens: 0.03,
          sixties: 0.02,
          fifties: 0.11,
          seventies: 0,
          eighties: 0,
          nineties: 0,
        },
        gender: { male: 0.67, '': 0.23, female: 0.09, other: 0.01 },
      },
      users: 8460,
      duration: 1939923648,
      buckets: {
        dev: 13511,
        invalidated: 19511,
        other: 22014,
        test: 13511,
        train: 71841,
        validated: 370976,
      },
      size: 15073085593,
      avgDurationSecs: 4.691,
      validDurationSecs: 1740166.784,
      totalHrs: 538.86,
      validHrs: 483.37,
    },
    fr: {
      clips: 311742,
      splits: {
        accent: {
          '': 0.34,
          france: 0.59,
          germany: 0,
          belgium: 0.02,
          switzerland: 0.01,
          guadeloupe: 0,
          reunion: 0,
          monaco: 0,
          tunisia: 0,
          canada: 0.03,
          other: 0,
          mayotte: 0,
          algeria: 0,
          netherlands: 0,
          senegal: 0,
          martinique: 0,
          portugal: 0,
          united_states: 0,
          cote_d_ivoire: 0,
          st_pierre_et_miquelon: 0,
          french_guiana: 0,
          benin: 0,
          italy: 0,
          united_kingdom: 0,
          madagascar: 0,
          cameroon: 0,
          new_caledonia: 0,
          haiti: 0,
          morocco: 0,
        },
        age: {
          twenties: 0.21,
          thirties: 0.23,
          '': 0.26,
          teens: 0.03,
          fourties: 0.14,
          fifties: 0.08,
          sixties: 0.04,
          seventies: 0.01,
        },
        gender: { male: 0.65, '': 0.22, female: 0.12, other: 0.01 },
      },
      users: 8164,
      duration: 1485039192,
      buckets: {
        dev: 14760,
        invalidated: 23904,
        other: 21466,
        test: 14760,
        train: 130604,
        validated: 264897,
      },
      size: 11572099037,
      avgDurationSecs: 4.764,
      validDurationSecs: 1261884.593,
      totalHrs: 412.51,
      validHrs: 350.52,
    },
    cy: {
      clips: 61272,
      splits: {
        accent: { united_kingdom: 0.43, '': 0.56, other: 0.01 },
        age: {
          fourties: 0.12,
          twenties: 0.08,
          sixties: 0.08,
          fifties: 0.07,
          '': 0.53,
          thirties: 0.09,
          seventies: 0.01,
          eighties: 0,
          teens: 0.01,
        },
        gender: { male: 0.29, female: 0.18, '': 0.51, other: 0.02 },
      },
      users: 1149,
      duration: 278683344,
      buckets: {
        dev: 690,
        invalidated: 2598,
        other: 11578,
        test: 690,
        train: 721,
        validated: 47059,
      },
      size: 2145771717,
      avgDurationSecs: 4.548,
      validDurationSecs: 214038.378,
      totalHrs: 77.41,
      validHrs: 59.45,
    },
    br: {
      clips: 15073,
      splits: {
        accent: { other: 0, '': 1 },
        age: {
          twenties: 0.14,
          '': 0.43,
          fifties: 0.06,
          fourties: 0.09,
          thirties: 0.11,
          sixties: 0.16,
        },
        gender: { male: 0.55, '': 0.43, female: 0.02 },
      },
      users: 133,
      duration: 45595056,
      buckets: {
        dev: 1590,
        invalidated: 553,
        other: 8495,
        test: 1685,
        train: 2208,
        validated: 6025,
      },
      size: 348309297,
      avgDurationSecs: 3.025,
      validDurationSecs: 18225.318,
      totalHrs: 12.66,
      validHrs: 5.06,
    },
    cv: {
      clips: 2355,
      splits: {
        accent: { other: 0.01, '': 0.99 },
        age: {
          twenties: 0.04,
          '': 0.53,
          fourties: 0.38,
          thirties: 0.04,
          teens: 0.01,
        },
        gender: { male: 0.47, '': 0.53 },
      },
      users: 38,
      duration: 10101114,
      buckets: {
        dev: 66,
        invalidated: 649,
        other: 900,
        test: 198,
        train: 429,
        validated: 806,
      },
      size: 79033262,
      avgDurationSecs: 4.289,
      validDurationSecs: 3457.112,
      totalHrs: 2.8,
      validHrs: 0.96,
    },
    tr: {
      clips: 13185,
      splits: {
        accent: { '': 0.94, other: 0.06 },
        age: {
          '': 0.18,
          thirties: 0.3,
          twenties: 0.45,
          teens: 0.05,
          fourties: 0.02,
          fifties: 0,
          sixties: 0.01,
        },
        gender: { '': 0.18, male: 0.74, female: 0.08 },
      },
      users: 461,
      duration: 53375304,
      buckets: {
        dev: 1624,
        invalidated: 1359,
        other: 9,
        test: 1629,
        train: 1823,
        validated: 12364,
      },
      size: 408922099,
      avgDurationSecs: 4.048,
      validDurationSecs: 50051.745,
      totalHrs: 14.82,
      validHrs: 13.9,
    },
    tt: {
      clips: 26790,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.17,
          thirties: 0.77,
          twenties: 0.05,
          sixties: 0,
          fifties: 0.01,
          teens: 0,
          fourties: 0,
        },
        gender: { '': 0.17, male: 0.81, female: 0.02 },
      },
      users: 142,
      duration: 99079848,
      buckets: {
        dev: 4815,
        invalidated: 256,
        other: 1933,
        test: 4855,
        train: 7131,
        validated: 24601,
      },
      size: 743123406,
      avgDurationSecs: 3.698,
      validDurationSecs: 90984.074,
      totalHrs: 27.52,
      validHrs: 25.27,
    },
    ky: {
      clips: 16913,
      splits: {
        accent: { '': 0.91, other: 0.09 },
        age: {
          thirties: 0.14,
          '': 0.05,
          fourties: 0.02,
          twenties: 0.78,
          teens: 0.01,
        },
        gender: { male: 0.45, '': 0.11, female: 0.44, other: 0 },
      },
      users: 119,
      duration: 78514128,
      buckets: {
        dev: 1345,
        invalidated: 1002,
        other: 7248,
        test: 1546,
        train: 1989,
        validated: 8663,
      },
      size: 560602458,
      avgDurationSecs: 4.642,
      validDurationSecs: 40215.686,
      totalHrs: 21.8,
      validHrs: 11.17,
    },
    'ga-IE': {
      clips: 4137,
      splits: {
        accent: {
          '': 0.31,
          connachta: 0.38,
          other: 0.09,
          ulaidh: 0.08,
          mumhain: 0.13,
        },
        age: {
          twenties: 0.26,
          '': 0.25,
          thirties: 0.36,
          fourties: 0.08,
          sixties: 0.01,
          teens: 0.03,
          fifties: 0.02,
        },
        gender: { male: 0.59, '': 0.25, female: 0.16 },
      },
      users: 80,
      duration: 14632440,
      buckets: {
        dev: 444,
        invalidated: 254,
        other: 1166,
        test: 486,
        train: 555,
        validated: 2717,
      },
      size: 110903924,
      avgDurationSecs: 3.537,
      validDurationSecs: 9609.944,
      totalHrs: 4.06,
      validHrs: 2.66,
    },
    kab: {
      clips: 275021,
      splits: {
        accent: { '': 0.81, other: 0.19 },
        age: {
          fourties: 0.14,
          thirties: 0.32,
          '': 0.27,
          twenties: 0.19,
          fifties: 0.03,
          eighties: 0.01,
          teens: 0,
          sixties: 0.04,
          seventies: 0,
        },
        gender: { male: 0.55, '': 0.23, female: 0.22, other: 0 },
      },
      users: 693,
      duration: 996003432,
      buckets: {
        dev: 11482,
        invalidated: 12914,
        other: 626,
        test: 11483,
        train: 37056,
        validated: 261338,
      },
      size: 7723179003,
      avgDurationSecs: 3.622,
      validDurationSecs: 946449.707,
      totalHrs: 276.66,
      validHrs: 262.9,
    },
    ca: {
      clips: 211172,
      splits: {
        accent: {
          valencian: 0.07,
          central: 0.58,
          '': 0.24,
          other: 0,
          balearic: 0.01,
          northwestern: 0.06,
          northern: 0.03,
        },
        age: {
          thirties: 0.14,
          fifties: 0.22,
          fourties: 0.19,
          twenties: 0.1,
          '': 0.22,
          sixties: 0.11,
          teens: 0.01,
          seventies: 0,
          eighties: 0,
        },
        gender: { male: 0.43, '': 0.22, female: 0.35, other: 0 },
      },
      users: 3724,
      duration: 1063996608,
      buckets: {
        dev: 12730,
        invalidated: 8654,
        other: 26257,
        test: 12730,
        train: 54173,
        validated: 175625,
      },
      size: 8180437356,
      avgDurationSecs: 5.039,
      validDurationSecs: 884891.957,
      totalHrs: 295.55,
      validHrs: 245.8,
    },
    'zh-TW': {
      clips: 70216,
      splits: {
        accent: {
          '': 0.57,
          taipei_city: 0.12,
          other: 0.11,
          kaohsiung_city: 0.03,
          tainan_city: 0.04,
          new_taipei_city: 0.05,
          yilan_county: 0,
          taoyuan_city: 0.01,
          hsinchu_city: 0.01,
          chiayi_city: 0.01,
          taichung_city: 0.01,
          yunlin_county: 0.03,
          nantou_county: 0,
          changhua_county: 0.01,
          pingtung_county: 0,
          hsinchu_county: 0,
          hong_kong: 0,
        },
        age: {
          thirties: 0.28,
          twenties: 0.39,
          teens: 0.03,
          '': 0.23,
          fifties: 0.01,
          seventies: 0,
          fourties: 0.05,
        },
        gender: { male: 0.48, '': 0.23, female: 0.26, other: 0.03 },
      },
      users: 1108,
      duration: 217510632,
      buckets: {
        dev: 2432,
        invalidated: 3177,
        other: 18071,
        test: 2432,
        train: 2849,
        validated: 48968,
      },
      size: 1684558466,
      avgDurationSecs: 3.098,
      validDurationSecs: 151689.937,
      totalHrs: 60.41,
      validHrs: 42.13,
    },
    sl: {
      clips: 6051,
      splits: {
        accent: { other: 0.01, '': 0.99 },
        age: {
          twenties: 0.67,
          teens: 0.13,
          '': 0.04,
          sixties: 0,
          fifties: 0.13,
          fourties: 0.01,
          thirties: 0.01,
        },
        gender: { female: 0.16, male: 0.8, '': 0.04 },
      },
      users: 51,
      duration: 23533392,
      buckets: {
        dev: 509,
        invalidated: 142,
        other: 2932,
        test: 360,
        train: 1566,
        validated: 2972,
      },
      size: 183825568,
      avgDurationSecs: 3.889,
      validDurationSecs: 11558.625,
      totalHrs: 6.53,
      validHrs: 3.21,
    },
    it: {
      clips: 80605,
      splits: {
        accent: { '': 0.92, other: 0.08 },
        age: {
          thirties: 0.14,
          twenties: 0.23,
          '': 0.35,
          fifties: 0.12,
          fourties: 0.09,
          seventies: 0.01,
          sixties: 0.02,
          teens: 0.04,
          eighties: 0,
          nineties: 0,
        },
        gender: { female: 0.18, male: 0.47, '': 0.35, other: 0 },
      },
      users: 4292,
      duration: 442751616,
      buckets: {
        dev: 8940,
        invalidated: 8143,
        other: 15314,
        test: 8951,
        train: 19387,
        validated: 56009,
      },
      size: 3447602853,
      avgDurationSecs: 5.493,
      validDurationSecs: 307649.343,
      totalHrs: 122.98,
      validHrs: 85.45,
    },
    nl: {
      clips: 31209,
      splits: {
        accent: {
          '': 0.27,
          netherlands: 0.62,
          belgium: 0.11,
          other: 0,
          germany: 0,
          france: 0,
        },
        age: {
          '': 0.21,
          twenties: 0.29,
          fourties: 0.12,
          thirties: 0.15,
          teens: 0.06,
          fifties: 0.16,
          sixties: 0.01,
        },
        gender: { '': 0.23, male: 0.66, female: 0.1, other: 0 },
      },
      users: 701,
      duration: 120456600,
      buckets: {
        dev: 1699,
        invalidated: 1301,
        other: 6513,
        test: 1699,
        train: 1893,
        validated: 22954,
      },
      size: 927639565,
      avgDurationSecs: 3.86,
      validDurationSecs: 88594.982,
      totalHrs: 33.46,
      validHrs: 24.6,
    },
    cnh: {
      clips: 4824,
      splits: {
        accent: { '': 0.82, other: 0.18 },
        age: {
          '': 0.57,
          twenties: 0.27,
          fourties: 0.01,
          teens: 0.02,
          thirties: 0.1,
          fifties: 0.02,
        },
        gender: { '': 0.57, male: 0.23, female: 0.2 },
      },
      users: 290,
      duration: 18281064,
      buckets: {
        dev: 716,
        invalidated: 477,
        other: 2040,
        test: 718,
        train: 756,
        validated: 2307,
      },
      size: 142825168,
      avgDurationSecs: 3.79,
      validDurationSecs: 8742.623,
      totalHrs: 5.07,
      validHrs: 2.42,
    },
    eo: {
      clips: 29463,
      splits: {
        accent: { '': 0.33, internacia: 0.66, other: 0.01 },
        age: {
          twenties: 0.46,
          thirties: 0.06,
          '': 0.1,
          fourties: 0.31,
          fifties: 0.04,
          seventies: 0,
          teens: 0.02,
          sixties: 0.01,
        },
        gender: { male: 0.7, '': 0.23, female: 0.07, other: 0.01 },
      },
      users: 215,
      duration: 148750728,
      buckets: {
        dev: 2965,
        invalidated: 1527,
        other: 2652,
        test: 3030,
        train: 3845,
        validated: 25147,
      },
      size: 1159923722,
      avgDurationSecs: 5.049,
      validDurationSecs: 126960.41,
      totalHrs: 41.31,
      validHrs: 35.26,
    },
    et: {
      clips: 7061,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.05,
          thirties: 0.11,
          twenties: 0.7,
          fourties: 0.13,
          fifties: 0,
          seventies: 0,
          teens: 0,
        },
        gender: { '': 0.05, male: 0.57, female: 0.38, other: 0 },
      },
      users: 230,
      duration: 47286360,
      buckets: {
        dev: 1576,
        invalidated: 1377,
        other: 5,
        test: 1571,
        train: 1750,
        validated: 5679,
      },
      size: 365304398,
      avgDurationSecs: 6.697,
      validDurationSecs: 38031.332,
      totalHrs: 13.13,
      validHrs: 10.56,
    },
    fa: {
      clips: 227458,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.2,
          twenties: 0.31,
          thirties: 0.43,
          fifties: 0.02,
          fourties: 0.02,
          teens: 0.02,
          sixties: 0,
        },
        gender: { '': 0.16, male: 0.78, female: 0.06, other: 0 },
      },
      users: 2763,
      duration: 919781952,
      buckets: {
        dev: 3445,
        invalidated: 9019,
        other: 29719,
        test: 3445,
        train: 4347,
        validated: 188313,
      },
      size: 7090877468,
      avgDurationSecs: 4.044,
      validDurationSecs: 761489.588,
      totalHrs: 255.49,
      validHrs: 211.52,
    },
    eu: {
      clips: 69746,
      splits: {
        accent: {
          mendebalekoa: 0.26,
          erdialdekoa_nafarra: 0.32,
          '': 0.42,
          nafarlapurtarra_zuberoatarra: 0,
        },
        age: {
          fourties: 0.15,
          thirties: 0.07,
          fifties: 0.15,
          twenties: 0.36,
          '': 0.23,
          teens: 0.01,
          sixties: 0.01,
          seventies: 0,
        },
        gender: { male: 0.51, female: 0.23, '': 0.24, other: 0.02 },
      },
      users: 638,
      duration: 357525936,
      buckets: {
        dev: 1981,
        invalidated: 3384,
        other: 20519,
        test: 1981,
        train: 2250,
        validated: 45843,
      },
      size: 2779103409,
      avgDurationSecs: 5.126,
      validDurationSecs: 234996.437,
      totalHrs: 99.31,
      validHrs: 65.27,
    },
    es: {
      clips: 148372,
      splits: {
        accent: {
          nortepeninsular: 0.14,
          '': 0.43,
          rioplatense: 0.06,
          centrosurpeninsular: 0.03,
          americacentral: 0.03,
          surpeninsular: 0.13,
          canario: 0,
          andino: 0.06,
          mexicano: 0.06,
          chileno: 0.02,
          caribe: 0.03,
          filipinas: 0,
        },
        age: {
          thirties: 0.12,
          '': 0.34,
          fifties: 0.11,
          twenties: 0.19,
          teens: 0.03,
          fourties: 0.08,
          sixties: 0.13,
          eighties: 0,
          seventies: 0,
          nineties: 0,
        },
        gender: { male: 0.55, '': 0.34, other: 0.01, female: 0.1 },
      },
      users: 8252,
      duration: 798568008,
      buckets: {
        dev: 13221,
        invalidated: 20340,
        other: 14476,
        test: 13221,
        train: 64353,
        validated: 112127,
      },
      size: 6226191576,
      avgDurationSecs: 5.382,
      validDurationSecs: 603490.113,
      totalHrs: 221.82,
      validHrs: 167.63,
    },
    'zh-CN': {
      clips: 19468,
      splits: {
        accent: {
          '110000': 0.05,
          '120000': 0,
          '130000': 0.04,
          '140000': 0,
          '150000': 0,
          '210000': 0.02,
          '220000': 0,
          '230000': 0.03,
          '310000': 0.11,
          '320000': 0.04,
          '330000': 0.07,
          '340000': 0.01,
          '350000': 0.02,
          '360000': 0.02,
          '370000': 0.03,
          '410000': 0.03,
          '420000': 0.01,
          '430000': 0.02,
          '440000': 0.03,
          '450000': 0.01,
          '500000': 0.03,
          '510000': 0.04,
          '520000': 0,
          '530000': 0,
          '610000': 0.01,
          '620000': 0,
          '640000': 0,
          '650000': 0.01,
          '710000': 0.01,
          '810000': 0,
          '': 0.35,
        },
        age: {
          '': 0.26,
          teens: 0.11,
          twenties: 0.4,
          thirties: 0.19,
          fourties: 0.04,
          nineties: 0,
          fifties: 0,
        },
        gender: { '': 0.26, male: 0.64, female: 0.1, other: 0 },
      },
      users: 963,
      duration: 111707304,
      buckets: {
        dev: 4843,
        invalidated: 1948,
        other: 612,
        test: 4898,
        train: 7079,
        validated: 16898,
      },
      size: 873632587,
      avgDurationSecs: 5.738,
      validDurationSecs: 96960.655,
      totalHrs: 31.02,
      validHrs: 26.93,
    },
    mn: {
      clips: 8262,
      splits: {
        accent: { '': 1 },
        age: {
          thirties: 0.28,
          '': 0.3,
          twenties: 0.29,
          fourties: 0.01,
          teens: 0.03,
          nineties: 0.09,
        },
        gender: { male: 0.36, '': 0.3, female: 0.25, other: 0.09 },
      },
      users: 296,
      duration: 44849040,
      buckets: {
        dev: 1761,
        invalidated: 580,
        other: 1311,
        test: 1759,
        train: 2018,
        validated: 6371,
      },
      size: 350541861,
      avgDurationSecs: 5.428,
      validDurationSecs: 34584.027,
      totalHrs: 12.45,
      validHrs: 9.6,
    },
    sah: {
      clips: 3860,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.35,
          twenties: 0.02,
          fourties: 0.07,
          thirties: 0.44,
          teens: 0.11,
          fifties: 0,
        },
        gender: { '': 0.35, male: 0.54, female: 0.1 },
      },
      users: 37,
      duration: 23069640,
      buckets: {
        dev: 246,
        invalidated: 76,
        other: 1448,
        test: 668,
        train: 1420,
        validated: 2336,
      },
      size: 176687745,
      avgDurationSecs: 5.977,
      validDurationSecs: 13961.316,
      totalHrs: 6.4,
      validHrs: 3.87,
    },
    dv: {
      clips: 6057,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.08,
          twenties: 0.3,
          thirties: 0.56,
          fourties: 0.02,
          teens: 0.04,
        },
        gender: { '': 0.08, male: 0.28, female: 0.64 },
      },
      users: 101,
      duration: 32793336,
      buckets: {
        dev: 1278,
        invalidated: 290,
        other: 1753,
        test: 1188,
        train: 1538,
        validated: 4014,
      },
      size: 252447683,
      avgDurationSecs: 5.414,
      validDurationSecs: 21732.285,
      totalHrs: 9.1,
      validHrs: 6.03,
    },
    rw: {
      clips: 13402,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.51,
          twenties: 0.36,
          thirties: 0.01,
          teens: 0.12,
          fourties: 0,
        },
        gender: { '': 0.51, male: 0.41, female: 0.08 },
      },
      users: 129,
      duration: 62166720,
      buckets: {
        dev: 62,
        invalidated: 58,
        other: 13141,
        test: 59,
        train: 78,
        validated: 199,
      },
      size: 486618454,
      avgDurationSecs: 4.639,
      validDurationSecs: 923.084,
      totalHrs: 17.26,
      validHrs: 0.25,
    },
    'sv-SE': {
      clips: 7064,
      splits: {
        accent: { '': 1 },
        age: {
          thirties: 0.28,
          '': 0.18,
          teens: 0.14,
          fifties: 0.16,
          twenties: 0.18,
          fourties: 0.05,
        },
        gender: { male: 0.74, '': 0.18, female: 0.08 },
      },
      users: 99,
      duration: 21861816,
      buckets: {
        dev: 1349,
        invalidated: 175,
        other: 991,
        test: 1595,
        train: 2152,
        validated: 5898,
      },
      size: 161284616,
      avgDurationSecs: 3.095,
      validDurationSecs: 18253.255,
      totalHrs: 6.07,
      validHrs: 5.07,
    },
    ru: {
      clips: 50631,
      splits: {
        accent: { '': 1 },
        age: {
          twenties: 0.54,
          teens: 0.04,
          '': 0.06,
          fourties: 0.2,
          thirties: 0.09,
          fifties: 0.07,
          sixties: 0,
        },
        gender: { male: 0.71, '': 0.06, other: 0, female: 0.23 },
      },
      users: 496,
      duration: 275609496,
      buckets: {
        dev: 6110,
        invalidated: 1825,
        other: 710,
        test: 6300,
        train: 10352,
        validated: 47987,
      },
      size: 2131930453,
      avgDurationSecs: 5.443,
      validDurationSecs: 261216.9,
      totalHrs: 76.55,
      validHrs: 72.56,
    },
    id: {
      clips: 3618,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.13,
          twenties: 0.6,
          thirties: 0.25,
          teens: 0.02,
          fifties: 0,
          fourties: 0,
        },
        gender: { '': 0.13, male: 0.82, female: 0.04 },
      },
      users: 56,
      duration: 13691112,
      buckets: {
        dev: 792,
        invalidated: 110,
        other: 627,
        test: 844,
        train: 1243,
        validated: 2881,
      },
      size: 106786387,
      avgDurationSecs: 3.784,
      validDurationSecs: 10902.182,
      totalHrs: 3.8,
      validHrs: 3.02,
    },
    ar: {
      clips: 12275,
      splits: {
        accent: { '': 1 },
        age: {
          thirties: 0.22,
          '': 0.28,
          twenties: 0.43,
          fourties: 0.01,
          teens: 0.07,
        },
        gender: { female: 0.24, '': 0.28, male: 0.48, other: 0 },
      },
      users: 228,
      duration: 45769368,
      buckets: {
        dev: 1758,
        invalidated: 851,
        other: 4585,
        test: 1695,
        train: 2029,
        validated: 6839,
      },
      size: 346519122,
      avgDurationSecs: 3.729,
      validDurationSecs: 25500.343,
      totalHrs: 12.71,
      validHrs: 7.08,
    },
    ta: {
      clips: 3997,
      splits: {
        accent: { '': 1 },
        age: { twenties: 0.39, thirties: 0.47, '': 0.11, fourties: 0.03 },
        gender: { male: 0.67, '': 0.2, other: 0.03, female: 0.1 },
      },
      users: 91,
      duration: 17949792,
      buckets: {
        dev: 384,
        invalidated: 184,
        other: 1285,
        test: 786,
        train: 1358,
        validated: 2528,
      },
      size: 139043206,
      avgDurationSecs: 4.491,
      validDurationSecs: 11352.783,
      totalHrs: 4.98,
      validHrs: 3.15,
    },
    ia: {
      clips: 3466,
      splits: {
        accent: { '': 1 },
        age: {
          seventies: 0.4,
          fourties: 0.48,
          '': 0.04,
          twenties: 0.04,
          thirties: 0.02,
          teens: 0.01,
        },
        gender: { male: 0.94, '': 0.04, female: 0.02 },
      },
      users: 12,
      duration: 14255976,
      buckets: {
        dev: 111,
        invalidated: 79,
        other: 2409,
        test: 236,
        train: 631,
        validated: 978,
      },
      size: 111363014,
      avgDurationSecs: 4.113,
      validDurationSecs: 4022.604,
      totalHrs: 3.95,
      validHrs: 1.11,
    },
    pt: {
      clips: 24243,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.09,
          twenties: 0.41,
          teens: 0.02,
          thirties: 0.46,
          fourties: 0.01,
          sixties: 0,
          fifties: 0.01,
          seventies: 0,
        },
        gender: { '': 0.09, male: 0.89, female: 0.02, other: 0 },
      },
      users: 354,
      duration: 106563624,
      buckets: {
        dev: 3318,
        invalidated: 865,
        other: 1192,
        test: 4023,
        train: 6014,
        validated: 22131,
      },
      size: 774265004,
      avgDurationSecs: 4.396,
      validDurationSecs: 97280.022,
      totalHrs: 29.6,
      validHrs: 27.02,
    },
    lv: {
      clips: 6488,
      splits: {
        accent: { '': 1 },
        age: {
          thirties: 0.6,
          fourties: 0.04,
          '': 0.19,
          twenties: 0.13,
          teens: 0.04,
          fifties: 0,
        },
        gender: { male: 0.64, female: 0.17, '': 0.19 },
      },
      users: 86,
      duration: 22526328,
      buckets: {
        dev: 1125,
        invalidated: 120,
        other: 1270,
        test: 1629,
        train: 2336,
        validated: 5098,
      },
      size: 168378556,
      avgDurationSecs: 3.472,
      validDurationSecs: 17700.25,
      totalHrs: 6.25,
      validHrs: 4.91,
    },
    ja: {
      clips: 2512,
      splits: {
        accent: { '': 1 },
        age: {
          twenties: 0.67,
          '': 0.18,
          teens: 0.07,
          fifties: 0.06,
          thirties: 0.01,
        },
        gender: { male: 0.81, '': 0.18, female: 0 },
      },
      users: 52,
      duration: 11333208,
      buckets: {
        dev: 635,
        invalidated: 73,
        other: 1,
        test: 684,
        train: 1119,
        validated: 2438,
      },
      size: 88830917,
      avgDurationSecs: 4.512,
      validDurationSecs: 10999.348,
      totalHrs: 3.14,
      validHrs: 3.05,
    },
    vot: {
      clips: 108,
      splits: { accent: { '': 1 }, age: { '': 1 }, gender: { '': 1 } },
      users: 2,
      duration: 440760,
      buckets: { invalidated: 0, other: 108, validated: 0 },
      size: 3446231,
      avgDurationSecs: 4.081,
      validDurationSecs: 0,
      totalHrs: 0.12,
      validHrs: 0,
    },
    ab: {
      clips: 58,
      splits: { accent: { '': 1 }, age: { '': 1 }, gender: { '': 1 } },
      users: 3,
      duration: 370272,
      buckets: {
        dev: 0,
        invalidated: 0,
        other: 56,
        test: 0,
        train: 2,
        validated: 2,
      },
      size: 2902360,
      avgDurationSecs: 6.384,
      validDurationSecs: 12.768,
      totalHrs: 0.1,
      validHrs: 0,
    },
    'zh-HK': {
      clips: 462,
      splits: {
        accent: { '': 1 },
        age: {
          fourties: 0.22,
          thirties: 0.12,
          '': 0.39,
          teens: 0.01,
          fifties: 0.02,
          seventies: 0.15,
          sixties: 0.09,
        },
        gender: { male: 0.37, '': 0.39, female: 0.24 },
      },
      users: 15,
      duration: 2658216,
      buckets: {
        dev: 5,
        invalidated: 9,
        other: 407,
        test: 14,
        train: 27,
        validated: 46,
      },
      size: 20859996,
      avgDurationSecs: 5.754,
      validDurationSecs: 264.671,
      totalHrs: 0.73,
      validHrs: 0.07,
    },
    'rm-sursilv': {
      clips: 20,
      splits: { accent: { '': 1 }, age: { '': 1 }, gender: { '': 1 } },
      users: 3,
      duration: 108936,
      buckets: {
        dev: 4,
        invalidated: 2,
        other: 1,
        test: 4,
        train: 9,
        validated: 17,
      },
      size: 857887,
      avgDurationSecs: 5.447,
      validDurationSecs: 92.596,
      totalHrs: 0.03,
      validHrs: 0.02,
    },
  },
  totalDuration: 15325973658,
  totalValidDurationSecs: 12245003,
  totalHrs: 4257,
  totalValidHrs: 3401,
};
