exports.stats = {
  bundleURL: 'cv-corpus-5.1-singleword/cv-corpus-5.1-singleword.tar.gz',
  multilingual: false,
  locales: {
    es: {
      clips: 68817,
      splits: {
        accent: {
          surpeninsular: 0.01,
          '': 0.8,
          americacentral: 0.02,
          centrosurpeninsular: 0.02,
          mexicano: 0.05,
          caribe: 0.02,
          nortepeninsular: 0.02,
          andino: 0.04,
          rioplatense: 0.02,
          chileno: 0.01,
          canario: 0,
          filipinas: 0,
        },
        age: {
          sixties: 0.01,
          '': 0.74,
          thirties: 0.05,
          fifties: 0.02,
          fourties: 0.04,
          twenties: 0.11,
          teens: 0.04,
          seventies: 0,
          nineties: 0,
          eighties: 0,
        },
        gender: { male: 0.17, '': 0.74, female: 0.09, other: 0 },
      },
      users: 8968,
      duration: 200607380,
      buckets: {
        dev: 6238,
        invalidated: 6461,
        other: 40337,
        test: 6238,
        train: 9979,
        validated: 22455,
      },
      avgDurationSecs: 2.915,
      validDurationSecs: 65458.226,
      totalHrs: 55.72,
      validHrs: 18.18,
    },
    pl: {
      clips: 899,
      splits: {
        accent: { '': 1 },
        age: {
          twenties: 0.22,
          thirties: 0.16,
          fourties: 0.03,
          teens: 0.09,
          '': 0.48,
          sixties: 0.01,
        },
        gender: { female: 0.12, male: 0.38, '': 0.48, other: 0.01 },
      },
      users: 86,
      duration: 2129666,
      buckets: {
        dev: 294,
        invalidated: 30,
        other: 0,
        test: 296,
        train: 308,
        validated: 898,
      },
      avgDurationSecs: 2.369,
      validDurationSecs: 2127.297,
      totalHrs: 0.59,
      validHrs: 0.59,
    },
    ca: {
      clips: 2279,
      splits: {
        accent: {
          valencian: 0.12,
          '': 0.44,
          central: 0.4,
          northern: 0.01,
          northwestern: 0.03,
          balearic: 0.01,
        },
        age: {
          fifties: 0.1,
          '': 0.39,
          sixties: 0.04,
          thirties: 0.14,
          fourties: 0.13,
          twenties: 0.13,
          teens: 0.05,
          seventies: 0.02,
        },
        gender: { male: 0.45, '': 0.39, female: 0.15, other: 0.01 },
      },
      users: 213,
      duration: 5963630,
      buckets: {
        dev: 733,
        invalidated: 122,
        other: 0,
        test: 733,
        train: 784,
        validated: 2250,
      },
      avgDurationSecs: 2.617,
      validDurationSecs: 5887.744,
      totalHrs: 1.65,
      validHrs: 1.63,
    },
    tr: {
      clips: 381,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.46,
          fourties: 0.06,
          twenties: 0.31,
          teens: 0.04,
          thirties: 0.14,
        },
        gender: { '': 0.46, male: 0.46, female: 0.07 },
      },
      users: 46,
      duration: 962552,
      buckets: {
        dev: 114,
        invalidated: 36,
        other: 21,
        test: 112,
        train: 125,
        validated: 351,
      },
      avgDurationSecs: 2.526,
      validDurationSecs: 886.761,
      totalHrs: 0.26,
      validHrs: 0.24,
    },
    ru: {
      clips: 1513,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.42,
          teens: 0.15,
          thirties: 0.15,
          twenties: 0.25,
          fourties: 0.04,
        },
        gender: { '': 0.44, male: 0.5, female: 0.05, other: 0.01 },
      },
      users: 149,
      duration: 3548113,
      buckets: {
        dev: 478,
        invalidated: 80,
        other: 0,
        test: 480,
        train: 518,
        validated: 1476,
      },
      avgDurationSecs: 2.345,
      validDurationSecs: 3461.345,
      totalHrs: 0.98,
      validHrs: 0.96,
    },
    pt: {
      clips: 983,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.39,
          fourties: 0.12,
          twenties: 0.28,
          thirties: 0.17,
          fifties: 0.02,
          sixties: 0.01,
          teens: 0.01,
        },
        gender: { '': 0.39, male: 0.54, female: 0.05, other: 0.01 },
      },
      users: 115,
      duration: 2792491,
      buckets: {
        dev: 312,
        invalidated: 74,
        other: 31,
        test: 318,
        train: 344,
        validated: 974,
      },
      avgDurationSecs: 2.841,
      validDurationSecs: 2766.924,
      totalHrs: 0.77,
      validHrs: 0.76,
    },
    nl: {
      clips: 457,
      splits: {
        accent: { suriname: 0.03, netherlands: 0.39, '': 0.43, belgium: 0.15 },
        age: {
          '': 0.4,
          twenties: 0.37,
          sixties: 0,
          thirties: 0.06,
          fifties: 0.06,
          fourties: 0.1,
        },
        gender: { male: 0.54, female: 0.06, '': 0.4 },
      },
      users: 47,
      duration: 1118014,
      buckets: {
        dev: 135,
        invalidated: 15,
        other: 0,
        test: 144,
        train: 168,
        validated: 447,
      },
      avgDurationSecs: 2.446,
      validDurationSecs: 1093.55,
      totalHrs: 0.31,
      validHrs: 0.3,
    },
    de: {
      clips: 13133,
      splits: {
        accent: {
          germany: 0.25,
          austria: 0.02,
          '': 0.71,
          france: 0,
          italy: 0,
          switzerland: 0.01,
          united_states: 0,
          russia: 0,
          united_kingdom: 0,
        },
        age: {
          fourties: 0.04,
          fifties: 0.06,
          twenties: 0.12,
          thirties: 0.07,
          '': 0.63,
          seventies: 0.01,
          teens: 0.06,
          sixties: 0.02,
          eighties: 0,
        },
        gender: { male: 0.3, female: 0.06, '': 0.63, other: 0 },
      },
      users: 1457,
      duration: 35864336,
      buckets: {
        dev: 3719,
        invalidated: 1310,
        other: 1,
        test: 3717,
        train: 4818,
        validated: 12254,
      },
      avgDurationSecs: 2.731,
      validDurationSecs: 33463.913,
      totalHrs: 9.96,
      validHrs: 9.29,
    },
    ta: {
      clips: 221,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.44,
          fifties: 0.07,
          fourties: 0,
          twenties: 0.26,
          thirties: 0.18,
          teens: 0.05,
        },
        gender: { '': 0.49, male: 0.35, female: 0.16 },
      },
      users: 32,
      duration: 738774,
      buckets: {
        dev: 62,
        invalidated: 37,
        other: 5,
        test: 66,
        train: 77,
        validated: 205,
      },
      avgDurationSecs: 3.343,
      validDurationSecs: 685.288,
      totalHrs: 0.2,
      validHrs: 0.19,
    },
    ar: {
      clips: 332,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.4,
          thirties: 0.1,
          twenties: 0.33,
          teens: 0.08,
          fifties: 0.04,
          fourties: 0.04,
        },
        gender: { '': 0.39, male: 0.54, female: 0.07 },
      },
      users: 44,
      duration: 884372,
      buckets: {
        dev: 94,
        invalidated: 24,
        other: 0,
        test: 96,
        train: 126,
        validated: 316,
      },
      avgDurationSecs: 2.664,
      validDurationSecs: 841.752,
      totalHrs: 0.24,
      validHrs: 0.23,
    },
    en: {
      clips: 40199,
      splits: {
        accent: {
          '': 0.76,
          us: 0.11,
          england: 0.04,
          indian: 0.04,
          australia: 0.01,
          canada: 0.01,
          newzealand: 0,
          scotland: 0,
          hongkong: 0.01,
          ireland: 0,
          singapore: 0,
          philippines: 0,
          malaysia: 0,
          african: 0,
          wales: 0,
        },
        age: {
          '': 0.61,
          twenties: 0.19,
          teens: 0.05,
          fourties: 0.04,
          thirties: 0.07,
          sixties: 0.01,
          fifties: 0.02,
          seventies: 0.01,
          nineties: 0,
          eighties: 0,
        },
        gender: { '': 0.62, female: 0.09, male: 0.29, other: 0.01 },
      },
      users: 4990,
      duration: 117312884,
      buckets: {
        dev: 7201,
        invalidated: 3556,
        other: 12452,
        test: 7205,
        train: 12717,
        validated: 27230,
      },
      avgDurationSecs: 2.918,
      validDurationSecs: 79465.405,
      totalHrs: 32.58,
      validHrs: 22.07,
    },
    fr: {
      clips: 19603,
      splits: {
        accent: {
          france: 0.2,
          '': 0.77,
          germany: 0,
          canada: 0.01,
          united_kingdom: 0,
          belgium: 0.01,
          switzerland: 0.01,
          reunion: 0,
          senegal: 0,
          martinique: 0,
          congo_brazzaville: 0,
          cameroon: 0,
          united_states: 0,
          madagascar: 0,
          romania: 0,
          benin: 0,
        },
        age: {
          sixties: 0.01,
          '': 0.72,
          twenties: 0.09,
          thirties: 0.05,
          fourties: 0.05,
          teens: 0.05,
          fifties: 0.03,
          seventies: 0,
        },
        gender: { male: 0.22, '': 0.72, female: 0.06, other: 0.01 },
      },
      users: 2367,
      duration: 54892201,
      buckets: {
        dev: 4943,
        invalidated: 2191,
        other: 1484,
        test: 4948,
        train: 7051,
        validated: 16942,
      },
      avgDurationSecs: 2.8,
      validDurationSecs: 47440.885,
      totalHrs: 15.24,
      validHrs: 13.17,
    },
    cy: {
      clips: 232,
      splits: {
        accent: { united_kingdom: 0.63, '': 0.37 },
        age: {
          twenties: 0.22,
          thirties: 0.13,
          '': 0.3,
          teens: 0.09,
          fifties: 0.13,
          fourties: 0.09,
          sixties: 0.03,
          seventies: 0.01,
        },
        gender: { female: 0.39, male: 0.29, '': 0.32 },
      },
      users: 28,
      duration: 699792,
      buckets: {
        dev: 80,
        invalidated: 9,
        other: 5,
        test: 79,
        train: 90,
        validated: 249,
      },
      avgDurationSecs: 3.016,
      validDurationSecs: 751.07,
      totalHrs: 0.19,
      validHrs: 0.2,
    },
    eo: {
      clips: 792,
      splits: {
        accent: { '': 0.66, internacia: 0.34 },
        age: {
          '': 0.45,
          fourties: 0.04,
          twenties: 0.27,
          teens: 0.05,
          eighties: 0.02,
          sixties: 0.03,
          thirties: 0.14,
          fifties: 0.02,
        },
        gender: { '': 0.47, male: 0.46, other: 0.02, female: 0.06 },
      },
      users: 75,
      duration: 1935968,
      buckets: {
        dev: 246,
        invalidated: 32,
        other: 0,
        test: 248,
        train: 276,
        validated: 770,
      },
      avgDurationSecs: 2.444,
      validDurationSecs: 1882.191,
      totalHrs: 0.53,
      validHrs: 0.52,
    },
    ja: {
      clips: 524,
      splits: {
        accent: { '': 1 },
        age: {
          teens: 0.04,
          twenties: 0.53,
          '': 0.29,
          fifties: 0.02,
          fourties: 0.05,
          thirties: 0.07,
        },
        gender: { male: 0.43, female: 0.28, '': 0.27, other: 0.02 },
      },
      users: 62,
      duration: 1200960,
      buckets: {
        dev: 161,
        invalidated: 22,
        other: 0,
        test: 162,
        train: 180,
        validated: 503,
      },
      avgDurationSecs: 2.292,
      validDurationSecs: 1152.83,
      totalHrs: 0.33,
      validHrs: 0.32,
    },
    id: {
      clips: 95,
      splits: {
        accent: { '': 1 },
        age: { '': 0.31, twenties: 0.57, teens: 0.13 },
        gender: { '': 0.31, male: 0.49, female: 0.13, other: 0.07 },
      },
      users: 10,
      duration: 243816,
      buckets: {
        dev: 22,
        invalidated: 9,
        other: 0,
        test: 31,
        train: 48,
        validated: 101,
      },
      avgDurationSecs: 2.566,
      validDurationSecs: 259.215,
      totalHrs: 0.06,
      validHrs: 0.07,
    },
    eu: {
      clips: 404,
      splits: {
        accent: { '': 0.89, mendebalekoa: 0.05, erdialdekoa_nafarra: 0.06 },
        age: {
          '': 0.86,
          teens: 0.02,
          fourties: 0.05,
          fifties: 0.04,
          thirties: 0.03,
        },
        gender: { '': 0.86, female: 0.07, male: 0.07 },
      },
      users: 60,
      duration: 1178544,
      buckets: {
        dev: 57,
        invalidated: 13,
        other: 260,
        test: 61,
        train: 75,
        validated: 193,
      },
      avgDurationSecs: 2.917,
      validDurationSecs: 563.017,
      totalHrs: 0.32,
      validHrs: 0.15,
    },
    tt: {
      clips: 22,
      splits: {
        accent: { '': 1 },
        age: { thirties: 0.77, '': 0.23 },
        gender: { male: 0.77, '': 0.23 },
      },
      users: 3,
      duration: 69672,
      buckets: {
        dev: 0,
        invalidated: 2,
        other: 16,
        test: 0,
        train: 11,
        validated: 11,
      },
      avgDurationSecs: 3.167,
      validDurationSecs: 34.836,
      totalHrs: 0.01,
      validHrs: 0,
    },
  },
  overall: {
    size: 3251558895,
    checksum:
      'cdf46ebdaeb4699ba6786907a1573ccdde28a114e8351caab03a54a8e7c47b49',
  },
  totalDuration: 432143165,
  totalValidDurationSecs: 248222,
  totalHrs: 120,
  totalValidHrs: 68,
};
