exports.stats = {
  bundleURLTemplate: 'cv-corpus-6.0-2020-12-11/{locale}.tar.gz',
  date: '2020-12-11',
  name: 'Common Voice Corpus 6.0',
  multilingual: true,
  exclude: true,
  locales: {
    en: {
      clips: 1582837,
      splits: {
        accent: {
          '': 0.51,
          canada: 0.03,
          england: 0.08,
          us: 0.24,
          indian: 0.05,
          australia: 0.03,
          malaysia: 0,
          newzealand: 0.01,
          african: 0.01,
          ireland: 0.01,
          philippines: 0,
          singapore: 0,
          scotland: 0.02,
          hongkong: 0,
          bermuda: 0,
          southatlandtic: 0,
          wales: 0,
          other: 0.01,
        },
        age: {
          '': 0.37,
          twenties: 0.24,
          sixties: 0.04,
          thirties: 0.14,
          teens: 0.06,
          seventies: 0.01,
          fourties: 0.1,
          fifties: 0.04,
          eighties: 0,
          nineties: 0,
        },
        gender: { '': 0.8, female: 0.2 },
      },
      users: 66151,
      duration: 7845900691,
      buckets: {
        dev: 16164,
        invalidated: 190028,
        other: 167678,
        reported: 1731,
        test: 16164,
        train: 564641,
        validated: 1226615,
      },
      reportedSentences: 1680,
      size: 60612746725,
      checksum:
        'f030e6cca6163c841d5d621ced640b0e70a5d6dcd78def30273883ceae5438fb',
      avgDurationSecs: 4.957,
      validDurationSecs: 6080158.27,
      totalHrs: 2179.41,
      validHrs: 1688.93,
    },
    de: {
      clips: 607943,
      splits: {
        accent: {
          russia: 0,
          germany: 0.65,
          '': 0.31,
          austria: 0.03,
          liechtenstein: 0,
          switzerland: 0.01,
          france: 0,
          other: 0,
          poland: 0,
          united_kingdom: 0,
          hungary: 0,
          netherlands: 0,
          namibia: 0,
          slovakia: 0,
          united_states: 0,
          italy: 0,
          czechia: 0,
          brazil: 0,
          canada: 0,
          turkey: 0,
          slovenia: 0,
          luxembourg: 0,
          belgium: 0,
          denmark: 0,
          greece: 0,
          lithuania: 0,
        },
        age: {
          twenties: 0.22,
          fourties: 0.2,
          '': 0.23,
          thirties: 0.16,
          teens: 0.03,
          sixties: 0.03,
          fifties: 0.11,
          seventies: 0,
          eighties: 0,
          nineties: 0,
        },
        gender: { female: 0.2, '': 0.8 },
      },
      users: 12655,
      buckets: {
        dev: 15588,
        invalidated: 32806,
        other: 10177,
        reported: 3708,
        test: 15588,
        train: 246372,
        validated: 565087,
      },
      reportedSentences: 3698,
      duration: 3009518417,
      size: 23283795490,
      checksum:
        'd4df4396998d6436f2fe30d45c0e75f1064e187c6fab0d55202bd272a3bdb86b',
      avgDurationSecs: 4.95,
      validDurationSecs: 2797367.078,
      totalHrs: 835.97,
      validHrs: 777.04,
    },
    fr: {
      clips: 504180,
      splits: {
        accent: {
          '': 0.32,
          france: 0.62,
          germany: 0,
          belgium: 0.02,
          switzerland: 0.01,
          guadeloupe: 0,
          reunion: 0,
          monaco: 0,
          tunisia: 0,
          canada: 0.02,
          other: 0,
          mayotte: 0,
          algeria: 0,
          netherlands: 0,
          senegal: 0,
          martinique: 0,
          portugal: 0,
          united_states: 0,
          cote_d_ivoire: 0,
          st_pierre_et_miquelon: 0,
          french_guiana: 0,
          benin: 0,
          italy: 0,
          united_kingdom: 0,
          madagascar: 0,
          cameroon: 0,
          new_caledonia: 0,
          haiti: 0,
          morocco: 0,
          romania: 0,
          congo_kinshasa: 0,
          congo_brazzaville: 0,
          gabon: 0,
        },
        age: {
          twenties: 0.2,
          thirties: 0.19,
          '': 0.3,
          teens: 0.04,
          fourties: 0.12,
          fifties: 0.11,
          sixties: 0.04,
          seventies: 0.01,
          eighties: 0,
        },
        gender: { female: 0.17, '': 0.83 },
      },
      users: 12950,
      duration: 2456111530,
      buckets: {
        dev: 15758,
        invalidated: 40182,
        other: 5286,
        reported: 3178,
        test: 15758,
        train: 297089,
        validated: 459109,
      },
      reportedSentences: 3150,
      size: 19130019144,
      checksum:
        '4c3030771eb49af63157fb0a59ff18e3484ae1d7946c4beb9d9f150b96021b98',
      avgDurationSecs: 4.871,
      validDurationSecs: 2236548.273,
      totalHrs: 682.25,
      validHrs: 621.26,
    },
    cy: {
      clips: 94528,
      splits: {
        accent: { united_kingdom: 0.52, '': 0.47, other: 0.01 },
        age: {
          fourties: 0.17,
          twenties: 0.14,
          sixties: 0.07,
          fifties: 0.09,
          '': 0.41,
          thirties: 0.1,
          seventies: 0.01,
          eighties: 0,
          teens: 0.02,
        },
        gender: { '': 0.95, female: 0.05 },
      },
      users: 1382,
      duration: 447428421,
      buckets: {
        dev: 4754,
        invalidated: 3670,
        other: 18029,
        reported: 122,
        test: 4814,
        train: 6841,
        validated: 72852,
      },
      reportedSentences: 123,
      size: 3434447665,
      checksum:
        '16959b18a5c5ac84a48e11356ac712290618bc8c8cb38648cb05da49a3171b3d',
      avgDurationSecs: 4.733,
      validDurationSecs: 344829.631,
      totalHrs: 124.28,
      validHrs: 95.78,
    },
    br: {
      clips: 20095,
      splits: {
        accent: {
          other: 0,
          '': 0.74,
          kerneveg: 0.2,
          gwenedeg: 0.05,
          leoneg: 0,
          tregerieg: 0,
        },
        age: {
          twenties: 0.17,
          '': 0.37,
          fifties: 0.06,
          fourties: 0.08,
          thirties: 0.08,
          sixties: 0.2,
          seventies: 0.03,
          teens: 0.01,
        },
        gender: { female: 0.17, '': 0.83 },
      },
      users: 157,
      duration: 60895123,
      buckets: {
        dev: 2158,
        invalidated: 777,
        other: 10291,
        reported: 163,
        test: 2157,
        train: 2553,
        validated: 9027,
      },
      reportedSentences: 164,
      size: 465278116,
      checksum:
        'd852feae9adf63c5218527ebdfd17fbcffa4fc66b5c278fba375ad7d521dc6c8',
      avgDurationSecs: 3.03,
      validDurationSecs: 27355.077,
      totalHrs: 16.91,
      validHrs: 7.59,
    },
    cv: {
      clips: 11705,
      splits: {
        accent: { other: 0, '': 1 },
        age: {
          twenties: 0.48,
          '': 0.27,
          fourties: 0.09,
          thirties: 0.01,
          teens: 0.13,
          fifties: 0.02,
        },
        gender: { female: 0.29, '': 0.71 },
      },
      users: 92,
      duration: 58562220,
      buckets: {
        dev: 853,
        invalidated: 1348,
        other: 6279,
        reported: 74,
        test: 781,
        train: 979,
        validated: 4078,
      },
      reportedSentences: 75,
      size: 439332696,
      checksum:
        'd79149ef4ccf3ace3d44ecb9c4e448ab58edc65b7bde6175306a407604da3a31',
      avgDurationSecs: 5.003,
      validDurationSecs: 20402.967,
      totalHrs: 16.26,
      validHrs: 5.66,
    },
    tr: {
      clips: 20736,
      splits: {
        accent: { '': 0.96, other: 0.04 },
        age: {
          '': 0.23,
          thirties: 0.23,
          twenties: 0.47,
          teens: 0.04,
          fourties: 0.03,
          fifties: 0.01,
          sixties: 0,
        },
        gender: { '': 0.58, female: 0.42 },
      },
      users: 678,
      duration: 80991855,
      buckets: {
        dev: 1646,
        invalidated: 1736,
        other: 295,
        reported: 72,
        test: 1647,
        train: 1832,
        validated: 18705,
      },
      reportedSentences: 73,
      size: 620830479,
      checksum:
        '0f99593e1110d46dc619d4c61795544d19e061411b03cf34aff64cd5b7493d9c',
      avgDurationSecs: 3.906,
      validDurationSecs: 73059.059,
      totalHrs: 22.49,
      validHrs: 20.29,
    },
    tt: {
      clips: 27866,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.18,
          thirties: 0.75,
          twenties: 0.05,
          sixties: 0,
          fifties: 0.01,
          teens: 0,
          fourties: 0,
          seventies: 0.01,
        },
        gender: { '': 0.96, female: 0.04 },
      },
      users: 185,
      duration: 103646870,
      buckets: {
        dev: 2127,
        invalidated: 287,
        other: 1794,
        reported: 2,
        test: 4489,
        train: 11211,
        validated: 25785,
      },
      reportedSentences: 3,
      size: 777143015,
      checksum:
        '3b2c57acfac8d8d575d54603ee0b96df15a8d73f61c9d94ff6b84835f8bf8bfa',
      avgDurationSecs: 3.719,
      validDurationSecs: 95906.644,
      totalHrs: 28.79,
      validHrs: 26.64,
    },
    ky: {
      clips: 17385,
      splits: {
        accent: { '': 0.92, other: 0.08 },
        age: {
          thirties: 0.13,
          '': 0.06,
          fourties: 0.02,
          twenties: 0.78,
          teens: 0.01,
        },
        gender: { '': 0.66, female: 0.34 },
      },
      users: 134,
      duration: 80915736,
      buckets: {
        dev: 1509,
        invalidated: 992,
        other: 6756,
        reported: 0,
        test: 1525,
        train: 1942,
        validated: 9637,
      },
      reportedSentences: 1,
      size: 579432347,
      checksum:
        '10413ca4529863e2de2ac4ba0aa975b954bcad1be7fdb0ef11169078d2001c84',
      avgDurationSecs: 4.654,
      validDurationSecs: 44853.894,
      totalHrs: 22.47,
      validHrs: 12.45,
    },
    'ga-IE': {
      clips: 5891,
      splits: {
        accent: {
          '': 0.4,
          connachta: 0.27,
          mumhain: 0.15,
          other: 0.06,
          ulaidh: 0.12,
        },
        age: {
          twenties: 0.37,
          '': 0.19,
          thirties: 0.3,
          fourties: 0.07,
          sixties: 0,
          teens: 0.02,
          fifties: 0.06,
        },
        gender: { female: 0.31, '': 0.69 },
      },
      users: 101,
      duration: 20497419,
      buckets: {
        dev: 507,
        invalidated: 534,
        other: 1878,
        reported: 4,
        test: 496,
        train: 541,
        validated: 3479,
      },
      reportedSentences: 5,
      size: 156554524,
      checksum:
        'dad7a17fcc8bf7c3e154e08a769d5d550dc23b006d792c103611be3b97e39c2f',
      avgDurationSecs: 3.479,
      validDurationSecs: 12104.994,
      totalHrs: 5.69,
      validHrs: 3.36,
    },
    kab: {
      clips: 679785,
      splits: {
        accent: { '': 0.9, other: 0.1 },
        age: {
          fourties: 0.1,
          thirties: 0.3,
          '': 0.26,
          fifties: 0.19,
          twenties: 0.12,
          eighties: 0,
          teens: 0,
          sixties: 0.03,
          seventies: 0,
        },
        gender: { '': 0.96, female: 0.04 },
      },
      users: 1306,
      duration: 2241399940,
      buckets: {
        dev: 14622,
        invalidated: 18134,
        other: 88029,
        reported: 3092,
        test: 14622,
        train: 120530,
        validated: 573710,
      },
      reportedSentences: 3052,
      size: 17171374359,
      checksum:
        'bb0046c5b368993c16cd3537e5f0ac397aef97f3e54c817f39f57e7dec421e1b',
      avgDurationSecs: 3.297,
      validDurationSecs: 1891647.447,
      totalHrs: 622.61,
      validHrs: 525.45,
    },
    ca: {
      clips: 498357,
      splits: {
        accent: {
          valencian: 0.05,
          central: 0.67,
          '': 0.22,
          other: 0,
          balearic: 0.01,
          northwestern: 0.03,
          northern: 0.01,
        },
        age: {
          thirties: 0.13,
          fifties: 0.23,
          fourties: 0.16,
          twenties: 0.08,
          '': 0.17,
          sixties: 0.22,
          teens: 0.01,
          seventies: 0.01,
          eighties: 0,
          nineties: 0,
        },
        gender: { '': 0.93, female: 0.07 },
      },
      users: 5370,
      duration: 2683762302,
      buckets: {
        dev: 15711,
        invalidated: 18521,
        other: 68926,
        reported: 1189,
        test: 15711,
        train: 281374,
        validated: 412561,
      },
      reportedSentences: 1185,
      size: 20742924739,
      checksum:
        'ff0f4724e6de643a74fd4216f7d9afdab5e833c09d5dd78ab8c4c2f862c114ba',
      avgDurationSecs: 5.385,
      validDurationSecs: 2221731.929,
      totalHrs: 745.48,
      validHrs: 617.14,
    },
    'zh-TW': {
      clips: 87293,
      splits: {
        accent: {
          '': 0.51,
          taipei_city: 0.16,
          other: 0.09,
          kaohsiung_city: 0.03,
          new_taipei_city: 0.05,
          tainan_city: 0.04,
          taichung_city: 0.03,
          yilan_county: 0.01,
          hong_kong: 0.01,
          taoyuan_city: 0.01,
          hsinchu_city: 0.02,
          chiayi_city: 0,
          yunlin_county: 0.02,
          nantou_county: 0,
          changhua_county: 0.01,
          pingtung_county: 0.01,
          hsinchu_county: 0,
          chiayi_county: 0,
          keelung_city: 0,
          hualien_county: 0.01,
          kinmen_county: 0,
        },
        age: {
          thirties: 0.24,
          twenties: 0.36,
          teens: 0.04,
          '': 0.23,
          fifties: 0.01,
          seventies: 0,
          fourties: 0.12,
          sixties: 0,
        },
        gender: { '': 0.77, female: 0.23 },
      },
      users: 1444,
      duration: 282305146,
      buckets: {
        dev: 2888,
        invalidated: 3747,
        other: 21701,
        reported: 38,
        test: 2888,
        train: 3496,
        validated: 61845,
      },
      reportedSentences: 39,
      size: 2182782100,
      checksum:
        'b85c7744e65f35e99516082b45128125a396edfc5466710af273828535be9a5c',
      avgDurationSecs: 3.234,
      validDurationSecs: 200006.435,
      totalHrs: 78.41,
      validHrs: 55.55,
    },
    sl: {
      clips: 7263,
      splits: {
        accent: { other: 0.04, '': 0.96 },
        age: {
          twenties: 0.62,
          teens: 0.11,
          '': 0.06,
          sixties: 0.03,
          fifties: 0.11,
          fourties: 0.01,
          thirties: 0.06,
        },
        gender: { '': 0.41, female: 0.59 },
      },
      users: 82,
      duration: 28545274,
      buckets: {
        dev: 534,
        invalidated: 140,
        other: 2130,
        reported: 13,
        test: 996,
        train: 1972,
        validated: 4993,
      },
      reportedSentences: 14,
      size: 222749198,
      checksum:
        'c597481b8d1c60878a96bcda42e17e2a185c314fda876e6fe38eb099d85a05ce',
      avgDurationSecs: 3.93,
      validDurationSecs: 19623.648,
      totalHrs: 7.92,
      validHrs: 5.45,
    },
    it: {
      clips: 129115,
      splits: {
        accent: { '': 0.95, other: 0.05 },
        age: {
          thirties: 0.19,
          twenties: 0.23,
          '': 0.31,
          fifties: 0.09,
          fourties: 0.14,
          seventies: 0.01,
          sixties: 0.03,
          teens: 0.02,
          eighties: 0,
          nineties: 0,
        },
        gender: { '': 0.81, female: 0.19 },
      },
      users: 5727,
      duration: 717521160,
      buckets: {
        dev: 12933,
        invalidated: 12190,
        other: 14386,
        reported: 1031,
        test: 12938,
        train: 58158,
        validated: 102741,
      },
      reportedSentences: 1011,
      size: 5585770901,
      checksum:
        '947f2bd67cd202f9fcba1ed9ca4fc7bbaa4fbc43364dc7dad0f6e66a5e502eb6',
      avgDurationSecs: 5.557,
      validDurationSecs: 570954.897,
      totalHrs: 199.31,
      validHrs: 158.59,
    },
    nl: {
      clips: 55457,
      splits: {
        accent: {
          '': 0.26,
          netherlands: 0.61,
          belgium: 0.11,
          other: 0,
          germany: 0,
          france: 0,
          suriname: 0.01,
          curacao: 0,
        },
        age: {
          '': 0.29,
          twenties: 0.24,
          fourties: 0.18,
          thirties: 0.14,
          teens: 0.04,
          fifties: 0.1,
          sixties: 0.01,
        },
        gender: { '': 0.81, female: 0.19 },
      },
      users: 1012,
      duration: 226181377,
      buckets: {
        dev: 4927,
        invalidated: 3310,
        other: 72,
        reported: 199,
        test: 5703,
        train: 9456,
        validated: 52441,
      },
      reportedSentences: 199,
      size: 1741815049,
      checksum:
        'c16ab7b20c87410e8d64e3ebd6e20f890d395e231a9f9c34ab759d917582f052',
      avgDurationSecs: 4.079,
      validDurationSecs: 213880.621,
      totalHrs: 62.82,
      validHrs: 59.41,
    },
    cnh: {
      clips: 5799,
      splits: {
        accent: { '': 0.85, other: 0.15 },
        age: {
          '': 0.51,
          twenties: 0.36,
          fourties: 0.01,
          teens: 0.02,
          thirties: 0.08,
          fifties: 0.02,
        },
        gender: { '': 0.8, female: 0.2 },
      },
      users: 297,
      duration: 20663664,
      buckets: {
        dev: 743,
        invalidated: 472,
        other: 2824,
        reported: 8,
        test: 762,
        train: 865,
        validated: 2503,
      },
      reportedSentences: 9,
      size: 161334217,
      checksum:
        '1e1b4af7c20e6f1b264e63eab763ecea6417ae216c9601e6bbcf21ec50752353',
      avgDurationSecs: 3.563,
      validDurationSecs: 8918.978,
      totalHrs: 5.73,
      validHrs: 2.47,
    },
    eo: {
      clips: 65726,
      splits: {
        accent: { '': 0.29, internacia: 0.7, other: 0 },
        age: {
          twenties: 0.32,
          thirties: 0.11,
          '': 0.12,
          fourties: 0.31,
          fifties: 0.03,
          seventies: 0.01,
          teens: 0.03,
          sixties: 0.02,
          eighties: 0.06,
        },
        gender: { female: 0.22, '': 0.78 },
      },
      users: 574,
      duration: 369742003,
      buckets: {
        dev: 8976,
        invalidated: 4762,
        other: 2676,
        reported: 404,
        test: 9024,
        train: 19764,
        validated: 58338,
      },
      reportedSentences: 402,
      size: 2883565367,
      checksum:
        'cd6379c89fa7d57e0cd254a91d1a95fbd36117e774a1f33bace8e1dbaf7e0b1d',
      avgDurationSecs: 5.626,
      validDurationSecs: 328180.765,
      totalHrs: 102.7,
      validHrs: 91.16,
    },
    et: {
      clips: 14809,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.15,
          thirties: 0.07,
          twenties: 0.71,
          fourties: 0.07,
          fifties: 0.01,
          seventies: 0,
          teens: 0,
        },
        gender: { '': 0.48, female: 0.52 },
      },
      users: 543,
      duration: 98800166,
      buckets: {
        dev: 2507,
        invalidated: 3575,
        other: 532,
        reported: 107,
        test: 2509,
        train: 2965,
        validated: 10702,
      },
      reportedSentences: 108,
      size: 767173893,
      checksum:
        '98ade2d2c5298f503aa45c3c880ad79a2b39f2f9577bda5da2dae008116aeb00',
      avgDurationSecs: 6.672,
      validDurationSecs: 71399.782,
      totalHrs: 27.44,
      validHrs: 19.83,
    },
    fa: {
      clips: 285645,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.21,
          twenties: 0.33,
          thirties: 0.39,
          fifties: 0.02,
          fourties: 0.03,
          teens: 0.01,
          sixties: 0,
        },
        gender: { '': 0.7, female: 0.3 },
      },
      users: 3654,
      duration: 1154779892,
      buckets: {
        dev: 5161,
        invalidated: 11806,
        other: 20469,
        reported: 1522,
        test: 5161,
        train: 7485,
        validated: 253592,
      },
      reportedSentences: 1516,
      size: 8884631439,
      checksum:
        '58c4fc315923e0524d4c7710d7a9be4285377f4dbecf1d0caa8e9e95d13db32e',
      avgDurationSecs: 4.043,
      validDurationSecs: 1025198.909,
      totalHrs: 320.77,
      validHrs: 284.77,
    },
    eu: {
      clips: 91966,
      splits: {
        accent: {
          mendebalekoa: 0.31,
          erdialdekoa_nafarra: 0.3,
          '': 0.39,
          nafarlapurtarra_zuberoatarra: 0.01,
        },
        age: {
          fourties: 0.14,
          thirties: 0.08,
          fifties: 0.14,
          twenties: 0.38,
          '': 0.22,
          teens: 0.03,
          sixties: 0.01,
          seventies: 0,
        },
        gender: { '': 0.78, female: 0.22 },
      },
      users: 1028,
      duration: 472668999,
      buckets: {
        dev: 5195,
        invalidated: 5527,
        other: 23026,
        reported: 38,
        test: 5195,
        train: 7555,
        validated: 63413,
      },
      reportedSentences: 39,
      size: 3664570179,
      checksum:
        'e5e7edc46a3f9b2567ac257f8f9fd41f35a371bad5862b94e5ca5dc4c6bd2a24',
      avgDurationSecs: 5.14,
      validDurationSecs: 325917.831,
      totalHrs: 131.29,
      validHrs: 90.53,
    },
    es: {
      clips: 421193,
      splits: {
        accent: {
          nortepeninsular: 0.1,
          '': 0.5,
          rioplatense: 0.04,
          centrosurpeninsular: 0.03,
          americacentral: 0.02,
          surpeninsular: 0.15,
          canario: 0,
          andino: 0.05,
          mexicano: 0.06,
          chileno: 0.02,
          caribe: 0.03,
          filipinas: 0,
        },
        age: {
          thirties: 0.1,
          '': 0.32,
          fifties: 0.09,
          twenties: 0.26,
          teens: 0.03,
          fourties: 0.06,
          sixties: 0.14,
          eighties: 0,
          seventies: 0,
          nineties: 0,
        },
        gender: { '': 0.84, female: 0.16 },
      },
      users: 19480,
      duration: 2082967866,
      buckets: {
        dev: 15077,
        invalidated: 40487,
        other: 146299,
        reported: 1109,
        test: 15077,
        train: 160481,
        validated: 234959,
      },
      reportedSentences: 1098,
      size: 16188722605,
      checksum:
        '0ac532f1b042201d02160e63f35670f91b11589034ad0b79632e3c90fd05d675',
      avgDurationSecs: 4.945,
      validDurationSecs: 1161966.241,
      totalHrs: 578.6,
      validHrs: 322.76,
    },
    'zh-CN': {
      clips: 50658,
      splits: {
        accent: {
          '110000': 0.04,
          '120000': 0,
          '130000': 0.02,
          '140000': 0.01,
          '150000': 0,
          '210000': 0.01,
          '220000': 0,
          '230000': 0.04,
          '310000': 0.05,
          '320000': 0.05,
          '330000': 0.03,
          '340000': 0.02,
          '350000': 0.01,
          '360000': 0.02,
          '370000': 0.03,
          '410000': 0.04,
          '420000': 0.02,
          '430000': 0.01,
          '440000': 0.03,
          '450000': 0.02,
          '460000': 0.01,
          '500000': 0.02,
          '510000': 0.03,
          '520000': 0,
          '530000': 0,
          '610000': 0.01,
          '620000': 0,
          '640000': 0,
          '650000': 0,
          '710000': 0,
          '810000': 0,
          '': 0.45,
        },
        age: {
          '': 0.38,
          teens: 0.08,
          twenties: 0.38,
          thirties: 0.13,
          fourties: 0.03,
          nineties: 0,
          fifties: 0,
          sixties: 0,
        },
        gender: { '': 0.68, female: 0.32 },
      },
      users: 3501,
      duration: 281080968,
      buckets: {
        dev: 8736,
        invalidated: 5357,
        other: 8829,
        reported: 279,
        test: 8772,
        train: 18605,
        validated: 36472,
      },
      reportedSentences: 279,
      size: 2184559529,
      checksum:
        'b44c46fffadb14a3effb60362295533e1484c1dcb76cbdb0ef04a06c87316667',
      avgDurationSecs: 5.549,
      validDurationSecs: 202368.531,
      totalHrs: 78.07,
      validHrs: 56.21,
    },
    mn: {
      clips: 11426,
      splits: {
        accent: { '': 1 },
        age: {
          thirties: 0.23,
          '': 0.25,
          twenties: 0.42,
          fourties: 0.01,
          teens: 0.02,
          nineties: 0.06,
        },
        gender: { '': 0.79, female: 0.21 },
      },
      users: 376,
      duration: 62534240,
      buckets: {
        dev: 1863,
        invalidated: 693,
        other: 3192,
        reported: 2,
        test: 1866,
        train: 2163,
        validated: 7541,
      },
      reportedSentences: 3,
      size: 486368725,
      checksum:
        '34efd592ff52d3b1d17add62f6af785b69fa945769ea539c275b33e6e0438dee',
      avgDurationSecs: 5.473,
      validDurationSecs: 41271.723,
      totalHrs: 17.37,
      validHrs: 11.46,
    },
    sah: {
      clips: 3947,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.36,
          twenties: 0.02,
          fourties: 0.07,
          thirties: 0.44,
          teens: 0.11,
          fifties: 0,
        },
        gender: { '': 0.88, female: 0.12 },
      },
      users: 42,
      duration: 23639016,
      buckets: {
        dev: 581,
        invalidated: 73,
        other: 1073,
        test: 776,
        train: 1442,
        validated: 2801,
      },
      size: 181255394,
      checksum:
        '45b40288f769f758c6e66c6d663fd934fd06a3064ea2db6e173907946e2ae756',
      avgDurationSecs: 5.989,
      validDurationSecs: 16775.496,
      totalHrs: 6.56,
      validHrs: 4.65,
    },
    dv: {
      clips: 12706,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.11,
          twenties: 0.19,
          thirties: 0.62,
          fourties: 0.05,
          teens: 0.02,
        },
        gender: { '': 0.97, female: 0.03 },
      },
      users: 167,
      duration: 70042959,
      buckets: {
        dev: 2072,
        invalidated: 780,
        other: 116,
        reported: 2,
        test: 2192,
        train: 2693,
        validated: 11810,
      },
      reportedSentences: 3,
      size: 540487184,
      checksum:
        '1196b9fa4976531e4f5b1828065278865f72be719cef7ebe2681e90a431a0c47',
      avgDurationSecs: 5.513,
      validDurationSecs: 65103.679,
      totalHrs: 19.45,
      validHrs: 18.08,
    },
    rw: {
      clips: 1062642,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.02,
          twenties: 0.63,
          thirties: 0.11,
          teens: 0.24,
          fourties: 0,
        },
        gender: { '': 0.61, female: 0.39 },
      },
      users: 411,
      duration: 5436192485,
      buckets: {
        dev: 15007,
        invalidated: 206525,
        other: 27057,
        reported: 618,
        test: 15733,
        train: 511386,
        validated: 829060,
      },
      reportedSentences: 619,
      size: 42544959200,
      checksum:
        '8270c36a7f1e0f937567628b259088f19a3e6156e609da775b6af73fb9c770d1',
      avgDurationSecs: 5.116,
      validDurationSecs: 4241249.397,
      totalHrs: 1510.05,
      validHrs: 1178.12,
    },
    'sv-SE': {
      clips: 16032,
      splits: {
        accent: { '': 1 },
        age: {
          thirties: 0.27,
          '': 0.16,
          teens: 0.07,
          fifties: 0.09,
          twenties: 0.2,
          fourties: 0.22,
          sixties: 0,
        },
        gender: { '': 0.84, female: 0.16 },
      },
      users: 222,
      duration: 55467663,
      buckets: {
        dev: 1971,
        invalidated: 469,
        other: 3082,
        reported: 151,
        test: 1970,
        train: 2374,
        validated: 12506,
      },
      reportedSentences: 152,
      size: 421428100,
      checksum:
        '2998270c70ea2c2201a5f87ce4e467529b99408befdf6c39f25f4d93388ff185',
      avgDurationSecs: 3.46,
      validDurationSecs: 43268.375,
      totalHrs: 15.4,
      validHrs: 12.01,
    },
    ru: {
      clips: 87534,
      splits: {
        accent: { '': 1 },
        age: {
          twenties: 0.46,
          teens: 0.08,
          '': 0.11,
          fourties: 0.16,
          thirties: 0.13,
          fifties: 0.05,
          sixties: 0,
          seventies: 0,
        },
        gender: { female: 0.35, '': 0.65 },
      },
      users: 1410,
      duration: 471270204,
      buckets: {
        dev: 7958,
        invalidated: 3091,
        other: 10098,
        reported: 142,
        test: 8007,
        train: 15486,
        validated: 74370,
      },
      reportedSentences: 136,
      size: 3655671426,
      checksum:
        'a324e505042f52336d64031a1ffef1b13199210e688b482dfa82871f05cc1511',
      avgDurationSecs: 5.384,
      validDurationSecs: 400397.161,
      totalHrs: 130.9,
      validHrs: 111.22,
    },
    id: {
      clips: 15914,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.09,
          twenties: 0.42,
          thirties: 0.08,
          teens: 0.36,
          fifties: 0.01,
          fourties: 0.04,
        },
        gender: { '': 0.66, female: 0.34 },
      },
      users: 219,
      duration: 61607784,
      buckets: {
        dev: 1801,
        invalidated: 528,
        other: 6862,
        reported: 200,
        test: 1818,
        train: 2117,
        validated: 8558,
      },
      reportedSentences: 201,
      size: 475917179,
      checksum:
        '17d8501b57be65d3a339ee210951c10090321806b54eaa5fba45842a72fe1857',
      avgDurationSecs: 3.871,
      validDurationSecs: 33130.54,
      totalHrs: 17.11,
      validHrs: 9.2,
    },
    ar: {
      clips: 56153,
      splits: {
        accent: { '': 1 },
        age: {
          thirties: 0.14,
          '': 0.42,
          twenties: 0.37,
          fourties: 0.01,
          teens: 0.06,
          fifties: 0,
        },
        gender: { '': 0.73, female: 0.27 },
      },
      users: 659,
      duration: 227977686,
      buckets: {
        dev: 7567,
        invalidated: 5437,
        other: 22528,
        reported: 825,
        test: 7626,
        train: 14173,
        validated: 39953,
      },
      reportedSentences: 457,
      size: 1756262997,
      checksum:
        '1a40f4948d0ea92b5ca928877156911bc40c1d5f027fa3abe164c1b6262ae5cf',
      avgDurationSecs: 4.06,
      validDurationSecs: 162206.694,
      totalHrs: 63.32,
      validHrs: 45.05,
    },
    ta: {
      clips: 20674,
      splits: {
        accent: { '': 1 },
        age: {
          twenties: 0.45,
          thirties: 0.41,
          '': 0.1,
          fourties: 0.01,
          seventies: 0,
          fifties: 0.01,
          teens: 0.01,
          sixties: 0,
        },
        gender: { female: 0.36, '': 0.64 },
      },
      users: 266,
      duration: 88153080,
      buckets: {
        dev: 1747,
        invalidated: 723,
        other: 6839,
        reported: 257,
        test: 1780,
        train: 2065,
        validated: 13112,
      },
      reportedSentences: 257,
      size: 679765629,
      checksum:
        '29b09471ce309432eb6717b30c8a1521a2b7323c9ccb9967b5b414afe959d470',
      avgDurationSecs: 4.264,
      validDurationSecs: 55909.025,
      totalHrs: 24.48,
      validHrs: 15.53,
    },
    ia: {
      clips: 7260,
      splits: {
        accent: { '': 1 },
        age: {
          seventies: 0.24,
          fourties: 0.58,
          '': 0.06,
          twenties: 0.07,
          thirties: 0.03,
          teens: 0,
          fifties: 0.01,
          sixties: 0,
        },
        gender: { '': 0.93, female: 0.07 },
      },
      users: 35,
      duration: 29092476,
      buckets: {
        dev: 1590,
        invalidated: 207,
        other: 1184,
        reported: 240,
        test: 886,
        train: 3397,
        validated: 5874,
      },
      reportedSentences: 236,
      size: 226498881,
      checksum:
        '5a68c83b7ecbc7470bdcaa79ea38b10e786248eabad06ba668e75040741d7353',
      avgDurationSecs: 4.007,
      validDurationSecs: 23538.458,
      totalHrs: 8.08,
      validHrs: 6.53,
    },
    pt: {
      clips: 51679,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.14,
          twenties: 0.35,
          teens: 0.03,
          thirties: 0.32,
          fourties: 0.13,
          sixties: 0,
          fifties: 0.04,
          seventies: 0,
        },
        gender: { '': 0.64, female: 0.36 },
      },
      users: 1117,
      duration: 226859263,
      buckets: {
        dev: 4639,
        invalidated: 1799,
        other: 7929,
        reported: 1698,
        test: 4642,
        train: 6446,
        validated: 41986,
      },
      reportedSentences: 1694,
      size: 1704250497,
      checksum:
        'bec46b0f07821023e7a9564c072ba0fd5609e0ba710c8036059d8900caa0c0da',
      avgDurationSecs: 4.39,
      validDurationSecs: 184309.159,
      totalHrs: 63.01,
      validHrs: 51.19,
    },
    lv: {
      clips: 8147,
      splits: {
        accent: { '': 1 },
        age: {
          thirties: 0.48,
          fourties: 0.03,
          '': 0.16,
          twenties: 0.29,
          teens: 0.03,
          fifties: 0,
        },
        gender: { '': 0.7, female: 0.3 },
      },
      users: 99,
      duration: 27668465,
      buckets: {
        dev: 1656,
        invalidated: 175,
        other: 1296,
        reported: 20,
        test: 1910,
        train: 3102,
        validated: 6676,
      },
      reportedSentences: 21,
      size: 208314325,
      checksum:
        'b8654831ce5814ba179630bdfc2f8d51718462909c3ad2cf3c252d094fa909da',
      avgDurationSecs: 3.396,
      validDurationSecs: 22672.723,
      totalHrs: 7.68,
      validHrs: 6.29,
    },
    ja: {
      clips: 4452,
      splits: {
        accent: { '': 1 },
        age: {
          twenties: 0.51,
          '': 0.3,
          teens: 0.04,
          fifties: 0.02,
          thirties: 0.06,
          fourties: 0.06,
          sixties: 0,
        },
        gender: { female: 0.4, '': 0.6 },
      },
      users: 232,
      duration: 19685913,
      buckets: {
        dev: 588,
        invalidated: 507,
        other: 883,
        reported: 43,
        test: 630,
        train: 724,
        validated: 3071,
      },
      reportedSentences: 44,
      size: 152880299,
      checksum:
        'e2a4c64e6d9fb77289c4a25d40aff206b6659f8107a25ee66e33d48c680e31d4',
      avgDurationSecs: 4.422,
      validDurationSecs: 13579.389,
      totalHrs: 5.46,
      validHrs: 3.77,
    },
    vot: {
      clips: 420,
      splits: {
        accent: { '': 1 },
        age: { '': 0.26, twenties: 0.74 },
        gender: { '': 0.26, female: 0.74 },
      },
      users: 3,
      duration: 1008696,
      buckets: {
        dev: 0,
        invalidated: 60,
        other: 352,
        test: 0,
        train: 8,
        validated: 8,
      },
      size: 7791984,
      checksum:
        'f11fc1ddcc9461de832cb304118993ea5782e0136782aaf99406f6f9f1a51e99',
      avgDurationSecs: 2.402,
      validDurationSecs: 19.213,
      totalHrs: 0.28,
      validHrs: 0,
    },
    ab: {
      clips: 791,
      splits: {
        accent: { '': 1 },
        age: { seventies: 0.63, thirties: 0.29, '': 0.08, teens: 0.01 },
        gender: { '': 1 },
      },
      users: 14,
      duration: 5216688,
      buckets: {
        dev: 0,
        invalidated: 8,
        other: 752,
        reported: 42,
        test: 9,
        train: 22,
        validated: 31,
      },
      reportedSentences: 43,
      size: 41038479,
      checksum:
        'ad0c6d987ba72d7c47f709b95f5b1fccc8300b378fd05df49c95819a01dc2793',
      avgDurationSecs: 6.595,
      validDurationSecs: 204.447,
      totalHrs: 1.44,
      validHrs: 0.05,
    },
    'zh-HK': {
      clips: 83664,
      splits: {
        accent: { '': 1 },
        age: {
          fourties: 0.15,
          thirties: 0.11,
          '': 0.4,
          teens: 0.02,
          fifties: 0.03,
          seventies: 0,
          sixties: 0,
          twenties: 0.29,
        },
        gender: { '': 0.84, female: 0.16 },
      },
      users: 2536,
      duration: 361968900,
      buckets: {
        dev: 5172,
        invalidated: 3201,
        other: 37498,
        reported: 571,
        test: 5173,
        train: 7514,
        validated: 42965,
      },
      reportedSentences: 560,
      size: 2774151973,
      checksum:
        'f9dfbd13c1721753580f02535859e849d0cbf8d4ca974df34126fb2ad53d4f81',
      avgDurationSecs: 4.326,
      validDurationSecs: 185886.329,
      totalHrs: 100.54,
      validHrs: 51.63,
    },
    'rm-sursilv': {
      clips: 6524,
      splits: {
        accent: { '': 1 },
        age: {
          thirties: 0.02,
          twenties: 0.11,
          '': 0.64,
          teens: 0.06,
          fourties: 0.17,
        },
        gender: { '': 0.93, female: 0.07 },
      },
      users: 78,
      duration: 35885741,
      buckets: {
        dev: 1208,
        invalidated: 664,
        other: 2038,
        reported: 9,
        test: 1222,
        train: 1392,
        validated: 3822,
      },
      reportedSentences: 10,
      size: 275949719,
      checksum:
        '4ed2cb43b36cfd2ab917fd9285b76ba3d01ef78872ef51e714fcff2bb4cb0e49',
      avgDurationSecs: 5.501,
      validDurationSecs: 21023.192,
      totalHrs: 9.96,
      validHrs: 5.83,
    },
    hsb: {
      clips: 1656,
      splits: {
        accent: { '': 1 },
        age: {
          fourties: 0.55,
          '': 0.17,
          thirties: 0.11,
          sixties: 0,
          seventies: 0.03,
          twenties: 0.11,
          fifties: 0.03,
        },
        gender: { '': 0.89, female: 0.11 },
      },
      users: 19,
      duration: 10103328,
      buckets: {
        dev: 172,
        invalidated: 227,
        other: 62,
        reported: 56,
        test: 387,
        train: 808,
        validated: 1367,
      },
      reportedSentences: 57,
      size: 79362015,
      checksum:
        '6096fa84693d8e1abeed2d07271451f59db5e41caf22c53be5824546c60ca523',
      avgDurationSecs: 6.101,
      validDurationSecs: 8340.126,
      totalHrs: 2.8,
      validHrs: 2.31,
    },
    ro: {
      clips: 8469,
      splits: {
        accent: { '': 1 },
        age: {
          thirties: 0.51,
          teens: 0.05,
          '': 0.07,
          fourties: 0.08,
          sixties: 0.01,
          twenties: 0.27,
          fifties: 0,
        },
        gender: { '': 0.91, female: 0.09 },
      },
      users: 130,
      duration: 34024999,
      buckets: {
        dev: 849,
        invalidated: 493,
        other: 1946,
        reported: 59,
        test: 1783,
        train: 3394,
        validated: 6030,
      },
      reportedSentences: 60,
      size: 261978819,
      checksum:
        '3cbad11a867402d4ece946c3b34e3f1c804f503947da74847b6757e0cc410c15',
      avgDurationSecs: 4.018,
      validDurationSecs: 24226.089,
      totalHrs: 9.45,
      validHrs: 6.72,
    },
    'fy-NL': {
      clips: 33075,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.56,
          fifties: 0.1,
          thirties: 0.06,
          twenties: 0.03,
          fourties: 0.11,
          sixties: 0.15,
          seventies: 0,
        },
        gender: { '': 0.99, female: 0.01 },
      },
      users: 466,
      duration: 168875163,
      buckets: {
        dev: 2791,
        invalidated: 1032,
        other: 21590,
        reported: 281,
        test: 3017,
        train: 3927,
        validated: 10473,
      },
      reportedSentences: 281,
      size: 1237741428,
      checksum:
        '34387d3293893cde2603199a7f5beaac939104d617a89cb3dcb99906f306632c',
      avgDurationSecs: 5.106,
      validDurationSecs: 53473.306,
      totalHrs: 46.9,
      validHrs: 14.85,
    },
    cs: {
      clips: 38566,
      splits: {
        accent: { '': 1 },
        age: {
          fourties: 0.26,
          '': 0.3,
          thirties: 0.14,
          teens: 0.01,
          twenties: 0.27,
          fifties: 0.02,
          sixties: 0.01,
        },
        gender: { '': 0.74, female: 0.26 },
      },
      users: 353,
      duration: 164221815,
      buckets: {
        dev: 4095,
        invalidated: 729,
        other: 7026,
        reported: 585,
        test: 4117,
        train: 5725,
        validated: 30836,
      },
      reportedSentences: 582,
      size: 1271911386,
      checksum:
        '26fedfa2d6bab4b39104a6865e9dd47c181ad4f4b2027f1274d00de4896c7b8a',
      avgDurationSecs: 4.258,
      validDurationSecs: 131305.914,
      totalHrs: 45.61,
      validHrs: 36.47,
    },
    el: {
      clips: 11840,
      splits: {
        accent: { '': 1 },
        age: {
          thirties: 0.64,
          fourties: 0.19,
          '': 0.14,
          twenties: 0.02,
          fifties: 0.01,
          teens: 0,
        },
        gender: { '': 0.97, female: 0.03 },
      },
      users: 118,
      duration: 49105618,
      buckets: {
        dev: 1392,
        invalidated: 274,
        other: 5557,
        reported: 18,
        test: 1563,
        train: 2273,
        validated: 6009,
      },
      reportedSentences: 19,
      size: 381566104,
      checksum:
        '1e273d7b34be5c4d7e210dcb78d99172921725ee2e37072ec976acb4fa29d991',
      avgDurationSecs: 4.147,
      validDurationSecs: 24921.931,
      totalHrs: 13.64,
      validHrs: 6.92,
    },
    'rm-vallader': {
      clips: 2417,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.35,
          fourties: 0.44,
          twenties: 0.15,
          thirties: 0.06,
          fifties: 0,
        },
        gender: { '': 0.87, female: 0.13 },
      },
      users: 39,
      duration: 13869698,
      buckets: {
        dev: 357,
        invalidated: 382,
        other: 719,
        reported: 21,
        test: 378,
        train: 574,
        validated: 1316,
      },
      reportedSentences: 20,
      size: 108113716,
      checksum:
        '9a85ea50ac56aac6ba3a37bde29f71a8ef033a84113359195b31241870f25ecb',
      avgDurationSecs: 5.738,
      validDurationSecs: 7551.726,
      totalHrs: 3.85,
      validHrs: 2.09,
    },
    pl: {
      clips: 108240,
      splits: {
        accent: { '': 1 },
        age: {
          twenties: 0.27,
          '': 0.24,
          teens: 0.02,
          thirties: 0.35,
          fourties: 0.12,
          fifties: 0,
          nineties: 0.01,
          sixties: 0,
        },
        gender: { female: 0.19, '': 0.81 },
      },
      users: 2647,
      duration: 466550750,
      buckets: {
        dev: 5153,
        invalidated: 4623,
        other: 12706,
        reported: 431,
        test: 5153,
        train: 7468,
        validated: 90911,
      },
      reportedSentences: 431,
      size: 3537006506,
      checksum:
        'c2f85c8e844e35f46b05d6c1a6db25b08abd658fa4662dacdf66e630f3c81123',
      avgDurationSecs: 4.31,
      validDurationSecs: 391856.94,
      totalHrs: 129.59,
      validHrs: 108.84,
    },
    as: {
      clips: 535,
      splits: {
        accent: { '': 1 },
        age: { twenties: 0.29, '': 0.62, thirties: 0.08 },
        gender: { female: 0.29, '': 0.71 },
      },
      users: 17,
      duration: 2835893,
      buckets: {
        dev: 124,
        invalidated: 26,
        other: 5,
        reported: 2,
        test: 110,
        train: 270,
        validated: 504,
      },
      reportedSentences: 3,
      size: 22225821,
      checksum:
        '9852899831cabe53b45549da8a09de1649505ce852f3cea2fa804baa8b554157',
      avgDurationSecs: 5.301,
      validDurationSecs: 2671.57,
      totalHrs: 0.78,
      validHrs: 0.74,
    },
    uk: {
      clips: 31753,
      splits: {
        accent: { '': 1 },
        age: {
          twenties: 0.27,
          teens: 0.07,
          '': 0.19,
          fourties: 0.22,
          thirties: 0.24,
          fifties: 0.01,
          sixties: 0,
        },
        gender: { female: 0.16, '': 0.84 },
      },
      users: 459,
      duration: 158034012,
      buckets: {
        dev: 3239,
        invalidated: 1259,
        other: 8175,
        reported: 104,
        test: 3234,
        train: 4032,
        validated: 22319,
      },
      reportedSentences: 95,
      size: 1218554958,
      checksum:
        '4978874e7dcaba60f32e3698785acac3c8f9ee127ec681b44584dae549b736c8',
      avgDurationSecs: 4.977,
      validDurationSecs: 111081.193,
      totalHrs: 43.89,
      validHrs: 30.85,
    },
    mt: {
      clips: 11775,
      splits: {
        accent: { '': 1 },
        age: {
          twenties: 0.17,
          '': 0.25,
          fourties: 0.18,
          thirties: 0.08,
          teens: 0.03,
          fifties: 0.28,
          sixties: 0.01,
        },
        gender: { female: 0.12, '': 0.88 },
      },
      users: 171,
      duration: 55832112,
      buckets: {
        dev: 1492,
        invalidated: 321,
        other: 5633,
        reported: 4,
        test: 1661,
        train: 2015,
        validated: 5821,
      },
      reportedSentences: 5,
      size: 425114531,
      checksum:
        '814ebac24593526a378c5b4c14163418306d84ae3a82e88302592704c373628c',
      avgDurationSecs: 4.742,
      validDurationSecs: 27600.741,
      totalHrs: 15.5,
      validHrs: 7.66,
    },
    ka: {
      clips: 2458,
      splits: {
        accent: { '': 1 },
        age: { twenties: 0.59, thirties: 0.1, '': 0.26, fourties: 0.05 },
        gender: { female: 0.3, '': 0.7 },
      },
      users: 44,
      duration: 14005512,
      buckets: {
        dev: 454,
        invalidated: 138,
        other: 75,
        reported: 2,
        test: 711,
        train: 1058,
        validated: 2245,
      },
      reportedSentences: 3,
      size: 104279708,
      checksum:
        '099bb42f00b819df3ecf743afc7a2f98d38abe27f7f18ac1164f63d4e0cf52bd',
      avgDurationSecs: 5.698,
      validDurationSecs: 12791.853,
      totalHrs: 3.89,
      validHrs: 3.55,
    },
    'pa-IN': {
      clips: 1825,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.13,
          fourties: 0.06,
          fifties: 0.01,
          thirties: 0.56,
          twenties: 0.23,
        },
        gender: { '': 0.77, female: 0.23 },
      },
      users: 26,
      duration: 8904554,
      buckets: {
        dev: 124,
        invalidated: 202,
        other: 1175,
        reported: 232,
        test: 136,
        train: 188,
        validated: 448,
      },
      reportedSentences: 231,
      size: 69751993,
      checksum:
        '19511cd19e2dcaffedd5be65ac28a41c91dce4dcc8e1b21efb22858236b7bae0',
      avgDurationSecs: 4.879,
      validDurationSecs: 2185.885,
      totalHrs: 2.47,
      validHrs: 0.6,
    },
    or: {
      clips: 4979,
      splits: {
        accent: { '': 1 },
        age: {
          twenties: 0.13,
          '': 0.03,
          thirties: 0.83,
          fourties: 0,
          teens: 0,
        },
        gender: { female: 0.13, '': 0.87 },
      },
      users: 34,
      duration: 25499688,
      buckets: {
        dev: 0,
        invalidated: 98,
        other: 4060,
        reported: 4,
        test: 129,
        train: 691,
        validated: 821,
      },
      reportedSentences: 5,
      size: 199087467,
      checksum:
        '9ca9f69fb8425932084ba603c06b887ca259eed89103e2c835ff3fecce75ef04',
      avgDurationSecs: 5.121,
      validDurationSecs: 4204.709,
      totalHrs: 7.08,
      validHrs: 1.16,
    },
    vi: {
      clips: 1567,
      splits: {
        accent: { '': 1 },
        age: {
          thirties: 0.05,
          twenties: 0.23,
          '': 0.15,
          teens: 0.02,
          seventies: 0.01,
          fourties: 0,
          sixties: 0.53,
        },
        gender: { '': 0.79, female: 0.21 },
      },
      users: 62,
      duration: 6755804,
      buckets: {
        dev: 181,
        invalidated: 96,
        other: 853,
        reported: 7,
        test: 201,
        train: 236,
        validated: 618,
      },
      reportedSentences: 8,
      size: 51929202,
      checksum:
        '0e5ac63f3130b5fd00af0ae18f836abd57d462bfe2d00ce844755474ad395565',
      avgDurationSecs: 4.311,
      validDurationSecs: 2664.382,
      totalHrs: 1.87,
      validHrs: 0.74,
    },
    hu: {
      clips: 6911,
      splits: {
        accent: { '': 1 },
        age: {
          teens: 0,
          '': 0.05,
          thirties: 0.14,
          twenties: 0.75,
          fifties: 0.06,
        },
        gender: { '': 0.31, female: 0.69 },
      },
      users: 46,
      duration: 31171321,
      buckets: {
        dev: 1912,
        invalidated: 193,
        other: 115,
        reported: 31,
        test: 2050,
        train: 2623,
        validated: 6613,
      },
      reportedSentences: 32,
      size: 242765616,
      checksum:
        '10a6c3833a98586792dd6cb1eea5da03c4b5a4c569f9b81d318576430ca83dc5',
      avgDurationSecs: 4.51,
      validDurationSecs: 29827.224,
      totalHrs: 8.65,
      validHrs: 8.28,
    },
    fi: {
      clips: 1513,
      splits: {
        accent: { '': 1 },
        age: {
          thirties: 0.4,
          '': 0.23,
          twenties: 0.14,
          fourties: 0.17,
          teens: 0.04,
          fifties: 0.02,
        },
        gender: { '': 0.86, female: 0.14 },
      },
      users: 27,
      duration: 6905415,
      buckets: {
        dev: 406,
        invalidated: 59,
        other: 198,
        reported: 3,
        test: 408,
        train: 440,
        validated: 1256,
      },
      reportedSentences: 3,
      size: 49881693,
      checksum:
        'e746afdbe5e92874190719f4ea53ccd0d9eb0073681b4797e08d62954fcf3027',
      avgDurationSecs: 4.564,
      validDurationSecs: 5732.453,
      totalHrs: 1.91,
      validHrs: 1.59,
    },
    th: {
      clips: 10166,
      splits: {
        accent: { '': 1 },
        age: {
          twenties: 0.3,
          '': 0.17,
          thirties: 0.18,
          fourties: 0.3,
          teens: 0.05,
          fifties: 0.01,
        },
        gender: { female: 0.21, '': 0.79 },
      },
      users: 182,
      duration: 44472936,
      buckets: {
        dev: 1951,
        invalidated: 492,
        other: 2734,
        reported: 274,
        test: 2159,
        train: 2829,
        validated: 6940,
      },
      reportedSentences: 275,
      size: 341305351,
      checksum:
        '7577e16ded6e579e905932f114fba8dfa8ae6e7f1aef465b495c36912d7f6047',
      avgDurationSecs: 4.375,
      validDurationSecs: 30360.238,
      totalHrs: 12.35,
      validHrs: 8.43,
    },
    lg: {
      clips: 5615,
      splits: {
        accent: { '': 1 },
        age: { '': 0.06, thirties: 0.63, twenties: 0.31, fourties: 0 },
        gender: { '': 0.89, female: 0.11 },
      },
      users: 77,
      duration: 29566467,
      buckets: {
        dev: 355,
        invalidated: 363,
        other: 3044,
        reported: 1,
        test: 699,
        train: 1154,
        validated: 2213,
      },
      reportedSentences: 2,
      size: 208200104,
      checksum:
        '831f6c489904e2e6d985bf30dbe81b65f324a59dbbfeed654963f48e98449a1a',
      avgDurationSecs: 5.266,
      validDurationSecs: 11652.821,
      totalHrs: 8.21,
      validHrs: 3.23,
    },
    lt: {
      clips: 3370,
      splits: {
        accent: { '': 1 },
        age: {
          twenties: 0.48,
          '': 0.12,
          thirties: 0.35,
          fifties: 0.04,
          sixties: 0.02,
        },
        gender: { female: 0.47, '': 0.53 },
      },
      users: 30,
      duration: 17557532,
      buckets: {
        dev: 326,
        invalidated: 147,
        other: 1473,
        reported: 41,
        test: 502,
        train: 924,
        validated: 1755,
      },
      reportedSentences: 42,
      size: 135303346,
      checksum:
        '9a437a259b5de086a062e891f3445368a7db79eff5ec184d84f7d79ef38f95a7',
      avgDurationSecs: 5.21,
      validDurationSecs: 9143.463,
      totalHrs: 4.87,
      validHrs: 2.53,
    },
    hi: {
      clips: 618,
      splits: {
        accent: { '': 1 },
        age: {
          twenties: 0.63,
          fourties: 0.05,
          '': 0.27,
          thirties: 0.04,
          teens: 0.01,
        },
        gender: { female: 0.63, '': 0.37 },
      },
      users: 31,
      duration: 2899824,
      buckets: {
        dev: 139,
        invalidated: 63,
        other: 130,
        reported: 4,
        test: 129,
        train: 157,
        validated: 425,
      },
      reportedSentences: 5,
      size: 21424043,
      checksum:
        '91b14aaa3e5fe004c23dad7221d962a3a8b42cfa599af575a1df2d91228a740d',
      avgDurationSecs: 4.692,
      validDurationSecs: 1994.216,
      totalHrs: 0.8,
      validHrs: 0.55,
    },
  },
  totalDuration: 33340350739,
  totalValidDurationSecs: 26378513,
  totalHrs: 9261,
  totalValidHrs: 7327,
  totalClips: 7023545,
};
