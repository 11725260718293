exports.stats = {
  bundleURL: 'cv-corpus-7.0-singleword/cv-corpus-7.0-singleword.tar.gz',
  multilingual: false,
  locales: {
    es: {
      clips: 70038,
      splits: {
        accent: {
          surpeninsular: 0.01,
          '': 0.8,
          americacentral: 0.02,
          centrosurpeninsular: 0.02,
          mexicano: 0.05,
          caribe: 0.02,
          nortepeninsular: 0.02,
          andino: 0.04,
          rioplatense: 0.02,
          chileno: 0.01,
          canario: 0,
          filipinas: 0
        },
        age: {
          sixties: 0.01,
          '': 0.74,
          thirties: 0.05,
          fifties: 0.02,
          fourties: 0.04,
          twenties: 0.11,
          teens: 0.04,
          seventies: 0,
          nineties: 0,
          eighties: 0
        },
        gender: { male: 0.17, '': 0.74, female: 0.09, other: 0 }
      },
      users: 9052,
      buckets: {
        dev: 4,
        invalidated: 6542,
        other: 40514,
        test: 4,
        train: 6,
        validated: 22982
      },
      duration: 202516646,
      avgDurationSecs: 2.892,
      validDurationSecs: 66453.033,
      totalHrs: 56.25,
      validHrs: 18.45
    },
    pl: {
      clips: 1182,
      splits: {
        accent: { '': 1 },
        age: {
          twenties: 0.25,
          thirties: 0.17,
          fourties: 0.02,
          teens: 0.08,
          '': 0.47,
          sixties: 0.02
        },
        gender: { female: 0.13, male: 0.4, '': 0.47, other: 0.01 }
      },
      users: 112,
      buckets: {
        dev: 4,
        invalidated: 37,
        other: 3,
        test: 4,
        train: 6,
        validated: 1142
      },
      duration: 2713140,
      avgDurationSecs: 2.295,
      validDurationSecs: 2621.325,
      totalHrs: 0.75,
      validHrs: 0.72
    },
    ca: {
      clips: 4180,
      splits: {
        accent: {
          valencian: 0.08,
          '': 0.51,
          central: 0.35,
          northern: 0.01,
          northwestern: 0.03,
          balearic: 0.02
        },
        age: {
          fifties: 0.08,
          '': 0.52,
          sixties: 0.04,
          thirties: 0.12,
          fourties: 0.1,
          twenties: 0.1,
          teens: 0.04,
          seventies: 0.01
        },
        gender: { male: 0.35, '': 0.53, female: 0.12, other: 0 }
      },
      users: 387,
      buckets: {
        dev: 4,
        invalidated: 217,
        other: 297,
        test: 4,
        train: 6,
        validated: 3666
      },
      duration: 10707094,
      avgDurationSecs: 2.562,
      validDurationSecs: 9390.48,
      totalHrs: 2.97,
      validHrs: 2.6
    },
    tr: {
      clips: 537,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.5,
          fourties: 0.12,
          twenties: 0.26,
          teens: 0.03,
          thirties: 0.1
        },
        gender: { '': 0.5, male: 0.45, female: 0.05 }
      },
      users: 60,
      buckets: {
        dev: 4,
        invalidated: 46,
        other: 44,
        test: 4,
        train: 6,
        validated: 447
      },
      duration: 1278637,
      avgDurationSecs: 2.381,
      validDurationSecs: 1064.34,
      totalHrs: 0.35,
      validHrs: 0.29
    },
    ru: {
      clips: 2195,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.46,
          teens: 0.12,
          thirties: 0.13,
          twenties: 0.23,
          fourties: 0.05,
          fifties: 0.01
        },
        gender: { '': 0.47, male: 0.46, female: 0.06, other: 0.01 }
      },
      users: 214,
      buckets: {
        dev: 4,
        invalidated: 104,
        other: 57,
        test: 4,
        train: 6,
        validated: 2034
      },
      duration: 4977118,
      avgDurationSecs: 2.267,
      validDurationSecs: 4612.054,
      totalHrs: 1.38,
      validHrs: 1.28
    },
    pt: {
      clips: 1307,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.4,
          fourties: 0.11,
          twenties: 0.29,
          thirties: 0.16,
          fifties: 0.03,
          sixties: 0,
          teens: 0.02
        },
        gender: { '': 0.4, male: 0.54, female: 0.05, other: 0.01 }
      },
      users: 140,
      buckets: {
        dev: 4,
        invalidated: 87,
        other: 42,
        test: 4,
        train: 6,
        validated: 1178
      },
      duration: 3449335,
      avgDurationSecs: 2.639,
      validDurationSecs: 3108.888,
      totalHrs: 0.95,
      validHrs: 0.86
    },
    nl: {
      clips: 644,
      splits: {
        accent: { suriname: 0.02, netherlands: 0.39, '': 0.46, belgium: 0.13 },
        age: {
          '': 0.5,
          twenties: 0.32,
          sixties: 0,
          thirties: 0.04,
          fifties: 0.04,
          fourties: 0.1
        },
        gender: { male: 0.45, female: 0.06, '': 0.5 }
      },
      users: 67,
      buckets: {
        dev: 4,
        invalidated: 21,
        other: 7,
        test: 4,
        train: 6,
        validated: 616
      },
      duration: 1531757,
      avgDurationSecs: 2.379,
      validDurationSecs: 1465.159,
      totalHrs: 0.42,
      validHrs: 0.4
    },
    de: {
      clips: 15090,
      splits: {
        accent: {
          germany: 0.26,
          austria: 0.02,
          '': 0.7,
          france: 0,
          italy: 0,
          switzerland: 0.01,
          united_states: 0,
          russia: 0,
          united_kingdom: 0,
          lithuania: 0
        },
        age: {
          fourties: 0.04,
          fifties: 0.05,
          twenties: 0.12,
          thirties: 0.07,
          '': 0.63,
          seventies: 0.01,
          teens: 0.06,
          sixties: 0.02,
          eighties: 0
        },
        gender: { male: 0.31, female: 0.06, '': 0.63, other: 0 }
      },
      users: 1618,
      buckets: {
        dev: 4,
        invalidated: 1386,
        other: 56,
        test: 4,
        train: 7,
        validated: 13648
      },
      duration: 39418281,
      avgDurationSecs: 2.612,
      validDurationSecs: 35651.471,
      totalHrs: 10.94,
      validHrs: 9.9
    },
    ta: {
      clips: 341,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.45,
          fifties: 0.04,
          fourties: 0.04,
          twenties: 0.17,
          thirties: 0.26,
          teens: 0.03
        },
        gender: { '': 0.49, male: 0.35, female: 0.16 }
      },
      users: 39,
      buckets: {
        dev: 4,
        invalidated: 39,
        other: 50,
        test: 4,
        train: 6,
        validated: 252
      },
      duration: 992550,
      avgDurationSecs: 2.911,
      validDurationSecs: 733.497,
      totalHrs: 0.27,
      validHrs: 0.2
    },
    ar: {
      clips: 564,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.51,
          thirties: 0.11,
          twenties: 0.28,
          teens: 0.05,
          fifties: 0.02,
          fourties: 0.02
        },
        gender: { '': 0.5, male: 0.42, female: 0.08 }
      },
      users: 67,
      buckets: {
        dev: 4,
        invalidated: 45,
        other: 39,
        test: 4,
        train: 6,
        validated: 480
      },
      duration: 1530140,
      avgDurationSecs: 2.713,
      validDurationSecs: 1302.247,
      totalHrs: 0.42,
      validHrs: 0.36
    },
    en: {
      clips: 49370,
      splits: {
        accent: {
          '': 0.75,
          us: 0.12,
          england: 0.04,
          indian: 0.04,
          australia: 0.01,
          canada: 0.02,
          newzealand: 0,
          scotland: 0,
          hongkong: 0.01,
          ireland: 0,
          singapore: 0,
          philippines: 0,
          malaysia: 0,
          african: 0,
          wales: 0
        },
        age: {
          '': 0.61,
          twenties: 0.18,
          teens: 0.05,
          fourties: 0.04,
          thirties: 0.07,
          sixties: 0.01,
          fifties: 0.03,
          seventies: 0.01,
          nineties: 0,
          eighties: 0
        },
        gender: { '': 0.62, female: 0.09, male: 0.29, other: 0.01 }
      },
      users: 5876,
      buckets: {
        dev: 4,
        invalidated: 4071,
        other: 12573,
        test: 4,
        train: 6,
        validated: 32726
      },
      duration: 132611224,
      avgDurationSecs: 2.686,
      validDurationSecs: 87904.292,
      totalHrs: 36.83,
      validHrs: 24.41
    },
    fr: {
      clips: 22382,
      splits: {
        accent: {
          france: 0.23,
          '': 0.74,
          germany: 0,
          canada: 0.01,
          united_kingdom: 0,
          belgium: 0.01,
          switzerland: 0.01,
          reunion: 0,
          senegal: 0,
          martinique: 0,
          congo_brazzaville: 0,
          cameroon: 0,
          united_states: 0,
          madagascar: 0,
          romania: 0,
          benin: 0,
          cote_d_ivoire: 0,
          morocco: 0
        },
        age: {
          sixties: 0.01,
          '': 0.7,
          twenties: 0.1,
          thirties: 0.06,
          fourties: 0.05,
          teens: 0.05,
          fifties: 0.03,
          seventies: 0
        },
        gender: { male: 0.24, '': 0.7, female: 0.06, other: 0.01 }
      },
      users: 2560,
      buckets: {
        dev: 4,
        invalidated: 2345,
        other: 20,
        test: 4,
        train: 6,
        validated: 20017
      },
      duration: 59131896,
      avgDurationSecs: 2.642,
      validDurationSecs: 52883.708,
      totalHrs: 16.42,
      validHrs: 14.68
    },
    cy: {
      clips: 553,
      splits: {
        accent: { united_kingdom: 0.47, '': 0.53 },
        age: {
          twenties: 0.17,
          thirties: 0.07,
          '': 0.53,
          teens: 0.04,
          fifties: 0.09,
          fourties: 0.06,
          sixties: 0.02,
          seventies: 0.02
        },
        gender: { female: 0.22, male: 0.24, '': 0.54 }
      },
      users: 64,
      buckets: {
        dev: 3,
        invalidated: 15,
        other: 62,
        test: 3,
        train: 4,
        validated: 476
      },
      duration: 1512525,
      avgDurationSecs: 2.735,
      validDurationSecs: 1301.92,
      totalHrs: 0.42,
      validHrs: 0.36
    },
    eo: {
      clips: 965,
      splits: {
        accent: { '': 0.67, internacia: 0.33 },
        age: {
          '': 0.45,
          fourties: 0.03,
          twenties: 0.28,
          teens: 0.06,
          eighties: 0.01,
          sixties: 0.02,
          thirties: 0.14,
          fifties: 0.01
        },
        gender: { '': 0.46, male: 0.48, other: 0.01, female: 0.05 }
      },
      users: 91,
      buckets: {
        dev: 3,
        invalidated: 41,
        other: 27,
        test: 3,
        train: 6,
        validated: 897
      },
      duration: 2260824,
      avgDurationSecs: 2.343,
      validDurationSecs: 2101.512,
      totalHrs: 0.62,
      validHrs: 0.58
    },
    ja: {
      clips: 644,
      splits: {
        accent: { '': 1 },
        age: {
          teens: 0.05,
          twenties: 0.5,
          '': 0.32,
          fifties: 0.02,
          fourties: 0.04,
          thirties: 0.06
        },
        gender: { male: 0.43, female: 0.24, '': 0.31, other: 0.02 }
      },
      users: 74,
      buckets: {
        dev: 3,
        invalidated: 34,
        other: 18,
        test: 3,
        train: 6,
        validated: 592
      },
      duration: 1454877,
      avgDurationSecs: 2.259,
      validDurationSecs: 1337.402,
      totalHrs: 0.4,
      validHrs: 0.37
    },
    id: {
      clips: 171,
      splits: {
        accent: { '': 1 },
        age: { '': 0.37, twenties: 0.63 },
        gender: { '': 0.37, male: 0.5, female: 0.07, other: 0.06 }
      },
      users: 20,
      buckets: {
        dev: 3,
        invalidated: 11,
        other: 17,
        test: 3,
        train: 6,
        validated: 143
      },
      duration: 375907,
      avgDurationSecs: 2.198,
      validDurationSecs: 314.355,
      totalHrs: 0.1,
      validHrs: 0.08
    },
    eu: {
      clips: 635,
      splits: {
        accent: { '': 0.8, mendebalekoa: 0.12, erdialdekoa_nafarra: 0.08 },
        age: {
          '': 0.74,
          teens: 0.01,
          fourties: 0.16,
          fifties: 0.04,
          thirties: 0.04,
          twenties: 0.02
        },
        gender: { '': 0.74, female: 0.09, male: 0.17 }
      },
      users: 80,
      buckets: {
        dev: 2,
        invalidated: 13,
        other: 406,
        test: 3,
        train: 7,
        validated: 216
      },
      duration: 1577809,
      avgDurationSecs: 2.485,
      validDurationSecs: 536.704,
      totalHrs: 0.43,
      validHrs: 0.14
    },
    tt: {
      clips: 55,
      splits: {
        accent: { '': 1 },
        age: { thirties: 0.44, '': 0.55, twenties: 0.02 },
        gender: { male: 0.44, '': 0.55, female: 0.02 }
      },
      users: 8,
      buckets: {
        dev: 4,
        invalidated: 2,
        other: 17,
        test: 4,
        train: 6,
        validated: 36
      },
      duration: 154248,
      avgDurationSecs: 2.805,
      validDurationSecs: 100.962,
      totalHrs: 0.04,
      validHrs: 0.02
    },
    kab: {
      clips: 1529,
      splits: {
        accent: { '': 0.98, other: 0.02 },
        age: {
          fourties: 0.06,
          thirties: 0.13,
          '': 0.59,
          fifties: 0.02,
          twenties: 0.17,
          sixties: 0.02,
          teens: 0.01
        },
        gender: { male: 0.35, '': 0.6, female: 0.06 }
      },
      users: 168,
      buckets: {
        dev: 3,
        invalidated: 150,
        other: 160,
        test: 3,
        train: 6,
        validated: 1219
      },
      duration: 4224717,
      avgDurationSecs: 2.763,
      validDurationSecs: 3368.169,
      totalHrs: 1.17,
      validHrs: 0.93
    },
    br: {
      clips: 44,
      splits: {
        accent: { gwenedeg: 0.52, '': 0.41, kerneveg: 0.07 },
        age: {
          fourties: 0.05,
          twenties: 0.3,
          '': 0.2,
          fifties: 0.39,
          teens: 0.07
        },
        gender: { male: 0.8, '': 0.2 }
      },
      users: 8,
      buckets: {
        dev: 0,
        invalidated: 0,
        other: 42,
        test: 0,
        train: 2,
        validated: 2
      },
      duration: 113952,
      avgDurationSecs: 2.59,
      validDurationSecs: 5.18,
      totalHrs: 0.03,
      validHrs: 0
    },
    'zh-CN': {
      clips: 2731,
      splits: {
        accent: {
          '110000': 0.06,
          '120000': 0,
          '130000': 0.01,
          '140000': 0.01,
          '210000': 0.01,
          '230000': 0.02,
          '310000': 0,
          '320000': 0.03,
          '330000': 0.04,
          '340000': 0.02,
          '350000': 0.02,
          '360000': 0.01,
          '370000': 0.02,
          '410000': 0.01,
          '420000': 0.02,
          '430000': 0.01,
          '440000': 0.03,
          '450000': 0,
          '460000': 0,
          '500000': 0,
          '510000': 0.01,
          '530000': 0.01,
          '610000': 0.01,
          '620000': 0.01,
          '650000': 0.01,
          '710000': 0,
          '': 0.63
        },
        age: {
          thirties: 0.06,
          '': 0.6,
          twenties: 0.23,
          teens: 0.08,
          fourties: 0.02,
          fifties: 0.01
        },
        gender: { male: 0.27, '': 0.61, female: 0.1, other: 0.02 }
      },
      users: 338,
      buckets: {
        dev: 3,
        invalidated: 216,
        other: 0,
        test: 3,
        train: 6,
        validated: 2515
      },
      duration: 6478785,
      avgDurationSecs: 2.372,
      validDurationSecs: 5966.366,
      totalHrs: 1.79,
      validHrs: 1.65
    },
    'fy-NL': {
      clips: 216,
      splits: {
        accent: { '': 1 },
        age: { '': 0.84, sixties: 0.05, fourties: 0.11 },
        gender: { '': 0.84, female: 0.1, male: 0.06 }
      },
      users: 22,
      buckets: {
        dev: 3,
        invalidated: 13,
        other: 126,
        test: 3,
        train: 6,
        validated: 77
      },
      duration: 650279,
      avgDurationSecs: 3.011,
      validDurationSecs: 231.812,
      totalHrs: 0.18,
      validHrs: 0.06
    },
    'zh-TW': {
      clips: 29,
      splits: {
        accent: { '': 0.97, new_taipei_city: 0.03 },
        age: { '': 0.38, teens: 0.41, twenties: 0.21 },
        gender: { '': 0.38, male: 0.62 }
      },
      users: 5,
      buckets: {
        dev: 0,
        invalidated: 1,
        other: 0,
        test: 2,
        train: 10,
        validated: 28
      },
      duration: 66620,
      avgDurationSecs: 2.297,
      validDurationSecs: 64.323,
      totalHrs: 0.01,
      validHrs: 0.01
    },
    cs: {
      clips: 1962,
      splits: {
        accent: { '': 1 },
        age: {
          teens: 0.05,
          '': 0.68,
          thirties: 0.11,
          twenties: 0.13,
          fourties: 0.03,
          fifties: 0.01,
          seventies: 0.01
        },
        gender: { male: 0.29, '': 0.67, female: 0.04 }
      },
      users: 198,
      buckets: {
        dev: 3,
        invalidated: 34,
        other: 0,
        test: 3,
        train: 6,
        validated: 1928
      },
      duration: 4678287,
      avgDurationSecs: 2.384,
      validDurationSecs: 4597.216,
      totalHrs: 1.29,
      validHrs: 1.27
    },
    'zh-HK': {
      clips: 1623,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.71,
          twenties: 0.11,
          fourties: 0.06,
          thirties: 0.08,
          teens: 0.02,
          fifties: 0.01,
          sixties: 0.01
        },
        gender: { '': 0.71, male: 0.2, female: 0.1 }
      },
      users: 177,
      buckets: {
        dev: 3,
        invalidated: 93,
        other: 0,
        test: 3,
        train: 6,
        validated: 1530
      },
      duration: 4064590,
      avgDurationSecs: 2.504,
      validDurationSecs: 3831.684,
      totalHrs: 1.12,
      validHrs: 1.06
    },
    lg: {
      clips: 58,
      splits: {
        accent: { '': 1 },
        age: { twenties: 0.4, '': 0.38, thirties: 0.22 },
        gender: { male: 0.19, '': 0.59, female: 0.22 }
      },
      users: 9,
      buckets: {
        dev: 3,
        invalidated: 3,
        other: 38,
        test: 3,
        train: 5,
        validated: 17
      },
      duration: 168596,
      avgDurationSecs: 2.907,
      validDurationSecs: 49.416,
      totalHrs: 0.04,
      validHrs: 0.01
    },
    ka: {
      clips: 656,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.71,
          twenties: 0.2,
          thirties: 0.03,
          fifties: 0.01,
          teens: 0.04
        },
        gender: { '': 0.71, male: 0.21, female: 0.08 }
      },
      users: 73,
      buckets: {
        dev: 3,
        invalidated: 31,
        other: 12,
        test: 3,
        train: 6,
        validated: 613
      },
      duration: 1587372,
      avgDurationSecs: 2.42,
      validDurationSecs: 1483.322,
      totalHrs: 0.44,
      validHrs: 0.41
    },
    'sv-SE': {
      clips: 34,
      splits: {
        accent: { '': 1 },
        age: { fourties: 0.47, '': 0.47, thirties: 0.06 },
        gender: { female: 0.35, '': 0.47, male: 0.18 }
      },
      users: 6,
      buckets: {
        dev: 0,
        invalidated: 0,
        other: 5,
        test: 0,
        train: 12,
        validated: 29
      },
      duration: 86892,
      avgDurationSecs: 2.556,
      validDurationSecs: 74.114,
      totalHrs: 0.02,
      validHrs: 0.02
    },
    cv: {
      clips: 125,
      splits: {
        accent: { '': 1 },
        age: { teens: 0.26, twenties: 0.19, '': 0.45, fourties: 0.1 },
        gender: { female: 0.22, male: 0.42, '': 0.35 }
      },
      users: 13,
      buckets: {
        dev: 3,
        invalidated: 2,
        other: 37,
        test: 3,
        train: 6,
        validated: 86
      },
      duration: 310861,
      avgDurationSecs: 2.487,
      validDurationSecs: 213.872,
      totalHrs: 0.08,
      validHrs: 0.05
    },
    ky: {
      clips: 946,
      splits: {
        accent: { '': 0.99, other: 0.01 },
        age: { '': 0.27, thirties: 0.05, twenties: 0.46, teens: 0.23 },
        gender: { '': 0.29, male: 0.51, female: 0.19 }
      },
      users: 96,
      buckets: {
        dev: 3,
        invalidated: 72,
        other: 88,
        test: 3,
        train: 6,
        validated: 786
      },
      duration: 2327196,
      avgDurationSecs: 2.46,
      validDurationSecs: 1933.59,
      totalHrs: 0.64,
      validHrs: 0.53
    },
    rw: {
      clips: 6921,
      splits: {
        accent: { '': 1 },
        age: {
          twenties: 0.58,
          '': 0.13,
          fourties: 0.05,
          teens: 0.09,
          thirties: 0.13,
          fifties: 0.01
        },
        gender: { male: 0.48, '': 0.15, female: 0.37 }
      },
      users: 609,
      buckets: {
        dev: 3,
        invalidated: 23,
        other: 6306,
        test: 3,
        train: 6,
        validated: 592
      },
      duration: 16715172,
      avgDurationSecs: 2.415,
      validDurationSecs: 1429.762,
      totalHrs: 4.64,
      validHrs: 0.39
    },
    or: {
      clips: 80,
      splits: {
        accent: { '': 1 },
        age: { fourties: 0.06, '': 0.47, thirties: 0.06, twenties: 0.4 },
        gender: { male: 0.61, '': 0.39 }
      },
      users: 9,
      buckets: {
        dev: 3,
        invalidated: 3,
        other: 48,
        test: 3,
        train: 6,
        validated: 29
      },
      duration: 221652,
      avgDurationSecs: 2.771,
      validDurationSecs: 80.349,
      totalHrs: 0.06,
      validHrs: 0.02
    },
    ab: {
      clips: 221,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.17,
          twenties: 0.07,
          fifties: 0.22,
          thirties: 0.29,
          teens: 0.11,
          sixties: 0.1,
          fourties: 0.05
        },
        gender: { '': 0.17, male: 0.45, female: 0.38 }
      },
      users: 21,
      buckets: {
        dev: 3,
        invalidated: 11,
        other: 37,
        test: 3,
        train: 6,
        validated: 173
      },
      duration: 553140,
      avgDurationSecs: 2.503,
      validDurationSecs: 433.001,
      totalHrs: 0.15,
      validHrs: 0.12
    },
    th: {
      clips: 31,
      splits: {
        accent: { '': 1 },
        age: {
          fifties: 0.45,
          '': 0.1,
          twenties: 0.29,
          thirties: 0.1,
          fourties: 0.06
        },
        gender: { male: 0.58, '': 0.1, female: 0.32 }
      },
      users: 11,
      buckets: {
        dev: 3,
        invalidated: 0,
        other: 8,
        test: 3,
        train: 6,
        validated: 23
      },
      duration: 73332,
      avgDurationSecs: 2.366,
      validDurationSecs: 54.408,
      totalHrs: 0.02,
      validHrs: 0.01
    }
  },
  overall: {
    size:3766437719,
    checksum:"f96d00524f8859a8bd154eb98822ec06a9d451e5902a419deb5e1472f1d2c3ff"
  },
  totalDuration: 510515451,
  totalValidDurationSecs: 296699,
  totalHrs: 141,
  totalValidHrs: 82,
  totalClips: 188059
};
