exports.stats = {
  bundleURL: 'cv-corpus-6.0-singlword/cv-corpus-6.0-singlword.tar.gz',
  multilingual: false,
  exclude: true,
  locales: {
    es: {
      clips: 70038,
      splits: {
        accent: {
          surpeninsular: 0.01,
          '': 0.8,
          americacentral: 0.02,
          centrosurpeninsular: 0.02,
          mexicano: 0.05,
          caribe: 0.02,
          nortepeninsular: 0.02,
          andino: 0.04,
          rioplatense: 0.02,
          chileno: 0.01,
          canario: 0,
          filipinas: 0,
        },
        age: {
          sixties: 0.01,
          '': 0.74,
          thirties: 0.05,
          fifties: 0.02,
          fourties: 0.04,
          twenties: 0.11,
          teens: 0.04,
          seventies: 0,
          nineties: 0,
          eighties: 0,
        },
        gender: { '': 0.91, female: 0.09 },
      },
      users: 9052,
      duration: 202516646,
      reportedSentences: 1100,
      buckets: {
        dev: 4,
        invalidated: 6542,
        other: 40513,
        reported: 1109,
        test: 4,
        train: 6,
        validated: 22983,
      },
      avgDurationSecs: 2.892,
      validDurationSecs: 66455.925,
      totalHrs: 56.25,
      validHrs: 18.45,
    },
    pl: {
      clips: 1182,
      splits: {
        accent: { '': 1 },
        age: {
          twenties: 0.25,
          thirties: 0.17,
          fourties: 0.02,
          teens: 0.08,
          '': 0.47,
          sixties: 0.02,
        },
        gender: { '': 0.79, female: 0.21 },
      },
      users: 112,
      duration: 2713140,
      reportedSentences: 431,
      buckets: {
        dev: 4,
        invalidated: 37,
        other: 3,
        reported: 431,
        test: 4,
        train: 6,
        validated: 1142,
      },
      avgDurationSecs: 2.295,
      validDurationSecs: 2621.325,
      totalHrs: 0.75,
      validHrs: 0.72,
    },
    ca: {
      clips: 4180,
      splits: {
        accent: {
          valencian: 0.08,
          '': 0.51,
          central: 0.35,
          northern: 0.01,
          northwestern: 0.03,
          balearic: 0.02,
        },
        age: {
          fifties: 0.08,
          '': 0.52,
          sixties: 0.04,
          thirties: 0.12,
          fourties: 0.1,
          twenties: 0.1,
          teens: 0.04,
          seventies: 0.01,
        },
        gender: { '': 0.92, female: 0.08 },
      },
      users: 387,
      duration: 10707094,
      reportedSentences: 1187,
      buckets: {
        dev: 4,
        invalidated: 217,
        other: 295,
        reported: 1189,
        test: 4,
        train: 6,
        validated: 3668,
      },
      avgDurationSecs: 2.562,
      validDurationSecs: 9395.603,
      totalHrs: 2.97,
      validHrs: 2.6,
    },
    tr: {
      clips: 537,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.5,
          fourties: 0.12,
          twenties: 0.26,
          teens: 0.03,
          thirties: 0.1,
        },
        gender: { '': 0.8, female: 0.2 },
      },
      users: 60,
      duration: 1278637,
      reportedSentences: 73,
      buckets: {
        dev: 4,
        invalidated: 46,
        other: 44,
        reported: 72,
        test: 4,
        train: 6,
        validated: 447,
      },
      avgDurationSecs: 2.381,
      validDurationSecs: 1064.34,
      totalHrs: 0.35,
      validHrs: 0.29,
    },
    ru: {
      clips: 2195,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.46,
          teens: 0.12,
          thirties: 0.13,
          twenties: 0.23,
          fourties: 0.05,
          fifties: 0.01,
        },
        gender: { '': 0.79, female: 0.21 },
      },
      users: 214,
      duration: 4977118,
      reportedSentences: 137,
      buckets: {
        dev: 4,
        invalidated: 104,
        other: 52,
        reported: 143,
        test: 4,
        train: 6,
        validated: 2039,
      },
      avgDurationSecs: 2.267,
      validDurationSecs: 4623.391,
      totalHrs: 1.38,
      validHrs: 1.28,
    },
    pt: {
      clips: 1307,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.4,
          fourties: 0.11,
          twenties: 0.29,
          thirties: 0.16,
          fifties: 0.03,
          sixties: 0,
          teens: 0.02,
        },
        gender: { '': 0.75, female: 0.25 },
      },
      users: 140,
      duration: 3449335,
      reportedSentences: 1694,
      buckets: {
        dev: 4,
        invalidated: 87,
        other: 40,
        reported: 1698,
        test: 4,
        train: 6,
        validated: 1180,
      },
      avgDurationSecs: 2.639,
      validDurationSecs: 3114.166,
      totalHrs: 0.95,
      validHrs: 0.86,
    },
    nl: {
      clips: 644,
      splits: {
        accent: { suriname: 0.02, netherlands: 0.39, '': 0.46, belgium: 0.13 },
        age: {
          '': 0.5,
          twenties: 0.32,
          sixties: 0,
          thirties: 0.04,
          fifties: 0.04,
          fourties: 0.1,
        },
        gender: { '': 0.74, female: 0.26 },
      },
      users: 67,
      duration: 1531757,
      reportedSentences: 202,
      buckets: {
        dev: 4,
        invalidated: 21,
        other: 7,
        reported: 201,
        test: 4,
        train: 6,
        validated: 616,
      },
      avgDurationSecs: 2.379,
      validDurationSecs: 1465.159,
      totalHrs: 0.42,
      validHrs: 0.4,
    },
    de: {
      clips: 15090,
      splits: {
        accent: {
          germany: 0.26,
          austria: 0.02,
          '': 0.7,
          france: 0,
          italy: 0,
          switzerland: 0.01,
          united_states: 0,
          russia: 0,
          united_kingdom: 0,
          lithuania: 0,
        },
        age: {
          fourties: 0.04,
          fifties: 0.05,
          twenties: 0.12,
          thirties: 0.07,
          '': 0.63,
          seventies: 0.01,
          teens: 0.06,
          sixties: 0.02,
          eighties: 0,
        },
        gender: { '': 0.89, female: 0.11 },
      },
      users: 1618,
      duration: 39418281,
      reportedSentences: 3698,
      buckets: {
        dev: 4,
        invalidated: 1386,
        other: 40,
        reported: 3708,
        test: 4,
        train: 7,
        validated: 13664,
      },
      avgDurationSecs: 2.612,
      validDurationSecs: 35693.267,
      totalHrs: 10.94,
      validHrs: 9.91,
    },
    ta: {
      clips: 341,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.45,
          fifties: 0.04,
          fourties: 0.04,
          twenties: 0.17,
          thirties: 0.26,
          teens: 0.03,
        },
        gender: { '': 0.87, female: 0.13 },
      },
      users: 39,
      duration: 992550,
      reportedSentences: 257,
      buckets: {
        dev: 4,
        invalidated: 39,
        other: 50,
        reported: 257,
        test: 4,
        train: 6,
        validated: 252,
      },
      avgDurationSecs: 2.911,
      validDurationSecs: 733.497,
      totalHrs: 0.27,
      validHrs: 0.2,
    },
    ar: {
      clips: 564,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.51,
          thirties: 0.11,
          twenties: 0.28,
          teens: 0.05,
          fifties: 0.02,
          fourties: 0.02,
        },
        gender: { '': 0.79, female: 0.21 },
      },
      users: 67,
      duration: 1530140,
      reportedSentences: 1312,
      buckets: {
        dev: 4,
        invalidated: 46,
        other: 33,
        reported: 1317,
        test: 4,
        train: 6,
        validated: 485,
      },
      avgDurationSecs: 2.713,
      validDurationSecs: 1315.812,
      totalHrs: 0.42,
      validHrs: 0.36,
    },
    en: {
      clips: 49370,
      splits: {
        accent: {
          '': 0.76,
          us: 0.12,
          england: 0.04,
          indian: 0.04,
          australia: 0.01,
          canada: 0.02,
          newzealand: 0,
          scotland: 0,
          hongkong: 0.01,
          ireland: 0,
          singapore: 0,
          philippines: 0,
          malaysia: 0,
          african: 0,
          wales: 0,
        },
        age: {
          '': 0.61,
          twenties: 0.18,
          teens: 0.05,
          fourties: 0.04,
          thirties: 0.07,
          sixties: 0.01,
          fifties: 0.03,
          seventies: 0.01,
          nineties: 0,
          eighties: 0,
        },
        gender: { '': 0.86, female: 0.14 },
      },
      users: 5876,
      duration: 132611224,
      reportedSentences: 1698,
      buckets: {
        dev: 4,
        invalidated: 4071,
        other: 12571,
        reported: 1744,
        test: 4,
        train: 6,
        validated: 32728,
      },
      avgDurationSecs: 2.686,
      validDurationSecs: 87909.665,
      totalHrs: 36.83,
      validHrs: 24.41,
    },
    fr: {
      clips: 22382,
      splits: {
        accent: {
          france: 0.23,
          '': 0.74,
          germany: 0,
          canada: 0.01,
          united_kingdom: 0,
          belgium: 0.01,
          switzerland: 0.01,
          reunion: 0,
          senegal: 0,
          martinique: 0,
          congo_brazzaville: 0,
          cameroon: 0,
          united_states: 0,
          madagascar: 0,
          romania: 0,
          benin: 0,
          cote_d_ivoire: 0,
          morocco: 0,
        },
        age: {
          sixties: 0.01,
          '': 0.7,
          twenties: 0.1,
          thirties: 0.06,
          fourties: 0.05,
          teens: 0.05,
          fifties: 0.03,
          seventies: 0,
        },
        gender: { '': 0.91, female: 0.09 },
      },
      users: 2560,
      duration: 59131896,
      reportedSentences: 3155,
      buckets: {
        dev: 4,
        invalidated: 2345,
        other: 20,
        reported: 3181,
        test: 4,
        train: 6,
        validated: 20017,
      },
      avgDurationSecs: 2.642,
      validDurationSecs: 52883.708,
      totalHrs: 16.42,
      validHrs: 14.68,
    },
    cy: {
      clips: 553,
      splits: {
        accent: { united_kingdom: 0.45, '': 0.55 },
        age: {
          twenties: 0.17,
          thirties: 0.07,
          '': 0.53,
          teens: 0.04,
          fifties: 0.09,
          fourties: 0.06,
          sixties: 0.02,
          seventies: 0.02,
        },
        gender: { '': 0.94, female: 0.06 },
      },
      users: 64,
      duration: 1512525,
      reportedSentences: 123,
      buckets: {
        dev: 3,
        invalidated: 15,
        other: 62,
        reported: 122,
        test: 3,
        train: 4,
        validated: 476,
      },
      avgDurationSecs: 2.735,
      validDurationSecs: 1301.92,
      totalHrs: 0.42,
      validHrs: 0.36,
    },
    eo: {
      clips: 965,
      splits: {
        accent: { '': 0.67, internacia: 0.33 },
        age: {
          '': 0.45,
          fourties: 0.03,
          twenties: 0.28,
          teens: 0.06,
          eighties: 0.01,
          sixties: 0.02,
          thirties: 0.14,
          fifties: 0.01,
        },
        gender: { '': 0.77, female: 0.23 },
      },
      users: 91,
      duration: 2260824,
      reportedSentences: 404,
      buckets: {
        dev: 3,
        invalidated: 41,
        other: 23,
        reported: 404,
        test: 3,
        train: 6,
        validated: 901,
      },
      avgDurationSecs: 2.343,
      validDurationSecs: 2110.883,
      totalHrs: 0.62,
      validHrs: 0.58,
    },
    ja: {
      clips: 644,
      splits: {
        accent: { '': 1 },
        age: {
          teens: 0.05,
          twenties: 0.5,
          '': 0.32,
          fifties: 0.02,
          fourties: 0.04,
          thirties: 0.06,
        },
        gender: { '': 0.68, female: 0.32 },
      },
      users: 74,
      duration: 1454877,
      reportedSentences: 44,
      buckets: {
        dev: 3,
        invalidated: 34,
        other: 18,
        reported: 43,
        test: 3,
        train: 6,
        validated: 592,
      },
      avgDurationSecs: 2.259,
      validDurationSecs: 1337.402,
      totalHrs: 0.4,
      validHrs: 0.37,
    },
    id: {
      clips: 171,
      splits: {
        accent: { '': 1 },
        age: { '': 0.37, twenties: 0.63 },
        gender: { '': 0.5, female: 0.5 },
      },
      users: 20,
      duration: 375907,
      reportedSentences: 201,
      buckets: {
        dev: 3,
        invalidated: 11,
        other: 17,
        reported: 200,
        test: 3,
        train: 6,
        validated: 143,
      },
      avgDurationSecs: 2.198,
      validDurationSecs: 314.355,
      totalHrs: 0.1,
      validHrs: 0.08,
    },
    eu: {
      clips: 635,
      splits: {
        accent: { '': 0.8, mendebalekoa: 0.12, erdialdekoa_nafarra: 0.08 },
        age: {
          '': 0.74,
          teens: 0.01,
          fourties: 0.16,
          fifties: 0.04,
          thirties: 0.04,
          twenties: 0.02,
        },
        gender: { '': 0.99, female: 0.01 },
      },
      users: 80,
      duration: 1577809,
      reportedSentences: 39,
      buckets: {
        dev: 2,
        invalidated: 13,
        other: 406,
        reported: 38,
        test: 3,
        train: 7,
        validated: 216,
      },
      avgDurationSecs: 2.485,
      validDurationSecs: 536.704,
      totalHrs: 0.43,
      validHrs: 0.14,
    },
    tt: {
      clips: 55,
      splits: {
        accent: { '': 1 },
        age: { thirties: 0.44, '': 0.55, twenties: 0.02 },
        gender: { '': 1 },
      },
      users: 8,
      duration: 154248,
      reportedSentences: 3,
      buckets: {
        dev: 4,
        invalidated: 2,
        other: 18,
        reported: 2,
        test: 4,
        train: 6,
        validated: 35,
      },
      avgDurationSecs: 2.805,
      validDurationSecs: 98.158,
      totalHrs: 0.04,
      validHrs: 0.02,
    },
    kab: {
      clips: 513,
      splits: {
        accent: { '': 0.98, other: 0.02 },
        age: {
          fourties: 0.08,
          thirties: 0.2,
          '': 0.44,
          fifties: 0.05,
          twenties: 0.19,
          sixties: 0.01,
          teens: 0.02,
        },
        gender: { '': 0.84, female: 0.16 },
      },
      users: 53,
      duration: 1458225,
      reportedSentences: 3088,
      buckets: {
        dev: 3,
        invalidated: 10,
        other: 344,
        reported: 3092,
        test: 3,
        train: 6,
        validated: 159,
      },
      avgDurationSecs: 2.843,
      validDurationSecs: 451.964,
      totalHrs: 0.4,
      validHrs: 0.12,
    },
    br: {
      clips: 42,
      splits: {
        accent: { gwenedeg: 0.55, '': 0.38, kerneveg: 0.07 },
        age: {
          fourties: 0.05,
          twenties: 0.26,
          '': 0.21,
          fifties: 0.4,
          teens: 0.07,
        },
        gender: { '': 0.74, female: 0.26 },
      },
      users: 7,
      duration: 109416,
      reportedSentences: 167,
      buckets: {
        dev: 0,
        invalidated: 2,
        other: 35,
        reported: 166,
        test: 1,
        train: 3,
        validated: 5,
      },
      avgDurationSecs: 2.605,
      validDurationSecs: 13.026,
      totalHrs: 0.03,
      validHrs: 0,
    },
    'zh-CN': {
      clips: 319,
      splits: {
        accent: {
          '140000': 0,
          '230000': 0.08,
          '320000': 0.05,
          '340000': 0.04,
          '350000': 0.04,
          '410000': 0.04,
          '420000': 0.04,
          '450000': 0.04,
          '500000': 0.02,
          '620000': 0.04,
          '': 0.63,
        },
        age: {
          thirties: 0.13,
          '': 0.51,
          twenties: 0.21,
          teens: 0.09,
          fourties: 0.05,
        },
        gender: { '': 0.88, female: 0.12 },
      },
      users: 39,
      duration: 778185,
      reportedSentences: 279,
      buckets: {
        dev: 3,
        invalidated: 24,
        other: 2,
        reported: 279,
        test: 3,
        train: 6,
        validated: 293,
      },
      avgDurationSecs: 2.439,
      validDurationSecs: 714.759,
      totalHrs: 0.21,
      validHrs: 0.19,
    },
    'fy-NL': {
      clips: 216,
      splits: {
        accent: { '': 1 },
        age: { '': 0.84, sixties: 0.05, fourties: 0.11 },
        gender: { '': 1 },
      },
      users: 22,
      duration: 650279,
      reportedSentences: 281,
      buckets: {
        dev: 3,
        invalidated: 13,
        other: 126,
        reported: 281,
        test: 3,
        train: 6,
        validated: 77,
      },
      avgDurationSecs: 3.011,
      validDurationSecs: 231.812,
      totalHrs: 0.18,
      validHrs: 0.06,
    },
    'zh-TW': {
      clips: 27,
      splits: {
        accent: { '': 1 },
        age: { '': 0.37, teens: 0.44, twenties: 0.19 },
        gender: { '': 0.81, female: 0.19 },
      },
      users: 3,
      duration: 61796,
      reportedSentences: 39,
      buckets: {
        dev: 0,
        invalidated: 2,
        other: 14,
        reported: 38,
        test: 1,
        train: 8,
        validated: 11,
      },
      avgDurationSecs: 2.289,
      validDurationSecs: 25.176,
      totalHrs: 0.01,
      validHrs: 0,
    },
    cs: {
      clips: 542,
      splits: {
        accent: { '': 1 },
        age: {
          teens: 0.04,
          '': 0.7,
          thirties: 0.15,
          twenties: 0.04,
          fourties: 0.04,
          fifties: 0.02,
        },
        gender: { '': 0.94, female: 0.06 },
      },
      users: 54,
      duration: 1358979,
      reportedSentences: 582,
      buckets: {
        dev: 3,
        invalidated: 14,
        other: 0,
        reported: 585,
        test: 3,
        train: 6,
        validated: 528,
      },
      avgDurationSecs: 2.507,
      validDurationSecs: 1323.876,
      totalHrs: 0.37,
      validHrs: 0.36,
    },
    'zh-HK': {
      clips: 503,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.74,
          twenties: 0.03,
          fourties: 0.09,
          thirties: 0.14,
          teens: 0.01,
        },
        gender: { '': 0.97, female: 0.03 },
      },
      users: 53,
      duration: 1335754,
      reportedSentences: 560,
      buckets: {
        dev: 3,
        invalidated: 21,
        other: 1,
        reported: 571,
        test: 3,
        train: 6,
        validated: 481,
      },
      avgDurationSecs: 2.656,
      validDurationSecs: 1277.331,
      totalHrs: 0.37,
      validHrs: 0.35,
    },
    lg: {
      clips: 56,
      splits: {
        accent: { '': 1 },
        age: { twenties: 0.39, '': 0.39, thirties: 0.21 },
        gender: { female: 0.18, '': 0.82 },
      },
      users: 7,
      duration: 163592,
      reportedSentences: 2,
      buckets: {
        dev: 0,
        invalidated: 3,
        other: 39,
        reported: 1,
        test: 1,
        train: 9,
        validated: 14,
      },
      avgDurationSecs: 2.921,
      validDurationSecs: 40.898,
      totalHrs: 0.04,
      validHrs: 0.01,
    },
    ka: {
      clips: 29,
      splits: {
        accent: { '': 1 },
        age: { '': 0.59, twenties: 0.41 },
        gender: { '': 0.59, female: 0.41 },
      },
      users: 3,
      duration: 74256,
      reportedSentences: 3,
      buckets: {
        dev: 0,
        invalidated: 0,
        other: 12,
        reported: 2,
        test: 0,
        train: 12,
        validated: 17,
      },
      avgDurationSecs: 2.561,
      validDurationSecs: 43.529,
      totalHrs: 0.02,
      validHrs: 0.01,
    },
    'sv-SE': {
      clips: 29,
      splits: {
        accent: { '': 1 },
        age: { fourties: 0.48, '': 0.52 },
        gender: { '': 1 },
      },
      users: 3,
      duration: 73608,
      reportedSentences: 152,
      buckets: {
        dev: 0,
        invalidated: 0,
        other: 6,
        reported: 151,
        test: 1,
        train: 11,
        validated: 23,
      },
      avgDurationSecs: 2.538,
      validDurationSecs: 58.379,
      totalHrs: 0.02,
      validHrs: 0.01,
    },
    cv: {
      clips: 67,
      splits: {
        accent: { '': 1 },
        age: { teens: 0.31, twenties: 0.18, '': 0.33, fourties: 0.18 },
        gender: { female: 0.24, '': 0.76 },
      },
      users: 8,
      duration: 186589,
      reportedSentences: 75,
      buckets: {
        dev: 1,
        invalidated: 2,
        other: 25,
        reported: 74,
        test: 3,
        train: 8,
        validated: 40,
      },
      avgDurationSecs: 2.785,
      validDurationSecs: 111.396,
      totalHrs: 0.05,
      validHrs: 0.03,
    },
    ky: {
      clips: 5,
      splits: { accent: { '': 1 }, age: { '': 1 }, gender: { '': 1 } },
      users: 1,
      duration: 15744,
      reportedSentences: 1,
      buckets: {
        dev: 0,
        invalidated: 0,
        other: 0,
        reported: 0,
        test: 0,
        train: 5,
        validated: 5,
      },
      avgDurationSecs: 3.149,
      validDurationSecs: 15.744,
      totalHrs: 0,
      validHrs: 0,
    },
    rw: {
      clips: 16,
      splits: {
        accent: { '': 1 },
        age: { twenties: 0.75, '': 0.25 },
        gender: { female: 0.75, '': 0.25 },
      },
      users: 2,
      duration: 41736,
      reportedSentences: 619,
      buckets: {
        dev: 0,
        invalidated: 2,
        other: 3,
        reported: 618,
        test: 1,
        train: 9,
        validated: 11,
      },
      avgDurationSecs: 2.609,
      validDurationSecs: 28.694,
      totalHrs: 0.01,
      validHrs: 0,
    },
  },
  overall: {
    size: 3562672677,
    checksum:
      '2c3ae4af727d07da13a5437c6cb7a0ada84e4985be125c0ab1b37c4ec1b039a7',
    avgDurationSecs: null,
    validDurationSecs: null,
    totalHrs: null,
    validHrs: null,
  },
  totalDuration: 474502167,
  totalValidDurationSecs: 277311,
  totalHrs: 131,
  totalValidHrs: 77,
  totalClips: 173217,
};
