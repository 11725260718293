exports.stats = {
  bundleURLTemplate: 'cv-corpus-3/{locale}.tar.gz',
  date: '2019-06-24',
  name: 'Common Voice Corpus 3',
  multilingual: true,
  locales: {
    en: {
      clips: 896823,
      splits: {
        accent: {
          '': 0.52,
          canada: 0.03,
          england: 0.09,
          us: 0.23,
          indian: 0.04,
          australia: 0.03,
          malaysia: 0,
          newzealand: 0.01,
          african: 0.01,
          ireland: 0,
          philippines: 0,
          singapore: 0,
          scotland: 0.01,
          hongkong: 0,
          bermuda: 0,
          southatlandtic: 0,
          wales: 0,
          other: 0.02,
        },
        age: {
          '': 0.41,
          twenties: 0.21,
          sixties: 0.03,
          thirties: 0.15,
          teens: 0.05,
          seventies: 0.01,
          fourties: 0.08,
          fifties: 0.04,
          eighties: 0,
          nineties: 0,
        },
        gender: { '': 0.41, male: 0.47, female: 0.11, other: 0.02 },
      },
      users: 39577,
      duration: 3914431152,
      buckets: {
        dev: 13178,
        invalidated: 78904,
        other: 173800,
        test: 13178,
        train: 63330,
        validated: 644119,
      },
      size: 30159205077,
      avgDurationSecs: 4.365,
      validDurationSecs: 2811434.898,
      totalHrs: 1087.34,
      validHrs: 780.95,
    },
    de: {
      clips: 293932,
      splits: {
        accent: {
          germany: 0.65,
          russia: 0,
          '': 0.3,
          austria: 0.02,
          liechtenstein: 0,
          switzerland: 0.01,
          france: 0,
          other: 0,
          poland: 0,
          united_kingdom: 0,
          hungary: 0,
          netherlands: 0,
          namibia: 0,
          slovakia: 0,
          united_states: 0,
          italy: 0,
        },
        age: {
          twenties: 0.28,
          fourties: 0.17,
          '': 0.22,
          thirties: 0.18,
          teens: 0.04,
          sixties: 0.02,
          fifties: 0.09,
          seventies: 0,
        },
        gender: { male: 0.68, '': 0.22, female: 0.1, other: 0 },
      },
      users: 5007,
      duration: 1226382048,
      buckets: {
        dev: 5633,
        invalidated: 12721,
        other: 4,
        test: 5633,
        train: 8518,
        validated: 281207,
      },
      size: 9533851782,
      avgDurationSecs: 4.172,
      validDurationSecs: 1173289.116,
      totalHrs: 340.66,
      validHrs: 325.91,
    },
    fr: {
      clips: 162340,
      splits: {
        accent: {
          '': 0.29,
          france: 0.64,
          germany: 0,
          belgium: 0.03,
          switzerland: 0.01,
          guadeloupe: 0,
          reunion: 0,
          monaco: 0,
          tunisia: 0,
          canada: 0.02,
          other: 0,
          mayotte: 0,
          algeria: 0,
          netherlands: 0,
          senegal: 0,
          martinique: 0,
          portugal: 0,
          united_states: 0,
          cote_d_ivoire: 0,
          st_pierre_et_miquelon: 0,
          french_guiana: 0,
          benin: 0,
          italy: 0,
          united_kingdom: 0,
          madagascar: 0,
          cameroon: 0,
        },
        age: {
          twenties: 0.24,
          thirties: 0.22,
          '': 0.22,
          teens: 0.04,
          fourties: 0.17,
          fifties: 0.05,
          sixties: 0.06,
          seventies: 0,
        },
        gender: { male: 0.7, '': 0.2, female: 0.09, other: 0.01 },
      },
      users: 3005,
      duration: 665133624,
      buckets: {
        dev: 10366,
        invalidated: 9978,
        other: 0,
        test: 10366,
        train: 27498,
        validated: 152362,
      },
      size: 5176500440,
      avgDurationSecs: 4.097,
      validDurationSecs: 624252.12,
      totalHrs: 184.75,
      validHrs: 173.4,
    },
    cy: {
      clips: 38646,
      splits: {
        accent: { united_kingdom: 0.43, '': 0.55, other: 0.02 },
        age: {
          fourties: 0.12,
          twenties: 0.09,
          sixties: 0.13,
          fifties: 0.07,
          '': 0.49,
          thirties: 0.07,
          seventies: 0.01,
          eighties: 0,
          teens: 0.01,
        },
        gender: { male: 0.33, female: 0.18, '': 0.49, other: 0.01 },
      },
      users: 748,
      duration: 174368352,
      buckets: {
        dev: 484,
        invalidated: 1696,
        other: 2744,
        test: 484,
        train: 500,
        validated: 34188,
      },
      size: 1348150916,
      avgDurationSecs: 4.512,
      validDurationSecs: 154254.133,
      totalHrs: 48.43,
      validHrs: 42.84,
    },
    br: {
      clips: 12155,
      splits: {
        accent: { other: 0, '': 1 },
        age: {
          twenties: 0.14,
          '': 0.5,
          fifties: 0.08,
          fourties: 0.11,
          thirties: 0.13,
          sixties: 0.04,
        },
        gender: { male: 0.47, '': 0.5, female: 0.03 },
      },
      users: 118,
      duration: 36714648,
      buckets: {
        dev: 1142,
        invalidated: 289,
        other: 7480,
        test: 1328,
        train: 1630,
        validated: 4386,
      },
      size: 279505456,
      avgDurationSecs: 3.021,
      validDurationSecs: 13248.083,
      totalHrs: 10.19,
      validHrs: 3.68,
    },
    cv: {
      clips: 2355,
      splits: {
        accent: { other: 0.01, '': 0.99 },
        age: {
          twenties: 0.04,
          '': 0.53,
          fourties: 0.38,
          thirties: 0.04,
          teens: 0.01,
        },
        gender: { male: 0.47, '': 0.53 },
      },
      users: 38,
      duration: 10101114,
      buckets: {
        dev: 78,
        invalidated: 108,
        other: 1220,
        test: 243,
        train: 566,
        validated: 1027,
      },
      size: 79035841,
      avgDurationSecs: 4.289,
      validDurationSecs: 4405.029,
      totalHrs: 2.8,
      validHrs: 1.22,
    },
    tr: {
      clips: 9700,
      splits: {
        accent: { '': 0.92, other: 0.08 },
        age: {
          '': 0.19,
          thirties: 0.33,
          twenties: 0.42,
          teens: 0.04,
          fourties: 0.02,
          fifties: 0,
        },
        gender: { '': 0.19, male: 0.7, female: 0.11 },
      },
      users: 344,
      duration: 37589112,
      buckets: {
        dev: 1620,
        invalidated: 829,
        other: 0,
        test: 1621,
        train: 1802,
        validated: 8871,
      },
      size: 288370679,
      avgDurationSecs: 3.875,
      validDurationSecs: 34376.599,
      totalHrs: 10.44,
      validHrs: 9.54,
    },
    tt: {
      clips: 25772,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.16,
          thirties: 0.79,
          twenties: 0.05,
          sixties: 0,
          fifties: 0.01,
          teens: 0,
          fourties: 0,
        },
        gender: { '': 0.16, male: 0.83, female: 0.02 },
      },
      users: 132,
      duration: 94851408,
      buckets: {
        dev: 1949,
        invalidated: 251,
        other: 3438,
        test: 4070,
        train: 8264,
        validated: 22083,
      },
      size: 710269716,
      avgDurationSecs: 3.68,
      validDurationSecs: 81274.392,
      totalHrs: 26.34,
      validHrs: 22.57,
    },
    ky: {
      clips: 15885,
      splits: {
        accent: { '': 0.91, other: 0.09 },
        age: {
          thirties: 0.15,
          '': 0.04,
          fourties: 0.01,
          twenties: 0.79,
          teens: 0.01,
        },
        gender: { male: 0.44, '': 0.09, female: 0.47, other: 0 },
      },
      users: 97,
      duration: 72991440,
      buckets: {
        dev: 971,
        invalidated: 779,
        other: 8249,
        test: 1501,
        train: 2320,
        validated: 6857,
      },
      size: 525926969,
      avgDurationSecs: 4.595,
      validDurationSecs: 31507.857,
      totalHrs: 20.27,
      validHrs: 8.75,
    },
    'ga-IE': {
      clips: 3764,
      splits: {
        accent: {
          '': 0.29,
          connachta: 0.4,
          other: 0.1,
          ulaidh: 0.09,
          mumhain: 0.12,
        },
        age: {
          twenties: 0.27,
          '': 0.23,
          thirties: 0.38,
          fourties: 0.08,
          sixties: 0.01,
          teens: 0.03,
        },
        gender: { male: 0.62, '': 0.23, female: 0.15 },
      },
      users: 63,
      duration: 12982896,
      buckets: {
        dev: 462,
        invalidated: 202,
        other: 1033,
        test: 482,
        train: 522,
        validated: 2529,
      },
      size: 98312781,
      avgDurationSecs: 3.449,
      validDurationSecs: 8723.099,
      totalHrs: 3.6,
      validHrs: 2.42,
    },
    kab: {
      clips: 192115,
      splits: {
        accent: { '': 0.79, other: 0.21 },
        age: {
          fourties: 0.13,
          thirties: 0.32,
          '': 0.32,
          twenties: 0.13,
          fifties: 0.03,
          eighties: 0,
          teens: 0,
          sixties: 0.05,
          seventies: 0,
        },
        gender: { male: 0.57, '': 0.28, female: 0.15, other: 0.01 },
      },
      users: 584,
      duration: 693953592,
      buckets: {
        dev: 6646,
        invalidated: 9717,
        other: 1816,
        test: 6648,
        train: 11073,
        validated: 180582,
      },
      size: 5395733580,
      avgDurationSecs: 3.612,
      validDurationSecs: 652294.342,
      totalHrs: 192.76,
      validHrs: 181.19,
    },
    ca: {
      clips: 95720,
      splits: {
        accent: {
          valencian: 0.06,
          central: 0.68,
          '': 0.21,
          other: 0.01,
          balearic: 0.01,
          northwestern: 0.03,
          northern: 0,
        },
        age: {
          thirties: 0.12,
          fifties: 0.27,
          fourties: 0.25,
          twenties: 0.1,
          '': 0.2,
          sixties: 0.04,
          teens: 0.02,
          seventies: 0,
          eighties: 0,
        },
        gender: { male: 0.37, '': 0.2, female: 0.43, other: 0 },
      },
      users: 1834,
      duration: 434964288,
      buckets: {
        dev: 8411,
        invalidated: 4694,
        other: 6208,
        test: 8410,
        train: 17011,
        validated: 84818,
      },
      size: 3377582268,
      avgDurationSecs: 4.544,
      validDurationSecs: 385424.164,
      totalHrs: 120.82,
      validHrs: 107.06,
    },
    'zh-TW': {
      clips: 52677,
      splits: {
        accent: {
          '': 0.65,
          other: 0.14,
          kaohsiung_city: 0.01,
          new_taipei_city: 0.05,
          yilan_county: 0.01,
          taoyuan_city: 0,
          hsinchu_city: 0.01,
          taipei_city: 0.07,
          chiayi_city: 0.01,
          taichung_city: 0.01,
          yunlin_county: 0,
          nantou_county: 0,
          tainan_city: 0.03,
          changhua_county: 0,
          pingtung_county: 0,
          hsinchu_county: 0,
        },
        age: {
          thirties: 0.32,
          twenties: 0.38,
          teens: 0.04,
          '': 0.23,
          fifties: 0.01,
          seventies: 0,
          fourties: 0.02,
        },
        gender: { male: 0.46, '': 0.23, female: 0.29, other: 0.03 },
      },
      users: 949,
      duration: 154923336,
      buckets: {
        dev: 1540,
        invalidated: 2667,
        other: 9330,
        test: 1540,
        train: 1698,
        validated: 40680,
      },
      size: 1198002255,
      avgDurationSecs: 2.941,
      validDurationSecs: 119640.095,
      totalHrs: 43.03,
      validHrs: 33.23,
    },
    sl: {
      clips: 4714,
      splits: {
        accent: { other: 0.01, '': 0.99 },
        age: {
          twenties: 0.75,
          teens: 0,
          '': 0.05,
          sixties: 0,
          fifties: 0.17,
          fourties: 0.02,
          thirties: 0.01,
        },
        gender: { female: 0.2, male: 0.75, '': 0.05 },
      },
      users: 42,
      duration: 18543912,
      buckets: {
        dev: 465,
        invalidated: 146,
        other: 2090,
        test: 339,
        train: 1178,
        validated: 2478,
      },
      size: 145009295,
      avgDurationSecs: 3.934,
      validDurationSecs: 9747.945,
      totalHrs: 5.15,
      validHrs: 2.7,
    },
    it: {
      clips: 30274,
      splits: {
        accent: { '': 0.83, other: 0.17 },
        age: {
          thirties: 0.11,
          twenties: 0.32,
          '': 0.2,
          fifties: 0.19,
          fourties: 0.1,
          seventies: 0,
          sixties: 0,
          teens: 0.06,
        },
        gender: { female: 0.18, male: 0.62, '': 0.2, other: 0 },
      },
      users: 602,
      duration: 146844648,
      buckets: {
        dev: 4563,
        invalidated: 3211,
        other: 1,
        test: 4569,
        train: 6368,
        validated: 27062,
      },
      size: 1143749798,
      avgDurationSecs: 4.851,
      validDurationSecs: 131264.777,
      totalHrs: 40.79,
      validHrs: 36.46,
    },
    nl: {
      clips: 22232,
      splits: {
        accent: { '': 0.25, netherlands: 0.65, belgium: 0.1, other: 0 },
        age: {
          '': 0.22,
          twenties: 0.28,
          fourties: 0.14,
          thirties: 0.11,
          teens: 0.04,
          fifties: 0.19,
          sixties: 0.01,
        },
        gender: { '': 0.26, male: 0.72, female: 0.02, other: 0 },
      },
      users: 502,
      duration: 84629952,
      buckets: {
        dev: 1689,
        invalidated: 954,
        other: 4188,
        test: 1689,
        train: 1881,
        validated: 17090,
      },
      size: 651528448,
      avgDurationSecs: 3.807,
      validDurationSecs: 65056.04,
      totalHrs: 23.5,
      validHrs: 18.07,
    },
    cnh: {
      clips: 4746,
      splits: {
        accent: { '': 0.82, other: 0.18 },
        age: {
          '': 0.56,
          twenties: 0.28,
          fourties: 0.01,
          teens: 0.02,
          thirties: 0.1,
          fifties: 0.03,
        },
        gender: { '': 0.56, male: 0.24, female: 0.2 },
      },
      users: 280,
      duration: 17936232,
      buckets: {
        dev: 688,
        invalidated: 463,
        other: 2034,
        test: 694,
        train: 750,
        validated: 2249,
      },
      size: 140129764,
      avgDurationSecs: 3.779,
      validDurationSecs: 8499.491,
      totalHrs: 4.98,
      validHrs: 2.36,
    },
    eo: {
      clips: 13234,
      splits: {
        accent: { '': 0.51, internacia: 0.47, other: 0.02 },
        age: {
          twenties: 0.65,
          thirties: 0.05,
          '': 0.11,
          fourties: 0.16,
          fifties: 0.01,
          seventies: 0,
          teens: 0.02,
          sixties: 0,
        },
        gender: { male: 0.51, '': 0.38, female: 0.11, other: 0 },
      },
      users: 129,
      duration: 60410136,
      buckets: {
        dev: 1896,
        invalidated: 744,
        other: 1640,
        test: 1887,
        train: 2178,
        validated: 10850,
      },
      size: 468605004,
      avgDurationSecs: 4.565,
      validDurationSecs: 49527.73,
      totalHrs: 16.78,
      validHrs: 13.75,
    },
    et: {
      clips: 6930,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.05,
          thirties: 0.11,
          twenties: 0.7,
          fourties: 0.13,
          fifties: 0,
          seventies: 0,
          teens: 0,
        },
        gender: { '': 0.05, male: 0.57, female: 0.37, other: 0 },
      },
      users: 225,
      duration: 46332792,
      buckets: {
        dev: 1769,
        invalidated: 426,
        other: 58,
        test: 1783,
        train: 2016,
        validated: 6446,
      },
      size: 358541638,
      avgDurationSecs: 6.686,
      validDurationSecs: 43096.851,
      totalHrs: 12.87,
      validHrs: 11.97,
    },
    fa: {
      clips: 56394,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.39,
          twenties: 0.31,
          thirties: 0.16,
          fifties: 0.07,
          fourties: 0.03,
          teens: 0.04,
        },
        gender: { '': 0.39, male: 0.47, female: 0.13, other: 0.01 },
      },
      users: 1240,
      duration: 253556208,
      buckets: {
        dev: 2103,
        invalidated: 2344,
        other: 0,
        test: 2103,
        train: 2409,
        validated: 54050,
      },
      size: 1940329402,
      avgDurationSecs: 4.496,
      validDurationSecs: 243017.219,
      totalHrs: 70.43,
      validHrs: 67.5,
    },
    eu: {
      clips: 58920,
      splits: {
        accent: {
          mendebalekoa: 0.23,
          erdialdekoa_nafarra: 0.35,
          '': 0.42,
          nafarlapurtarra_zuberoatarra: 0.01,
        },
        age: {
          fourties: 0.14,
          thirties: 0.05,
          fifties: 0.17,
          twenties: 0.38,
          '': 0.23,
          teens: 0.01,
          sixties: 0.01,
          seventies: 0,
        },
        gender: { male: 0.53, female: 0.22, '': 0.24, other: 0.02 },
      },
      users: 508,
      duration: 299308320,
      buckets: {
        dev: 1981,
        invalidated: 2145,
        other: 23813,
        test: 1981,
        train: 2250,
        validated: 32962,
      },
      size: 2327699622,
      avgDurationSecs: 5.08,
      validDurationSecs: 167444.006,
      totalHrs: 83.14,
      validHrs: 46.51,
    },
    es: {
      clips: 27329,
      splits: {
        accent: {
          nortepeninsular: 0.29,
          '': 0.29,
          rioplatense: 0.07,
          centrosurpeninsular: 0.04,
          americacentral: 0.01,
          surpeninsular: 0.03,
          canario: 0.02,
          andino: 0.11,
          mexicano: 0.03,
          chileno: 0.08,
          caribe: 0.01,
          filipinas: 0.01,
        },
        age: {
          thirties: 0.12,
          '': 0.18,
          fifties: 0.17,
          twenties: 0.32,
          teens: 0.02,
          fourties: 0.16,
          sixties: 0.02,
        },
        gender: { male: 0.74, '': 0.16, other: 0.01, female: 0.09 },
      },
      users: 611,
      duration: 113152080,
      buckets: {
        dev: 2723,
        invalidated: 3566,
        other: 0,
        test: 2729,
        train: 3275,
        validated: 23763,
      },
      size: 868898656,
      avgDurationSecs: 4.14,
      validDurationSecs: 98387.533,
      totalHrs: 31.43,
      validHrs: 27.32,
    },
    'zh-CN': {
      clips: 7013,
      splits: {
        accent: {
          '110000': 0.12,
          '130000': 0.01,
          '210000': 0.03,
          '220000': 0.01,
          '230000': 0.04,
          '310000': 0,
          '320000': 0.08,
          '330000': 0.07,
          '340000': 0.03,
          '350000': 0.03,
          '360000': 0.01,
          '370000': 0.03,
          '410000': 0.01,
          '420000': 0.01,
          '430000': 0.01,
          '440000': 0.03,
          '450000': 0.01,
          '500000': 0.01,
          '510000': 0.05,
          '520000': 0,
          '610000': 0.01,
          '620000': 0,
          '650000': 0.01,
          '710000': 0.02,
          '': 0.36,
        },
        age: {
          '': 0.23,
          teens: 0.11,
          twenties: 0.41,
          thirties: 0.25,
          fourties: 0,
        },
        gender: { '': 0.23, male: 0.76, female: 0 },
      },
      users: 288,
      duration: 45829032,
      buckets: {
        dev: 1954,
        invalidated: 779,
        other: 0,
        test: 1964,
        train: 2301,
        validated: 6234,
      },
      size: 359184757,
      avgDurationSecs: 6.535,
      validDurationSecs: 40738.37,
      totalHrs: 12.73,
      validHrs: 11.31,
    },
    mn: {
      clips: 6306,
      splits: {
        accent: { '': 1 },
        age: {
          thirties: 0.31,
          '': 0.31,
          twenties: 0.22,
          fourties: 0.02,
          teens: 0.02,
          nineties: 0.11,
        },
        gender: { male: 0.35, '': 0.31, female: 0.22, other: 0.11 },
      },
      users: 230,
      duration: 34457088,
      buckets: {
        dev: 1407,
        invalidated: 479,
        other: 273,
        test: 1610,
        train: 2049,
        validated: 5554,
      },
      size: 269325609,
      avgDurationSecs: 5.464,
      validDurationSecs: 30348.028,
      totalHrs: 9.57,
      validHrs: 8.43,
    },
    sah: {
      clips: 3793,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.36,
          twenties: 0.02,
          fourties: 0.07,
          thirties: 0.44,
          teens: 0.11,
          fifties: 0,
        },
        gender: { '': 0.36, male: 0.54, female: 0.1 },
      },
      users: 35,
      duration: 22638720,
      buckets: {
        dev: 0,
        invalidated: 51,
        other: 1850,
        test: 516,
        train: 1374,
        validated: 1892,
      },
      size: 173419827,
      avgDurationSecs: 5.969,
      validDurationSecs: 11292.502,
      totalHrs: 6.28,
      validHrs: 3.13,
    },
    dv: {
      clips: 5944,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.08,
          twenties: 0.29,
          thirties: 0.57,
          fourties: 0.02,
          teens: 0.04,
        },
        gender: { '': 0.08, male: 0.27, female: 0.65 },
      },
      users: 92,
      duration: 32169912,
      buckets: {
        dev: 1103,
        invalidated: 266,
        other: 1841,
        test: 1237,
        train: 1487,
        validated: 3837,
      },
      size: 247579967,
      avgDurationSecs: 5.412,
      validDurationSecs: 20766.479,
      totalHrs: 8.93,
      validHrs: 5.76,
    },
    rw: {
      clips: 1470,
      splits: {
        accent: { '': 1 },
        age: { '': 0.87, twenties: 0.12, thirties: 0.01 },
        gender: { '': 0.87, male: 0.13 },
      },
      users: 32,
      duration: 6806688,
      buckets: {
        dev: 35,
        invalidated: 31,
        other: 1325,
        test: 34,
        train: 45,
        validated: 114,
      },
      size: 53219628,
      avgDurationSecs: 4.63,
      validDurationSecs: 527.866,
      totalHrs: 1.89,
      validHrs: 0.14,
    },
    'sv-SE': {
      clips: 4113,
      splits: {
        accent: { '': 1 },
        age: {
          thirties: 0.38,
          '': 0.21,
          teens: 0.07,
          fifties: 0.26,
          twenties: 0.03,
          fourties: 0.04,
        },
        gender: { male: 0.75, '': 0.21, female: 0.03 },
      },
      users: 44,
      duration: 12524424,
      buckets: {
        dev: 1237,
        invalidated: 118,
        other: 0,
        test: 1134,
        train: 1602,
        validated: 3995,
      },
      size: 89437571,
      avgDurationSecs: 3.045,
      validDurationSecs: 12165.104,
      totalHrs: 3.47,
      validHrs: 3.37,
    },
    ru: {
      clips: 21598,
      splits: {
        accent: { '': 1 },
        age: {
          twenties: 0.73,
          teens: 0,
          '': 0.02,
          fourties: 0.15,
          thirties: 0.02,
          fifties: 0.07,
        },
        gender: { male: 0.55, '': 0.02, other: 0, female: 0.42 },
      },
      users: 64,
      duration: 111829272,
      buckets: {
        dev: 1748,
        invalidated: 784,
        other: 1559,
        test: 1676,
        train: 2113,
        validated: 19255,
      },
      size: 850506531,
      avgDurationSecs: 5.178,
      validDurationSecs: 99697.779,
      totalHrs: 31.06,
      validHrs: 27.69,
    },
  },
  totalDuration: 8836356426,
  totalValidDurationSecs: 7125701,
  totalHrs: 2454,
  totalValidHrs: 1979,
  totalClips: 2076894,
};
