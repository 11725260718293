exports.stats = {
  bundleURLTemplate: 'cv-corpus-6.1-2020-12-11/{locale}.tar.gz',
  date: '2020-12-11',
  name: 'Common Voice Corpus 6.1',
  multilingual: true,
  locales: {
    en: {
      reportedSentences: 1762,
      size: 60613063630,
      checksum:
        '0f8fdfc4fe715738be94ee49c4fb63d5f1608d2e6a43a2bed80f6cb871171c36',
      buckets: {
        dev: 16164,
        invalidated: 189562,
        other: 169895,
        reported: 1810,
        test: 16164,
        train: 564337,
        validated: 1224864,
      },
      clips: 1584321,
      splits: {
        accent: {
          '': 0.51,
          canada: 0.03,
          england: 0.08,
          us: 0.24,
          indian: 0.05,
          australia: 0.03,
          malaysia: 0,
          newzealand: 0.01,
          african: 0.01,
          ireland: 0.01,
          philippines: 0,
          singapore: 0,
          scotland: 0.02,
          hongkong: 0,
          bermuda: 0,
          southatlandtic: 0,
          wales: 0,
          other: 0.01,
        },
        age: {
          '': 0.37,
          twenties: 0.24,
          sixties: 0.04,
          thirties: 0.14,
          teens: 0.06,
          seventies: 0.01,
          fourties: 0.1,
          fifties: 0.04,
          eighties: 0,
          nineties: 0,
        },
        gender: { '': 0.36, male: 0.47, female: 0.15, other: 0.02 },
      },
      users: 66173,
      duration: 7854934999,
      avgDurationSecs: 4.958,
      validDurationSecs: 6072776.352,
      totalHrs: 2181.92,
      validHrs: 1686.88,
    },
    fa: {
      reportedSentences: 1519,
      size: 8884585819,
      checksum:
        '5454efe3b2f6d06d51e7177469b7bef9a962adbf7611e3cd21771451112abe6d',
      buckets: {
        dev: 5213,
        invalidated: 11698,
        other: 22510,
        reported: 1524,
        test: 5213,
        train: 7593,
        validated: 251659,
      },
      clips: 285867,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.21,
          twenties: 0.33,
          thirties: 0.39,
          fifties: 0.02,
          fourties: 0.03,
          teens: 0.01,
          sixties: 0,
        },
        gender: { '': 0.18, male: 0.75, female: 0.07, other: 0 },
      },
      users: 3655,
      duration: 1155788252,
      avgDurationSecs: 4.043,
      validDurationSecs: 1017481.961,
      totalHrs: 321.05,
      validHrs: 282.63,
    },
    fr: {
      reportedSentences: 3193,
      size: 19130141984,
      checksum:
        '719ef964b55d830a095a602aff311db39b77239e9d600b6af646ec2ed57e5e45',
      buckets: {
        dev: 15763,
        invalidated: 40351,
        other: 3222,
        reported: 3219,
        test: 15763,
        train: 298982,
        validated: 461004,
      },
      clips: 504577,
      splits: {
        accent: {
          '': 0.32,
          france: 0.62,
          germany: 0,
          belgium: 0.02,
          switzerland: 0.01,
          guadeloupe: 0,
          reunion: 0,
          monaco: 0,
          tunisia: 0,
          canada: 0.02,
          other: 0,
          mayotte: 0,
          algeria: 0,
          netherlands: 0,
          senegal: 0,
          martinique: 0,
          portugal: 0,
          united_states: 0,
          cote_d_ivoire: 0,
          st_pierre_et_miquelon: 0,
          french_guiana: 0,
          benin: 0,
          italy: 0,
          united_kingdom: 0,
          madagascar: 0,
          cameroon: 0,
          new_caledonia: 0,
          haiti: 0,
          morocco: 0,
          romania: 0,
          congo_kinshasa: 0,
          congo_brazzaville: 0,
          gabon: 0,
        },
        age: {
          twenties: 0.2,
          thirties: 0.19,
          '': 0.3,
          teens: 0.04,
          fourties: 0.12,
          fifties: 0.11,
          sixties: 0.04,
          seventies: 0.01,
          eighties: 0,
        },
        gender: { male: 0.65, '': 0.24, female: 0.1, other: 0.01 },
      },
      users: 12953,
      duration: 2458421254,
      avgDurationSecs: 4.872,
      validDurationSecs: 2246123.053,
      totalHrs: 682.89,
      validHrs: 623.92,
    },
    es: {
      reportedSentences: 1101,
      size: 16188844718,
      checksum:
        '276ca393783cd8b208d56b5032b87c13a40fcadde5b3925596e67c15578d0235',
      buckets: {
        dev: 15089,
        invalidated: 40640,
        other: 144791,
        reported: 1110,
        test: 15089,
        train: 161813,
        validated: 236314,
      },
      clips: 421745,
      splits: {
        accent: {
          nortepeninsular: 0.1,
          '': 0.49,
          rioplatense: 0.04,
          centrosurpeninsular: 0.03,
          americacentral: 0.02,
          surpeninsular: 0.15,
          canario: 0,
          andino: 0.05,
          mexicano: 0.06,
          chileno: 0.02,
          caribe: 0.03,
          filipinas: 0,
        },
        age: {
          thirties: 0.1,
          '': 0.32,
          fifties: 0.09,
          twenties: 0.26,
          teens: 0.03,
          fourties: 0.06,
          sixties: 0.13,
          eighties: 0,
          seventies: 0,
          nineties: 0,
        },
        gender: { male: 0.5, '': 0.32, other: 0, female: 0.18 },
      },
      users: 19484,
      duration: 2085846138,
      avgDurationSecs: 4.946,
      validDurationSecs: 1168750.416,
      totalHrs: 579.4,
      validHrs: 324.65,
    },
    sl: {
      reportedSentences: 14,
      size: 222751292,
      checksum:
        '184cfbfe876a1f1c6317e4e34680c82a940db833afca78203c2929db1768a353',
      buckets: {
        dev: 556,
        invalidated: 92,
        other: 2502,
        reported: 13,
        test: 881,
        train: 2038,
        validated: 4669,
      },
      clips: 7263,
      splits: {
        accent: { other: 0.04, '': 0.96 },
        age: {
          twenties: 0.62,
          teens: 0.11,
          '': 0.06,
          sixties: 0.03,
          fifties: 0.11,
          fourties: 0.01,
          thirties: 0.06,
        },
        gender: { female: 0.18, male: 0.76, '': 0.06, other: 0 },
      },
      users: 82,
      duration: 28545274,
      avgDurationSecs: 3.93,
      validDurationSecs: 18350.253,
      totalHrs: 7.92,
      validHrs: 5.09,
    },
    kab: {
      reportedSentences: 3183,
      size: 17171606918,
      checksum:
        'd2089107d4f3a84856c457a436a47a883b872022f2085cfad0501469be91fd95',
      buckets: {
        dev: 14622,
        invalidated: 18134,
        other: 88021,
        reported: 3202,
        test: 14622,
        train: 120530,
        validated: 573718,
      },
      clips: 679873,
      splits: {
        accent: { '': 0.9, other: 0.1 },
        age: {
          fourties: 0.1,
          thirties: 0.3,
          '': 0.26,
          fifties: 0.19,
          twenties: 0.12,
          eighties: 0,
          teens: 0,
          sixties: 0.03,
          seventies: 0,
        },
        gender: { male: 0.55, '': 0.24, female: 0.21, other: 0 },
      },
      users: 1309,
      duration: 2241648880,
      avgDurationSecs: 3.297,
      validDurationSecs: 1891639.045,
      totalHrs: 622.68,
      validHrs: 525.45,
    },
    cy: {
      reportedSentences: 127,
      size: 3434474658,
      checksum:
        '269da0cbbb2887d1903c0e17bbb71ea9bcd83506ba928fe75c660cb3e52f9a67',
      buckets: {
        dev: 4776,
        invalidated: 3648,
        other: 17919,
        reported: 126,
        test: 4820,
        train: 6839,
        validated: 72984,
      },
      clips: 94551,
      splits: {
        accent: { united_kingdom: 0.52, '': 0.47, other: 0.01 },
        age: {
          fourties: 0.17,
          twenties: 0.14,
          sixties: 0.07,
          fifties: 0.09,
          '': 0.41,
          thirties: 0.1,
          seventies: 0.01,
          eighties: 0,
          teens: 0.02,
        },
        gender: { male: 0.34, female: 0.25, '': 0.4, other: 0.01 },
      },
      users: 1382,
      duration: 447561765,
      avgDurationSecs: 4.734,
      validDurationSecs: 345473.32,
      totalHrs: 124.32,
      validHrs: 95.96,
    },
    ca: {
      reportedSentences: 1192,
      size: 20743110341,
      checksum:
        'a27bec66c151ddb21c1736781b3bca972047cc20c02488bad94d2311c40bc6da',
      buckets: {
        dev: 15724,
        invalidated: 18846,
        other: 64446,
        reported: 1195,
        test: 15724,
        train: 285584,
        validated: 416701,
      },
      clips: 499993,
      splits: {
        accent: {
          valencian: 0.05,
          central: 0.67,
          '': 0.22,
          other: 0,
          balearic: 0.01,
          northwestern: 0.03,
          northern: 0.01,
        },
        age: {
          thirties: 0.13,
          fifties: 0.23,
          fourties: 0.16,
          twenties: 0.08,
          '': 0.17,
          sixties: 0.22,
          teens: 0.01,
          seventies: 0.01,
          eighties: 0,
          nineties: 0,
        },
        gender: { male: 0.58, '': 0.18, female: 0.24, other: 0 },
      },
      users: 5376,
      duration: 2693148330,
      avgDurationSecs: 5.386,
      validDurationSecs: 2244506.628,
      totalHrs: 748.09,
      validHrs: 623.47,
    },
    de: {
      reportedSentences: 3748,
      size: 23283812097,
      checksum:
        '733e6e367da4b9588b4bb175ac45c6c0ec545e41df5494a7ee4a7e4ff3141ef7',
      buckets: {
        dev: 15588,
        invalidated: 32789,
        other: 10095,
        reported: 3768,
        test: 15588,
        train: 246525,
        validated: 565186,
      },
      clips: 608070,
      splits: {
        accent: {
          russia: 0,
          germany: 0.65,
          '': 0.31,
          austria: 0.03,
          liechtenstein: 0,
          switzerland: 0.01,
          france: 0,
          other: 0,
          poland: 0,
          united_kingdom: 0,
          hungary: 0,
          netherlands: 0,
          namibia: 0,
          slovakia: 0,
          united_states: 0,
          italy: 0,
          czechia: 0,
          brazil: 0,
          canada: 0,
          turkey: 0,
          slovenia: 0,
          luxembourg: 0,
          belgium: 0,
          denmark: 0,
          greece: 0,
          lithuania: 0,
        },
        age: {
          twenties: 0.22,
          fourties: 0.2,
          '': 0.23,
          thirties: 0.16,
          teens: 0.03,
          sixties: 0.03,
          fifties: 0.11,
          seventies: 0,
          eighties: 0,
          nineties: 0,
        },
        gender: { male: 0.67, '': 0.23, female: 0.09, other: 0.01 },
      },
      users: 12659,
      duration: 3010229669,
      avgDurationSecs: 4.95,
      validDurationSecs: 2797933.899,
      totalHrs: 836.17,
      validHrs: 777.2,
    },
    tt: {
      reportedSentences: 3,
      size: 777153207,
      checksum:
        '89c8d7a49584de720f1790df39e6f07996e2eecb07f6273f4ba2668e9fe4ad46',
      buckets: {
        dev: 2127,
        invalidated: 287,
        other: 1798,
        reported: 2,
        test: 4485,
        train: 11211,
        validated: 25781,
      },
      clips: 27866,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.18,
          thirties: 0.75,
          twenties: 0.05,
          sixties: 0,
          fifties: 0.01,
          teens: 0,
          fourties: 0,
          seventies: 0.01,
        },
        gender: { '': 0.18, male: 0.8, female: 0.02 },
      },
      users: 185,
      duration: 103646870,
      avgDurationSecs: 3.719,
      validDurationSecs: 95891.766,
      totalHrs: 28.79,
      validHrs: 26.63,
    },
    ta: {
      reportedSentences: 257,
      size: 679766097,
      checksum:
        '78560d9d608a63ee75c3fdeb7f96f33cf0d85855ba6294b13e945de066eb46d8',
      buckets: {
        dev: 1779,
        invalidated: 594,
        other: 7428,
        reported: 257,
        test: 1781,
        train: 2009,
        validated: 12652,
      },
      clips: 20674,
      splits: {
        accent: { '': 1 },
        age: {
          twenties: 0.45,
          thirties: 0.41,
          '': 0.1,
          fourties: 0.01,
          seventies: 0,
          fifties: 0.01,
          teens: 0.01,
          sixties: 0,
        },
        gender: { male: 0.72, '': 0.11, other: 0.01, female: 0.17 },
      },
      users: 266,
      duration: 88153080,
      avgDurationSecs: 4.264,
      validDurationSecs: 53947.604,
      totalHrs: 24.48,
      validHrs: 14.98,
    },
    ru: {
      reportedSentences: 137,
      size: 3655676916,
      checksum:
        'dcbb460e58d4afc78047c3801c9eb56d940b388eb350ee3da3de5bfe5a74a025',
      buckets: {
        dev: 7963,
        invalidated: 3056,
        other: 10247,
        reported: 143,
        test: 8007,
        train: 15481,
        validated: 74256,
      },
      clips: 87559,
      splits: {
        accent: { '': 1 },
        age: {
          twenties: 0.46,
          teens: 0.08,
          '': 0.11,
          fourties: 0.16,
          thirties: 0.13,
          fifties: 0.05,
          sixties: 0,
          seventies: 0,
        },
        gender: { male: 0.71, '': 0.11, other: 0, female: 0.18 },
      },
      users: 1412,
      duration: 471429144,
      avgDurationSecs: 5.384,
      validDurationSecs: 399804.047,
      totalHrs: 130.95,
      validHrs: 111.05,
    },
    nl: {
      reportedSentences: 215,
      size: 1741827548,
      checksum:
        '048f823408e3bbd16e63111d1b4caecb0102606c440bbdf3e5b6a6bae1e1e3f1',
      buckets: {
        dev: 4938,
        invalidated: 3308,
        other: 27,
        reported: 215,
        test: 5708,
        train: 9460,
        validated: 52488,
      },
      clips: 55823,
      splits: {
        accent: {
          '': 0.26,
          netherlands: 0.61,
          belgium: 0.11,
          other: 0,
          germany: 0,
          france: 0,
          suriname: 0.01,
          curacao: 0,
        },
        age: {
          '': 0.29,
          twenties: 0.24,
          fourties: 0.18,
          thirties: 0.14,
          teens: 0.04,
          fifties: 0.1,
          sixties: 0.01,
        },
        gender: { '': 0.3, male: 0.58, female: 0.12, other: 0 },
      },
      users: 1012,
      duration: 227719441,
      avgDurationSecs: 4.079,
      validDurationSecs: 214114.935,
      totalHrs: 63.25,
      validHrs: 59.47,
    },
    it: {
      reportedSentences: 1046,
      size: 5585781573,
      checksum:
        '3a75b1631958af1487ee49b13cd27efc951183737ed515832cf714ed20c97808',
      buckets: {
        dev: 12928,
        invalidated: 12189,
        other: 14549,
        reported: 1052,
        test: 12928,
        train: 58015,
        validated: 102579,
      },
      clips: 129317,
      splits: {
        accent: { '': 0.95, other: 0.05 },
        age: {
          thirties: 0.19,
          twenties: 0.23,
          '': 0.31,
          fifties: 0.09,
          fourties: 0.14,
          seventies: 0.01,
          sixties: 0.03,
          teens: 0.02,
          eighties: 0,
          nineties: 0,
        },
        gender: { female: 0.14, male: 0.54, '': 0.31, other: 0.01 },
      },
      users: 5729,
      duration: 718680432,
      avgDurationSecs: 5.558,
      validDurationSecs: 570083.748,
      totalHrs: 199.63,
      validHrs: 158.35,
    },
    eu: {
      reportedSentences: 39,
      size: 3664586106,
      checksum:
        '55b6eaf7ca7c120faa0b60d71c87189b610412334e6b710fe12c2a79489ab06f',
      buckets: {
        dev: 5172,
        invalidated: 5387,
        other: 23570,
        reported: 38,
        test: 5172,
        train: 7505,
        validated: 63009,
      },
      clips: 91966,
      splits: {
        accent: {
          mendebalekoa: 0.31,
          erdialdekoa_nafarra: 0.3,
          '': 0.39,
          nafarlapurtarra_zuberoatarra: 0.01,
        },
        age: {
          fourties: 0.14,
          thirties: 0.08,
          fifties: 0.14,
          twenties: 0.38,
          '': 0.22,
          teens: 0.03,
          sixties: 0.01,
          seventies: 0,
        },
        gender: { male: 0.5, female: 0.25, '': 0.23, other: 0.02 },
      },
      users: 1028,
      duration: 472668999,
      avgDurationSecs: 5.14,
      validDurationSecs: 323841.43,
      totalHrs: 131.29,
      validHrs: 89.95,
    },
    tr: {
      reportedSentences: 73,
      size: 620848700,
      checksum:
        'b3f266c868b1fe9f76270ba76226b1cdc17f33b3e387e6b44a64d5419f8b9768',
      buckets: {
        dev: 1647,
        invalidated: 1726,
        other: 325,
        reported: 72,
        test: 1647,
        train: 1831,
        validated: 18685,
      },
      clips: 20736,
      splits: {
        accent: { '': 0.96, other: 0.04 },
        age: {
          '': 0.23,
          thirties: 0.23,
          twenties: 0.47,
          teens: 0.04,
          fourties: 0.03,
          fifties: 0.01,
          sixties: 0,
        },
        gender: { '': 0.23, male: 0.71, female: 0.06, other: 0 },
      },
      users: 678,
      duration: 80991855,
      avgDurationSecs: 3.906,
      validDurationSecs: 72980.942,
      totalHrs: 22.49,
      validHrs: 20.27,
    },
    ar: {
      reportedSentences: 1797,
      size: 1756264615,
      checksum:
        '516b369da8a000c1b98d8f5ee3b90fa12bcc5d5438391fcf01f3d5e78ccdd6fa',
      buckets: {
        dev: 7517,
        invalidated: 6333,
        other: 18283,
        reported: 1810,
        test: 7622,
        train: 14227,
        validated: 43291,
      },
      clips: 67907,
      splits: {
        accent: { '': 1 },
        age: {
          thirties: 0.11,
          '': 0.49,
          twenties: 0.34,
          fourties: 0.01,
          teens: 0.05,
          fifties: 0,
        },
        gender: { female: 0.21, '': 0.48, male: 0.31, other: 0 },
      },
      users: 672,
      duration: 278490582,
      avgDurationSecs: 4.101,
      validDurationSecs: 177538.925,
      totalHrs: 77.35,
      validHrs: 49.31,
    },
    'zh-TW': {
      reportedSentences: 39,
      size: 2182836295,
      checksum:
        '67fadf561f8237690d4a4a1d63a9b3ac271b5d05438dc745b7e04282d909460f',
      buckets: {
        dev: 2895,
        invalidated: 3584,
        other: 22477,
        reported: 38,
        test: 2895,
        train: 3507,
        validated: 61232,
      },
      clips: 87293,
      splits: {
        accent: {
          '': 0.51,
          taipei_city: 0.16,
          other: 0.09,
          kaohsiung_city: 0.03,
          new_taipei_city: 0.05,
          tainan_city: 0.04,
          taichung_city: 0.03,
          yilan_county: 0.01,
          hong_kong: 0.01,
          taoyuan_city: 0.01,
          hsinchu_city: 0.02,
          chiayi_city: 0,
          yunlin_county: 0.02,
          nantou_county: 0,
          changhua_county: 0.01,
          pingtung_county: 0.01,
          hsinchu_county: 0,
          chiayi_county: 0,
          keelung_city: 0,
          hualien_county: 0.01,
          kinmen_county: 0,
        },
        age: {
          thirties: 0.24,
          twenties: 0.36,
          teens: 0.04,
          '': 0.23,
          fifties: 0.01,
          seventies: 0,
          fourties: 0.12,
          sixties: 0,
        },
        gender: { male: 0.49, '': 0.22, female: 0.27, other: 0.03 },
      },
      users: 1444,
      duration: 282305146,
      avgDurationSecs: 3.234,
      validDurationSecs: 198023.996,
      totalHrs: 78.41,
      validHrs: 55,
    },
    br: {
      reportedSentences: 167,
      size: 465276982,
      checksum:
        'd323d71337055b794c8fe3dcdf5a0dc03d6bf8f7c8c19f96369884410aef4606',
      buckets: {
        dev: 1997,
        invalidated: 623,
        other: 10912,
        reported: 166,
        test: 2087,
        train: 2780,
        validated: 8560,
      },
      clips: 20095,
      splits: {
        accent: {
          other: 0,
          '': 0.74,
          kerneveg: 0.2,
          gwenedeg: 0.05,
          leoneg: 0,
          tregerieg: 0,
        },
        age: {
          twenties: 0.17,
          '': 0.37,
          fifties: 0.06,
          fourties: 0.08,
          thirties: 0.08,
          sixties: 0.2,
          seventies: 0.03,
          teens: 0.01,
        },
        gender: { male: 0.6, '': 0.38, female: 0.02 },
      },
      users: 157,
      duration: 60895123,
      avgDurationSecs: 3.03,
      validDurationSecs: 25939.898,
      totalHrs: 16.91,
      validHrs: 7.2,
    },
    pt: {
      reportedSentences: 1697,
      size: 1704252567,
      checksum:
        '6700de499f728e0e3f3ed4d7005e5b7db27ba2ddc872b21b0b404c3b4859d84b',
      buckets: {
        dev: 4592,
        invalidated: 1740,
        other: 8390,
        reported: 1701,
        test: 4641,
        train: 6514,
        validated: 41584,
      },
      clips: 51714,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.14,
          twenties: 0.35,
          teens: 0.03,
          thirties: 0.32,
          fourties: 0.13,
          sixties: 0,
          fifties: 0.04,
          seventies: 0,
        },
        gender: { '': 0.14, male: 0.81, female: 0.03, other: 0.02 },
      },
      users: 1120,
      duration: 226986379,
      avgDurationSecs: 4.389,
      validDurationSecs: 182523.138,
      totalHrs: 63.05,
      validHrs: 50.7,
    },
    eo: {
      reportedSentences: 404,
      size: 2883560869,
      checksum:
        'c19900010aee0f9eb39416406598509b1cdba136a16318e746b1a64f97d7809c',
      buckets: {
        dev: 8987,
        invalidated: 4736,
        other: 2946,
        reported: 404,
        test: 8969,
        train: 19587,
        validated: 58094,
      },
      clips: 65776,
      splits: {
        accent: { '': 0.29, internacia: 0.7, other: 0 },
        age: {
          twenties: 0.32,
          thirties: 0.11,
          '': 0.12,
          fourties: 0.31,
          fifties: 0.03,
          seventies: 0.01,
          teens: 0.03,
          sixties: 0.02,
          eighties: 0.06,
        },
        gender: { male: 0.74, '': 0.17, female: 0.08, other: 0 },
      },
      users: 574,
      duration: 370020067,
      avgDurationSecs: 5.625,
      validDurationSecs: 326805.305,
      totalHrs: 102.78,
      validHrs: 90.77,
    },
    'zh-CN': {
      reportedSentences: 280,
      size: 2184602350,
      checksum:
        'cd8589cac28541f9f996d1954f14c307954f1146ac44a8eadad8e31ebaf1f15e',
      buckets: {
        dev: 8743,
        invalidated: 5305,
        other: 8948,
        reported: 280,
        test: 8760,
        train: 18541,
        validated: 36405,
      },
      clips: 50658,
      splits: {
        accent: {
          '110000': 0.04,
          '120000': 0,
          '130000': 0.02,
          '140000': 0.01,
          '150000': 0,
          '210000': 0.01,
          '220000': 0,
          '230000': 0.04,
          '310000': 0.05,
          '320000': 0.05,
          '330000': 0.03,
          '340000': 0.02,
          '350000': 0.01,
          '360000': 0.02,
          '370000': 0.03,
          '410000': 0.04,
          '420000': 0.02,
          '430000': 0.01,
          '440000': 0.03,
          '450000': 0.02,
          '460000': 0.01,
          '500000': 0.02,
          '510000': 0.03,
          '520000': 0,
          '530000': 0,
          '610000': 0.01,
          '620000': 0,
          '640000': 0,
          '650000': 0,
          '710000': 0,
          '810000': 0,
          '': 0.45,
        },
        age: {
          '': 0.38,
          teens: 0.08,
          twenties: 0.38,
          thirties: 0.13,
          fourties: 0.03,
          nineties: 0,
          fifties: 0,
          sixties: 0,
        },
        gender: { '': 0.37, male: 0.53, female: 0.09, other: 0.01 },
      },
      users: 3501,
      duration: 281080968,
      avgDurationSecs: 5.549,
      validDurationSecs: 201996.775,
      totalHrs: 78.07,
      validHrs: 56.11,
    },
    id: {
      reportedSentences: 201,
      size: 475918233,
      checksum:
        '71177fa9d2fac29f48db5feabc294f1d6bbcaa0c326b0d1099be66c0b804b245',
      buckets: {
        dev: 1835,
        invalidated: 470,
        other: 6782,
        reported: 200,
        test: 1844,
        train: 2130,
        validated: 8696,
      },
      clips: 15948,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.09,
          twenties: 0.42,
          thirties: 0.08,
          teens: 0.36,
          fifties: 0.01,
          fourties: 0.04,
        },
        gender: { '': 0.09, male: 0.62, female: 0.18, other: 0.11 },
      },
      users: 219,
      duration: 61766436,
      avgDurationSecs: 3.873,
      validDurationSecs: 33679.516,
      totalHrs: 17.15,
      validHrs: 9.35,
    },
    ia: {
      reportedSentences: 236,
      size: 226499645,
      checksum:
        '47a137a805ea8ce01f2cf9277739919a824a9fd13468345dfbd84eddb52c02f1',
      buckets: {
        dev: 1601,
        invalidated: 192,
        other: 1095,
        reported: 240,
        test: 899,
        train: 3477,
        validated: 5978,
      },
      clips: 7265,
      splits: {
        accent: { '': 1 },
        age: {
          seventies: 0.24,
          fourties: 0.58,
          '': 0.06,
          twenties: 0.07,
          thirties: 0.03,
          teens: 0,
          fifties: 0.01,
          sixties: 0,
        },
        gender: { male: 0.93, '': 0.06, female: 0.01 },
      },
      users: 36,
      duration: 29112996,
      avgDurationSecs: 4.007,
      validDurationSecs: 23955.608,
      totalHrs: 8.08,
      validHrs: 6.65,
    },
    lv: {
      reportedSentences: 21,
      size: 208307691,
      checksum:
        '8a4350ccf24884ee1012032bfd5a87e0de50d780b1f8450d1cb52afe3f69c671',
      buckets: {
        dev: 2002,
        invalidated: 143,
        other: 1560,
        reported: 20,
        test: 1882,
        train: 2552,
        validated: 6444,
      },
      clips: 8147,
      splits: {
        accent: { '': 1 },
        age: {
          thirties: 0.48,
          fourties: 0.03,
          '': 0.16,
          twenties: 0.29,
          teens: 0.03,
          fifties: 0,
        },
        gender: { male: 0.7, female: 0.13, '': 0.16 },
      },
      users: 99,
      duration: 27668465,
      avgDurationSecs: 3.396,
      validDurationSecs: 21884.815,
      totalHrs: 7.68,
      validHrs: 6.07,
    },
    ja: {
      reportedSentences: 44,
      size: 152879796,
      checksum:
        '3614cd0d0abac80794351c78183967c83179fab390d7e19cad97758eb85ae558',
      buckets: {
        dev: 586,
        invalidated: 504,
        other: 885,
        reported: 43,
        test: 632,
        train: 722,
        validated: 3072,
      },
      clips: 4461,
      splits: {
        accent: { '': 1 },
        age: {
          twenties: 0.51,
          '': 0.3,
          teens: 0.04,
          fifties: 0.02,
          thirties: 0.06,
          fourties: 0.06,
          sixties: 0,
        },
        gender: { male: 0.72, '': 0.14, female: 0.14, other: 0 },
      },
      users: 235,
      duration: 19732425,
      avgDurationSecs: 4.423,
      validDurationSecs: 13588.435,
      totalHrs: 5.48,
      validHrs: 3.77,
    },
    rw: {
      reportedSentences: 619,
      size: 42545189583,
      checksum:
        'cf8a07059b3713022d487f9a6b8f465271f3457c525a8b350f829f87b0132b41',
      buckets: {
        dev: 15032,
        invalidated: 206790,
        other: 22923,
        reported: 618,
        test: 15724,
        train: 515197,
        validated: 832929,
      },
      clips: 1062642,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.02,
          twenties: 0.63,
          thirties: 0.11,
          teens: 0.24,
          fourties: 0,
        },
        gender: { '': 0.09, male: 0.6, female: 0.31, other: 0 },
      },
      users: 410,
      duration: 5436192485,
      avgDurationSecs: 5.116,
      validDurationSecs: 4261042.167,
      totalHrs: 1510.05,
      validHrs: 1183.62,
    },
    'sv-SE': {
      reportedSentences: 152,
      size: 421434184,
      checksum:
        'dc8634dafacb33be00f06e376f6c479d53f84f4834952593c8903f1080535213',
      buckets: {
        dev: 2019,
        invalidated: 462,
        other: 3043,
        reported: 151,
        test: 2027,
        train: 2331,
        validated: 12552,
      },
      clips: 16057,
      splits: {
        accent: { '': 1 },
        age: {
          thirties: 0.28,
          '': 0.16,
          teens: 0.07,
          fifties: 0.09,
          twenties: 0.2,
          fourties: 0.22,
          sixties: 0,
        },
        gender: { male: 0.61, '': 0.16, female: 0.22, other: 0.01 },
      },
      users: 222,
      duration: 55571595,
      avgDurationSecs: 3.461,
      validDurationSecs: 43441.157,
      totalHrs: 15.43,
      validHrs: 12.06,
    },
    cnh: {
      reportedSentences: 9,
      size: 161331331,
      checksum:
        '9c27ce17ea8db73e7a2c8715bdb3a45a40792d6d64238cfbb467a81c6b71d71f',
      buckets: {
        dev: 756,
        invalidated: 433,
        other: 2934,
        reported: 8,
        test: 752,
        train: 807,
        validated: 2432,
      },
      clips: 5799,
      splits: {
        accent: { '': 0.85, other: 0.15 },
        age: {
          '': 0.51,
          twenties: 0.36,
          fourties: 0.01,
          teens: 0.02,
          thirties: 0.08,
          fifties: 0.02,
        },
        gender: { '': 0.51, male: 0.33, female: 0.16 },
      },
      users: 297,
      duration: 20663664,
      avgDurationSecs: 3.563,
      validDurationSecs: 8665.982,
      totalHrs: 5.73,
      validHrs: 2.4,
    },
    et: {
      reportedSentences: 108,
      size: 767174465,
      checksum:
        '50a861393e4e7013ab71f1b63bca8c42c26dca1519c15a3b9cdb3cb5b6c561a2',
      buckets: {
        dev: 2507,
        invalidated: 3557,
        other: 569,
        reported: 107,
        test: 2509,
        train: 2966,
        validated: 10683,
      },
      clips: 14809,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.15,
          thirties: 0.07,
          twenties: 0.71,
          fourties: 0.07,
          fifties: 0.01,
          seventies: 0,
          teens: 0,
        },
        gender: { '': 0.16, male: 0.6, female: 0.24, other: 0 },
      },
      users: 543,
      duration: 98800166,
      avgDurationSecs: 6.672,
      validDurationSecs: 71273.021,
      totalHrs: 27.44,
      validHrs: 19.79,
    },
    ky: {
      reportedSentences: 1,
      size: 579440853,
      checksum:
        '6efe0ca5384d0419fcf5fda0e0229a1b5eb80d8eeba2d7528a4c3c9f2593206f',
      buckets: {
        dev: 1511,
        invalidated: 926,
        other: 7223,
        reported: 0,
        test: 1503,
        train: 1955,
        validated: 9236,
      },
      clips: 17385,
      splits: {
        accent: { '': 0.92, other: 0.08 },
        age: {
          thirties: 0.13,
          '': 0.06,
          fourties: 0.02,
          twenties: 0.78,
          teens: 0.01,
        },
        gender: { male: 0.46, '': 0.11, female: 0.43, other: 0 },
      },
      users: 134,
      duration: 80915736,
      avgDurationSecs: 4.654,
      validDurationSecs: 42987.503,
      totalHrs: 22.47,
      validHrs: 11.94,
    },
    ro: {
      reportedSentences: 60,
      size: 261978702,
      checksum:
        '450b159e936ef6ff136fcdfad193675caec5b2230d1b6ca24c5cde491ff002cd',
      buckets: {
        dev: 858,
        invalidated: 485,
        other: 1945,
        reported: 59,
        test: 1778,
        train: 3399,
        validated: 6039,
      },
      clips: 8469,
      splits: {
        accent: { '': 1 },
        age: {
          thirties: 0.51,
          teens: 0.05,
          '': 0.07,
          fourties: 0.08,
          sixties: 0.01,
          twenties: 0.27,
          fifties: 0,
        },
        gender: { male: 0.72, '': 0.07, female: 0.2, other: 0 },
      },
      users: 130,
      duration: 34024999,
      avgDurationSecs: 4.018,
      validDurationSecs: 24262.247,
      totalHrs: 9.45,
      validHrs: 6.73,
    },
    hsb: {
      reportedSentences: 57,
      size: 79362060,
      checksum:
        '3dd3d79aaa078ad7955552ebc596e0a8894ffd7a4a88a51b2c8ee80c0e088152',
      buckets: {
        dev: 172,
        invalidated: 227,
        other: 62,
        reported: 56,
        test: 387,
        train: 808,
        validated: 1367,
      },
      clips: 1656,
      splits: {
        accent: { '': 1 },
        age: {
          fourties: 0.55,
          '': 0.17,
          thirties: 0.11,
          sixties: 0,
          seventies: 0.03,
          twenties: 0.11,
          fifties: 0.03,
        },
        gender: { male: 0.83, '': 0.17, other: 0 },
      },
      users: 19,
      duration: 10103328,
      avgDurationSecs: 6.101,
      validDurationSecs: 8340.126,
      totalHrs: 2.8,
      validHrs: 2.31,
    },
    el: {
      reportedSentences: 19,
      size: 381570611,
      checksum:
        '86c67e7bda7658a7087b5a1997d140d57957a05bb413a188610db61807c53ee4',
      buckets: {
        dev: 1401,
        invalidated: 185,
        other: 5659,
        reported: 18,
        test: 1522,
        train: 2316,
        validated: 5996,
      },
      clips: 11840,
      splits: {
        accent: { '': 1 },
        age: {
          thirties: 0.64,
          fourties: 0.19,
          '': 0.14,
          twenties: 0.02,
          fifties: 0.01,
          teens: 0,
        },
        gender: { male: 0.85, '': 0.14, other: 0.01, female: 0 },
      },
      users: 118,
      duration: 49105618,
      avgDurationSecs: 4.147,
      validDurationSecs: 24868.014,
      totalHrs: 13.64,
      validHrs: 6.9,
    },
    cs: {
      reportedSentences: 582,
      size: 1271909933,
      checksum:
        '68a1d6f27eb7161fdf28da889e7d37e8c86b7aff73b0b6df52edc8359e30ac56',
      buckets: {
        dev: 4118,
        invalidated: 685,
        other: 7475,
        reported: 585,
        test: 4144,
        train: 5655,
        validated: 30431,
      },
      clips: 38591,
      splits: {
        accent: { '': 1 },
        age: {
          fourties: 0.26,
          '': 0.3,
          thirties: 0.14,
          teens: 0.01,
          twenties: 0.27,
          fifties: 0.02,
          sixties: 0.01,
        },
        gender: { male: 0.69, '': 0.29, female: 0.02 },
      },
      users: 353,
      duration: 164383635,
      avgDurationSecs: 4.26,
      validDurationSecs: 129625.001,
      totalHrs: 45.66,
      validHrs: 36,
    },
    pl: {
      reportedSentences: 431,
      size: 3537012341,
      checksum:
        'acbf77d36e083e2bcb7152ffb52ab7d1e3e64d33a3f51f106cdff7feff6279aa',
      buckets: {
        dev: 5153,
        invalidated: 4601,
        other: 12848,
        reported: 431,
        test: 5153,
        train: 7468,
        validated: 90791,
      },
      clips: 108240,
      splits: {
        accent: { '': 1 },
        age: {
          twenties: 0.27,
          '': 0.24,
          teens: 0.02,
          thirties: 0.35,
          fourties: 0.12,
          fifties: 0,
          nineties: 0.01,
          sixties: 0,
        },
        gender: { male: 0.6, '': 0.24, female: 0.15, other: 0 },
      },
      users: 2647,
      duration: 466550750,
      avgDurationSecs: 4.31,
      validDurationSecs: 391339.7,
      totalHrs: 129.59,
      validHrs: 108.7,
    },
    'rm-sursilv': {
      reportedSentences: 10,
      size: 275950479,
      checksum:
        '3cfc4971b6ab8958d7c3d784977690fcc04ebd7570ecf788d5948df84a5481a1',
      buckets: {
        dev: 1205,
        invalidated: 639,
        other: 2102,
        reported: 9,
        test: 1194,
        train: 1384,
        validated: 3783,
      },
      clips: 6524,
      splits: {
        accent: { '': 1 },
        age: {
          thirties: 0.02,
          twenties: 0.11,
          '': 0.64,
          teens: 0.06,
          fourties: 0.17,
        },
        gender: { male: 0.15, female: 0.2, '': 0.64, other: 0 },
      },
      users: 78,
      duration: 35885741,
      avgDurationSecs: 5.501,
      validDurationSecs: 20808.669,
      totalHrs: 9.96,
      validHrs: 5.78,
    },
    'rm-vallader': {
      reportedSentences: 20,
      size: 108113989,
      checksum:
        '4fdb7dc5e20862a636ee7975831b39db29012d615f9139edf2d266b878ce43ae',
      buckets: {
        dev: 357,
        invalidated: 374,
        other: 727,
        reported: 21,
        test: 378,
        train: 574,
        validated: 1316,
      },
      clips: 2417,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.35,
          fourties: 0.44,
          twenties: 0.15,
          thirties: 0.06,
          fifties: 0,
        },
        gender: { '': 0.35, male: 0.44, female: 0.21, other: 0.01 },
      },
      users: 39,
      duration: 13869698,
      avgDurationSecs: 5.738,
      validDurationSecs: 7551.726,
      totalHrs: 3.85,
      validHrs: 2.09,
    },
    mn: {
      reportedSentences: 3,
      size: 486369317,
      checksum:
        '3aebc40d40eb19263576664a981f4bb8b221abeab78c8154adc3d16875c75ec7',
      buckets: {
        dev: 1837,
        invalidated: 667,
        other: 3272,
        reported: 2,
        test: 1862,
        train: 2183,
        validated: 7487,
      },
      clips: 11426,
      splits: {
        accent: { '': 1 },
        age: {
          thirties: 0.23,
          '': 0.25,
          twenties: 0.42,
          fourties: 0.01,
          teens: 0.02,
          nineties: 0.06,
        },
        gender: { male: 0.35, '': 0.25, female: 0.34, other: 0.06 },
      },
      users: 376,
      duration: 62534240,
      avgDurationSecs: 5.473,
      validDurationSecs: 40976.182,
      totalHrs: 17.37,
      validHrs: 11.38,
    },
    'zh-HK': {
      reportedSentences: 560,
      size: 2774145806,
      checksum:
        '8a525ce4664d6647701449d5e72f7d8658cc3a5fabc72e05c6883994fd3c0134',
      buckets: {
        dev: 5172,
        invalidated: 2999,
        other: 38830,
        reported: 571,
        test: 5172,
        train: 7506,
        validated: 41835,
      },
      clips: 83664,
      splits: {
        accent: { '': 1 },
        age: {
          fourties: 0.15,
          thirties: 0.11,
          '': 0.4,
          teens: 0.02,
          fifties: 0.03,
          seventies: 0,
          sixties: 0,
          twenties: 0.29,
        },
        gender: { male: 0.4, '': 0.34, female: 0.24, other: 0.01 },
      },
      users: 2536,
      duration: 361968900,
      avgDurationSecs: 4.326,
      validDurationSecs: 180997.429,
      totalHrs: 100.54,
      validHrs: 50.27,
    },
    ab: {
      reportedSentences: 43,
      size: 41038412,
      checksum:
        '801de9c63f740c4d2c821709586921bed216c736e593051306579cf478a54388',
      buckets: {
        dev: 0,
        invalidated: 8,
        other: 752,
        reported: 42,
        test: 9,
        train: 22,
        validated: 31,
      },
      clips: 791,
      splits: {
        accent: { '': 1 },
        age: { seventies: 0.63, thirties: 0.29, '': 0.08, teens: 0.01 },
        gender: { male: 0.64, female: 0.29, '': 0.08 },
      },
      users: 14,
      duration: 5216688,
      avgDurationSecs: 6.595,
      validDurationSecs: 204.447,
      totalHrs: 1.44,
      validHrs: 0.05,
    },
    cv: {
      reportedSentences: 75,
      size: 439329081,
      checksum:
        'c3fb84c28a5718f01b91cf1026985b1dcd83bb312d32620f16b5ed4f12fb8c73',
      buckets: {
        dev: 818,
        invalidated: 1282,
        other: 6927,
        reported: 74,
        test: 788,
        train: 931,
        validated: 3496,
      },
      clips: 11705,
      splits: {
        accent: { other: 0, '': 1 },
        age: {
          twenties: 0.48,
          '': 0.27,
          fourties: 0.09,
          thirties: 0.01,
          teens: 0.13,
          fifties: 0.02,
        },
        gender: { male: 0.45, '': 0.26, female: 0.3 },
      },
      users: 92,
      duration: 58562220,
      avgDurationSecs: 5.003,
      validDurationSecs: 17491.117,
      totalHrs: 16.26,
      validHrs: 4.85,
    },
    uk: {
      reportedSentences: 323,
      size: 1218559031,
      checksum:
        'f3ca0143cd84f5eacb583187052e69efec21c571a426efee91a765a2284519c2',
      buckets: {
        dev: 3236,
        invalidated: 1255,
        other: 8161,
        reported: 323,
        test: 3235,
        train: 4035,
        validated: 22337,
      },
      clips: 31753,
      splits: {
        accent: { '': 1 },
        age: {
          twenties: 0.27,
          teens: 0.07,
          '': 0.19,
          fourties: 0.22,
          thirties: 0.24,
          fifties: 0.01,
          sixties: 0,
        },
        gender: { male: 0.59, female: 0.22, '': 0.19 },
      },
      users: 459,
      duration: 158034012,
      avgDurationSecs: 4.977,
      validDurationSecs: 111170.778,
      totalHrs: 43.89,
      validHrs: 30.88,
    },
    mt: {
      reportedSentences: 5,
      size: 425114242,
      checksum:
        '9d53000d7832d130c4d35fb412bfc092ab8de8e763a5d2a528aebf37f052af03',
      buckets: {
        dev: 1516,
        invalidated: 314,
        other: 5714,
        reported: 4,
        test: 1617,
        train: 2036,
        validated: 5747,
      },
      clips: 11775,
      splits: {
        accent: { '': 1 },
        age: {
          twenties: 0.17,
          '': 0.25,
          fourties: 0.18,
          thirties: 0.08,
          teens: 0.03,
          fifties: 0.28,
          sixties: 0.01,
        },
        gender: { male: 0.26, '': 0.25, female: 0.48, other: 0.01 },
      },
      users: 171,
      duration: 55832112,
      avgDurationSecs: 4.742,
      validDurationSecs: 27249.864,
      totalHrs: 15.5,
      validHrs: 7.56,
    },
    as: {
      reportedSentences: 8,
      size: 22226465,
      checksum:
        'd9afd6d28e9c837ff0943a94452fb12ce8a7885b38fdeb25fc2912bbe4977f40',
      buckets: {
        dev: 124,
        invalidated: 31,
        other: 0,
        reported: 7,
        test: 110,
        train: 270,
        validated: 504,
      },
      clips: 535,
      splits: {
        accent: { '': 1 },
        age: { twenties: 0.29, '': 0.62, thirties: 0.08 },
        gender: { male: 0.38, '': 0.62 },
      },
      users: 17,
      duration: 2835893,
      avgDurationSecs: 5.301,
      validDurationSecs: 2671.57,
      totalHrs: 0.78,
      validHrs: 0.74,
    },
    ka: {
      reportedSentences: 3,
      size: 104280554,
      checksum:
        '7677df9d650234306a11bf8518be5807e72e7d5fc440d391304d1b99dd5517f5',
      buckets: {
        dev: 527,
        invalidated: 139,
        other: 44,
        reported: 2,
        test: 656,
        train: 1058,
        validated: 2275,
      },
      clips: 2458,
      splits: {
        accent: { '': 1 },
        age: { twenties: 0.59, thirties: 0.1, '': 0.26, fourties: 0.05 },
        gender: { male: 0.4, female: 0.35, '': 0.25 },
      },
      users: 44,
      duration: 14005512,
      avgDurationSecs: 5.698,
      validDurationSecs: 12962.791,
      totalHrs: 3.89,
      validHrs: 3.6,
    },
    'fy-NL': {
      reportedSentences: 281,
      size: 1237743070,
      checksum:
        'ddee4fc3ce52df2379fa4069090d8f5c853155dc0462eb645f6111e2da627297',
      buckets: {
        dev: 2790,
        invalidated: 1031,
        other: 21569,
        reported: 281,
        test: 3020,
        train: 3927,
        validated: 10495,
      },
      clips: 33095,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.56,
          fifties: 0.1,
          thirties: 0.06,
          twenties: 0.03,
          fourties: 0.11,
          sixties: 0.15,
          seventies: 0,
        },
        gender: { '': 0.56, male: 0.08, female: 0.36 },
      },
      users: 467,
      duration: 168972795,
      avgDurationSecs: 5.106,
      validDurationSecs: 53584.212,
      totalHrs: 46.93,
      validHrs: 14.88,
    },
    dv: {
      reportedSentences: 3,
      size: 540488041,
      checksum:
        'b2c8617df5e7aebd74d88491913ecc6b94066198e875853b0b3847d13e70f419',
      buckets: {
        dev: 2077,
        invalidated: 840,
        other: 0,
        reported: 2,
        test: 2202,
        train: 2680,
        validated: 11866,
      },
      clips: 12706,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.11,
          twenties: 0.19,
          thirties: 0.62,
          fourties: 0.05,
          teens: 0.02,
        },
        gender: { '': 0.11, male: 0.35, female: 0.53 },
      },
      users: 167,
      duration: 70042959,
      avgDurationSecs: 5.513,
      validDurationSecs: 65412.384,
      totalHrs: 19.45,
      validHrs: 18.17,
    },
    'pa-IN': {
      reportedSentences: 231,
      size: 69748265,
      checksum:
        'd2e30f28a227ecb8209340c4133edf6489f35f8e3d1eb55ff22b96b12f36952c',
      buckets: {
        dev: 44,
        invalidated: 43,
        other: 1411,
        reported: 232,
        test: 116,
        train: 211,
        validated: 371,
      },
      clips: 1825,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.13,
          fourties: 0.06,
          fifties: 0.01,
          thirties: 0.56,
          twenties: 0.23,
        },
        gender: { '': 0.13, male: 0.87 },
      },
      users: 26,
      duration: 8904554,
      avgDurationSecs: 4.879,
      validDurationSecs: 1810.186,
      totalHrs: 2.47,
      validHrs: 0.5,
    },
    vi: {
      reportedSentences: 22,
      size: 51929480,
      checksum:
        '704bce8031932377cc21c017923ff1e96ebd2be9bd520adcf839f7a0f5f03b6e',
      buckets: {
        dev: 200,
        invalidated: 78,
        other: 870,
        reported: 22,
        test: 198,
        train: 221,
        validated: 619,
      },
      clips: 1567,
      splits: {
        accent: { '': 1 },
        age: {
          thirties: 0.05,
          twenties: 0.23,
          '': 0.15,
          teens: 0.02,
          seventies: 0.01,
          fourties: 0,
          sixties: 0.53,
        },
        gender: { male: 0.82, '': 0.15, female: 0.02, other: 0 },
      },
      users: 62,
      duration: 6755804,
      avgDurationSecs: 4.311,
      validDurationSecs: 2668.693,
      totalHrs: 1.87,
      validHrs: 0.74,
    },
    or: {
      reportedSentences: 5,
      size: 199077358,
      checksum:
        'f3edad30166fe454f4d2b14adeece1434dc4b8eb7b0ece37aac8389b7122218a',
      buckets: {
        dev: 129,
        invalidated: 62,
        other: 4302,
        reported: 4,
        test: 98,
        train: 388,
        validated: 615,
      },
      clips: 4979,
      splits: {
        accent: { '': 1 },
        age: {
          twenties: 0.13,
          '': 0.03,
          thirties: 0.83,
          fourties: 0,
          teens: 0,
        },
        gender: { male: 0.97, '': 0.03, female: 0 },
      },
      users: 34,
      duration: 25499688,
      avgDurationSecs: 5.121,
      validDurationSecs: 3149.69,
      totalHrs: 7.08,
      validHrs: 0.87,
    },
    'ga-IE': {
      reportedSentences: 5,
      size: 156553447,
      checksum:
        '27223fc99af6a45f81190ecb90034806991ff3b9e3aa38a7e97caaabbb0a4ddc',
      buckets: {
        dev: 497,
        invalidated: 409,
        other: 2130,
        reported: 4,
        test: 506,
        train: 541,
        validated: 3352,
      },
      clips: 5891,
      splits: {
        accent: {
          '': 0.4,
          connachta: 0.27,
          mumhain: 0.15,
          other: 0.06,
          ulaidh: 0.12,
        },
        age: {
          twenties: 0.37,
          '': 0.19,
          thirties: 0.3,
          fourties: 0.07,
          sixties: 0,
          teens: 0.02,
          fifties: 0.06,
        },
        gender: { male: 0.65, '': 0.19, female: 0.17 },
      },
      users: 101,
      duration: 20497419,
      avgDurationSecs: 3.479,
      validDurationSecs: 11663.104,
      totalHrs: 5.69,
      validHrs: 3.23,
    },
    fi: {
      reportedSentences: 4,
      size: 49882909,
      checksum:
        'eb26d0904beef5ec08cf53267be7e78b8ba5056fd162057d5b085a7cba51f035',
      buckets: {
        dev: 415,
        invalidated: 59,
        other: 149,
        reported: 3,
        test: 428,
        train: 460,
        validated: 1305,
      },
      clips: 1513,
      splits: {
        accent: { '': 1 },
        age: {
          thirties: 0.4,
          '': 0.23,
          twenties: 0.14,
          fourties: 0.17,
          teens: 0.04,
          fifties: 0.02,
        },
        gender: { male: 0.77, '': 0.23, female: 0 },
      },
      users: 27,
      duration: 6905415,
      avgDurationSecs: 4.564,
      validDurationSecs: 5956.092,
      totalHrs: 1.91,
      validHrs: 1.65,
    },
    hu: {
      reportedSentences: 32,
      size: 242758708,
      checksum:
        '61f933155cba6c54c0b76d0ddd2caebd62d69228b7c935382112abe172660953',
      buckets: {
        dev: 1434,
        invalidated: 169,
        other: 295,
        reported: 31,
        test: 1649,
        train: 3348,
        validated: 6457,
      },
      clips: 6921,
      splits: {
        accent: { '': 1 },
        age: {
          teens: 0,
          '': 0.05,
          thirties: 0.14,
          twenties: 0.75,
          fifties: 0.06,
        },
        gender: { male: 0.89, '': 0.05, female: 0.06 },
      },
      users: 47,
      duration: 31226113,
      avgDurationSecs: 4.512,
      validDurationSecs: 29132.641,
      totalHrs: 8.67,
      validHrs: 8.09,
    },
    sah: {
      size: 181245626,
      checksum:
        'dea1a454813c8f90abcbdf427fa922e1b7a116753deeb410af096ce5f0ae2405',
      buckets: {
        dev: 405,
        invalidated: 66,
        other: 1275,
        test: 757,
        train: 1442,
        validated: 2606,
      },
      clips: 3947,
      splits: {
        accent: { '': 1 },
        age: {
          '': 0.36,
          twenties: 0.02,
          fourties: 0.07,
          thirties: 0.44,
          teens: 0.11,
          fifties: 0,
        },
        gender: { '': 0.36, male: 0.54, female: 0.1 },
      },
      users: 42,
      duration: 23639016,
      avgDurationSecs: 5.989,
      validDurationSecs: 15607.62,
      totalHrs: 6.56,
      validHrs: 4.33,
    },
    vot: {
      size: 7792602,
      checksum:
        '7fb07dd25b0575e8cd811bb8d1e5aebd17fdbca079a4ee50d81e0aaaff50f8b0',
      buckets: {
        dev: 0,
        invalidated: 6,
        other: 411,
        test: 0,
        train: 3,
        validated: 3,
      },
      clips: 420,
      splits: { accent: { '': 1 }, age: { '': 1 }, gender: { '': 1 } },
      users: 3,
      duration: 1008696,
      avgDurationSecs: 2.402,
      validDurationSecs: 7.205,
      totalHrs: 0.28,
      validHrs: 0,
    },
    th: {
      size: 341305736,
      checksum:
        'a3d11043c49d3ea8ffb58dfab117cd831dd62a641e0a26ac60eb43e483534f7a',
      buckets: {
        dev: 1922,
        invalidated: 467,
        other: 2671,
        reported: 275,
        test: 2188,
        train: 2917,
        validated: 7028,
      },
      clips: 10166,
      splits: {
        accent: { '': 1 },
        age: {
          twenties: 0.3,
          '': 0.17,
          thirties: 0.18,
          fourties: 0.3,
          teens: 0.05,
          fifties: 0.01,
        },
        gender: { male: 0.64, '': 0.17, female: 0.19, other: 0 },
      },
      users: 182,
      duration: 44472936,
      reportedSentences: 276,
      avgDurationSecs: 4.375,
      validDurationSecs: 30745.209,
      totalHrs: 12.35,
      validHrs: 8.54,
    },
    lg: {
      size: 208197149,
      checksum:
        '71243c65f638cd7f392fabe22e37cbafbdca4eb5a199210000ae957a88768040',
      buckets: {
        dev: 384,
        invalidated: 290,
        other: 3110,
        reported: 2,
        test: 584,
        train: 1250,
        validated: 2220,
      },
      clips: 5620,
      splits: {
        accent: { '': 1 },
        age: { '': 0.06, thirties: 0.63, twenties: 0.31, fourties: 0 },
        gender: { '': 0.08, female: 0.79, male: 0.13 },
      },
      users: 76,
      duration: 29590983,
      reportedSentences: 3,
      avgDurationSecs: 5.265,
      validDurationSecs: 11688.965,
      totalHrs: 8.21,
      validHrs: 3.24,
    },
    lt: {
      size: 135299706,
      checksum:
        '5ad3d93bc308f58a70e6685f71ae035237ef9caa0922232ac76846f7587bb8aa',
      buckets: {
        dev: 244,
        invalidated: 102,
        other: 1629,
        reported: 41,
        test: 466,
        train: 931,
        validated: 1644,
      },
      clips: 3375,
      splits: {
        accent: { '': 1 },
        age: {
          twenties: 0.48,
          '': 0.12,
          thirties: 0.35,
          fifties: 0.04,
          sixties: 0.02,
        },
        gender: { male: 0.86, '': 0.12, female: 0.03 },
      },
      users: 30,
      duration: 17591228,
      reportedSentences: 42,
      avgDurationSecs: 5.212,
      validDurationSecs: 8568.883,
      totalHrs: 4.88,
      validHrs: 2.38,
    },
    hi: {
      size: 21424045,
      checksum:
        '5492393b04dd1307a52d93525a7db08fc392c8ba0df553668945152e434f58c9',
      buckets: {
        dev: 135,
        invalidated: 60,
        other: 139,
        reported: 5,
        test: 127,
        train: 157,
        validated: 419,
      },
      clips: 618,
      splits: {
        accent: { '': 1 },
        age: {
          twenties: 0.63,
          fourties: 0.05,
          '': 0.27,
          thirties: 0.04,
          teens: 0.01,
        },
        gender: { male: 0.71, female: 0.02, '': 0.27 },
      },
      users: 31,
      duration: 2899824,
      reportedSentences: 5,
      avgDurationSecs: 4.692,
      validDurationSecs: 1966.062,
      totalHrs: 0.8,
      validHrs: 0.54,
    },
  },
  totalDuration: 33420537391,
  totalValidDurationSecs: 26409526,
  totalHrs: 9283,
  totalValidHrs: 7335,
  totalClips: 7040644,
};
